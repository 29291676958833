import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";

import logo from "../assets/main-logo.png";
import searchIcon from "../assets/search-icon.svg";
import searchIconDark from "../assets/white-search-icon.svg";
import whitesearchIcon from "../assets/white-search-icon.svg";
import dollarIcon from "../assets/dollar.svg";
import crossIcon from "../assets/cross_icon.svg";
import crossIconDark from "../assets/cross_icon_dark.svg";
import mainlogo from "../assets/Trackingpoker.com.svg";
import mainlogoDark from "../assets/Trackingpoker.com_black.svg";
import { GetSearchResults } from "../store/slices/ProfileByUser";
import { connect } from "react-redux";
import { InputGroup, Input, InputGroupText, FormGroup } from "reactstrap";
import { GetEpisodeHeaderDetail } from "../store/slices/EpisodesAction";

import "./header.css";

const Header = ({ id, seTheme, GetSearchResults, value1, GetEpisodeHeaderDetail }) => {

  const navigate = useNavigate();
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const [theme, setTheme] = useState(userTheme);
  const [darkmode, setDarkmode] = useState(localStorage.getItem("theme") === "light" || localStorage.theme === undefined ? false : true);

  const [activeTab, setActiveTab] = useState(id && id > 0 ? id : 1);
  const [searchData, setSearchData] = useState(value1);
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [hideContent, setHideContent] = useState(false);
  const [smScreenSearch, setSmScreenSearch] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false)
  const [headerData, setHeaderData] = useState();

  useEffect(() => {
    if (isMenuClicked) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [isMenuClicked]);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWindowSize(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleWindowResize);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, []);

  useEffect(() => {

    if (window.innerWidth >= 992) {
      setIsMenuClicked(false);
    }
  }, []);

  useEffect(() => {
    if (searchData && searchData !== "") {
      setSearchBarActive(true)
    } else {
      setSearchBarActive(false)
    }
  }, [searchData])

  useEffect(() => {
    GetEpisodeHeaderDetail().then((res) => {
      setHeaderData(res);
    });
  }, [])

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  };

  const showDropdownContent = () => {
    let menuContent = document.querySelector(
      ".live-stream-dropdown-menu-content"
    );
    if (menuContent.style.display === "") {
      menuContent.style.display = "block";
    } else {
      menuContent.style.display = "";
    }
  };

  const onFieldKeyPress = (e) => {
    if (e && e.target.value && e.target.name === "search") {
      localStorage.setItem("searchValue", e.target.value);
      if (e.key === "Enter") {
        setLoading(true)
        // GetSearchResults(e.target.value.trim()).then((res) => {
        //   setLoading(false)
        //   setTableData(res.data.result)
        navigate("/search-result", {
          state: { value: e.target.value.trim(), loading: loading },
        });
        setSearchBarActive(true);
        // });
      }
    } else if (searchData) {
      localStorage.setItem("searchValue", searchData);
      // GetSearchResults(searchData.trim()).then((res) => {
      navigate("/search-result", {
        state: { value: searchData.trim() },
      });

      // setSearchData(searchData.trim());
      setSearchBarActive(true);
      // setHideContent(false);
      // });
    }
  };
  let searchValue = localStorage.getItem("searchValue");

  const toggleCollapse = () => {
    setIsMenuClicked(!isMenuClicked);
  };
  const collapseClass = isMenuClicked === false ? '' : 'show';

  const HandleDarkModeChange = (e) => {
    setDarkmode(e.target.checked)

    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  }

  const [adjustedHeight, setAdjustedHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleWindowResize() {
      setAdjustedHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isMenuClicked) {
      // Get the height of the navigation controls (assuming 60 pixels for demonstration purposes)
      const navigationHeight = 60;
      // Calculate the adjusted height for your content
      const adjustedHeight = window.innerHeight - navigationHeight;
      // Set the adjusted height
      setAdjustedHeight(adjustedHeight);
    } else {
      // Reset the adjusted height to full viewport height
      setAdjustedHeight(window.innerHeight);
    }
  }, [isMenuClicked]);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid justify-content-between main-container">
          {/* {isMenuClicked === false &&  */}
          <div className="d-flex start-flex">
            <button
              className={"navbar-toggler"}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={isMenuClicked ? "true" : "false"}
              aria-label="Toggle navigation"
              // onClick={() => setIsMenuClicked(!isMenuClicked)}
              onClick={() => {
                // setIsMenuClicked(!isMenuClicked);
                toggleCollapse(); // Toggle the collapse menu when the button is clicked
              }}
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <span></span>
              <span></span>
              <span></span>
              {/* <img
                src={hamburgerImage}
                alt="hamburgher-icon"
                className="hamburger-icon"
              /> */}
            </button>

            <div
              className={`${searchBarActive === true && windowSize === 768
                ? "logo-d-none"
                : ""
                } d-flex logo-flex`}
            >
              <img src={logo} alt="logo" className="logo" />
              <h3 className="main-heading d-flex">
                <img
                  src={mainlogo}
                  alt="main-logo"
                  className="main-header-logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate('/')}
                />
              </h3>
            </div>
          </div>
          {/* } */}

          {/* {isMenuClicked === false && */}
          <div className="sm-screen-search">
            {/* Search Box */}
            <div className="d-flex search-flex me-3">
              <img
                src={whitesearchIcon}
                alt="searc-icon"
                className="me-1 search-icon-sm"
                onClick={() => {
                  setSmScreenSearch(!smScreenSearch);
                  setIsMenuClicked(false);
                }}
              />
            </div>
            {/* Donation Button */}
            <button
              className="btn btn-outline-success donation-btn"
              onClick={() => navigate("/donation")}
            >
              Support
            </button>
            <div className="dollar-btn" onClick={() => navigate("/donation")}>
              <img src={dollarIcon} alt="" className="dollar-image" />
            </div>
            {/* <button
              className="btn btn-outline-success dollar-btn"
              onClick={() => navigate("/donation")}
            >
              $
            </button> */}
          </div>
          {/* } */}

          {/* <div
              className={`collapse ${
                searchBarActive === true ? "navbar-d-none" : "navbar-collapse"
              } me-2`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-3 ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link links"
                    aria-current="page"
                    href="/live-stream"
                  >
                    Live Stream
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link links" href="/">
                    Contact Me
                  </a>
                </li>
              </ul>
            </div>
         */}
          {/* <div
            className={`me-2 live-strem-div ${
              hideContent == true ? "hide-content" : ""
            }`}
          >
            <ul className="navbar-nav me-3 ms-auto mb-2 mb-lg-0 nav-bar-live-strem">
              <li className="nav-item ">
                <div
                  className="live-stream-dropdown-menu nav-link links"
                  onClick={showDropdownContent}
                >
                  Live Stream
                  <i className="fa fa-caret-down ps-1"></i>
                  <div className="live-stream-dropdown-menu-content">
                    <a
                      className="live-stream-dropdownlinks dropdown-hustler-casino-text text-start"
                      href="/live-stream"
                    >
                      Hustler Casino Live
                    </a>
                    <a
                      className="live-stream-dropdownlinks text-start dropdown-hustler-casino-text"
                      href="#"
                    >
                      The Lodge
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}

          {/* Hamburger Start */}

          {/* <div
            className={`collapse ${isMenuClicked == true ? "navbar-collapse" : "navbar-d-none"
              }  mb-2 `}
            id="navbarSupportedContent"
          > */}
          <div
            className={`navbar-collapse ${collapseClass}`}
            id="navbarSupportedContent"
            style={{ height: isMenuClicked ? adjustedHeight + 'px' : '100vh', overflowY: "scroll" }}
          >

            <div className="text-end" style={{ margin: "5% 3%" }}>
              <img
                src={userTheme === "light" ? crossIcon : crossIconDark}
                height={"24px"}
                width={"24px"}
                // src={crossIcon}
                alt=""
                onClick={() => {
                  setIsMenuClicked(false);
                  // document.getElementsByClassName("navbar-toggler")[0].click();
                }}
                style={{ cursor: "pointer" }}
              />
            </div>

            {/* links */}

            <div className="hamburger-links-div">
              <div className="hamburger-image-div">
                <span>
                  <a
                    className="dropdown-links links"
                    aria-current="page"
                    href="/"
                    onClick={() => setIsMenuClicked(false)}
                  >
                    <img
                      src={userTheme === "light" ? mainlogoDark : mainlogo}
                      alt="main-logo"
                      style={{ width: "181px" }}
                    />
                  </a>
                </span>
              </div>

              <div className="hamburger-links-first-div text-start">
                <table className="table table-borderless m-0">
                  <tbody>
                    <tr>
                      <td className="text-start left-row">
                        <a
                          className="dropdown-links links"
                          aria-current="page"
                          href="/"
                          onClick={() => setIsMenuClicked(false)}
                        >
                          Hustler Casino Live Stream
                        </a>
                      </td>
                      <td className="text-end right-row">
                        <span className="right-row-inner-span">
                          {headerData?.episodeNumber}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start left-row">
                        The Lodge Club
                      </td>
                      <td className="text-end right-row">
                        <span className="right-row-inner-span-coming-soon">
                          Coming Soon
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start left-row">
                        Bally Live
                      </td>
                      <td className="text-end right-row">
                        <span className="right-row-inner-span-coming-soon">
                          Coming Soon
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <ul className="navbar-nav ps-3 pe-3 pb-3 ">
                <li className="nav-item text-start ul-header-tracking-text pb-4">
                  <img
                    src={userTheme === "light" ? mainlogoDark : mainlogo}
                    alt="main-logo"
                  />
                </li>

                <li className="nav-item text-start ul-sub-header-text pt-2 pb-2 cursor-pointer">
                  <a
                    className="dropdown-links links"
                    aria-current="page"
                    href="/"
                  >
                    Hustler Casino Live
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav p-3 pt-1">
                <li className="nav-item text-start ul-header-text pb-2">
                  Site Links
                </li>
                <li className="nav-item text-start  ul-sub-header-text pt-2 pb-2 d-flex justify-content-between align-items-center">
                  Dark Mode /Turn off Dark Mode
                  <div className="form-check form-switch ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      onChange={(e) => changeThemeHandler(e)}
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    ></label>
                  </div>
                </li>
                <li className="nav-item text-start  ul-sub-header-text pb-2">
                  <a className="links" href="#">
                    Contact Me
                  </a>
                </li>
                <li className="nav-item text-start  ul-sub-header-text pt-2 pb-2">
                  <a
                    className="dropdown-links links"
                    aria-current="page"
                    href="/data-disclaimer"
                  >
                    Data Disclaimer
                  </a>
                </li>

                <li className="nav-item text-start  ul-sub-header-text pt-2  pb-2">
                  <a className="links" target="_blank" rel="noreferrer" href="https://twitter.com/TrackingPoker">
                    Twitter
                  </a>
                </li>
                <li className="nav-item text-start ul-sub-header-text pt-2 pb-2">
                  <a className="links" href="/advertise">
                    Advertise
                  </a>
                </li>
                <li className="nav-item text-start ul-sub-header-text pt-2 pb-2">
                  <a
                    className="dropdown-links links"
                    aria-current="page"
                    href="/donation"
                  >
                    Support
                  </a>
                </li>
              </ul> */}
            </div>

            <div className="hamburger-links-div">
              <div className="hamburger-image-div">
                <span className="settings-header">
                  Settings
                </span>
              </div>

              <div className="hamburger-links-first-div text-start">
                <table className="table table-borderless m-0">
                  <tbody>
                    <tr>
                      <td className="text-start left-row">
                        Dark Mode
                      </td>
                      <td className="text-end right-row">
                        {/* <span> */}
                        <div className="small-screen-button-hamburger">
                          <div className="highlight-div-hamburger">
                            <FormGroup switch className={darkmode === true ? "formgrp-1-hamburger" : "formgrp-2-hamburger"}>
                              <div class="form-switch-hamburger formgrp-switch-hamburger">
                                <input type="checkbox" id="darkmode_switch" className="formgrp-input-hamburger" value={darkmode} onChange={(e) => HandleDarkModeChange(e)} checked={darkmode} />
                                <label for="darkmode_switch" class="switch"></label>
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                        {/* </span> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="hamburger-links-div">
              <div className="hamburger-image-div">
                <span className="settings-header">
                  Site Links
                </span>
              </div>

              <div className="hamburger-links-first-div text-start">
                <table className="table table-borderless m-0">
                  <tbody>
                    <tr>
                      <td className="text-start left-row">
                        <a className="dropdown-links links" href="mailto:trackingpokerdotcom@gmail.com">
                          Contact Me
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start left-row">
                        <a
                          className="dropdown-links links"
                          aria-current="page"
                          href="/data-disclaimer"
                          onClick={() => setIsMenuClicked(false)}
                        >
                          Data Disclaimer
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start left-row">
                        <a className="dropdown-links links" target="_blank" rel="noreferrer" href="https://twitter.com/TrackingPoker">
                          Twitter
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start left-row">
                        <a className="dropdown-links links" href="/advertise" onClick={() => setIsMenuClicked(false)}>
                          Advertise
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start left-row">
                        <a
                          className="dropdown-links links"
                          aria-current="page"
                          href="/donation"
                          onClick={() => setIsMenuClicked(false)}
                        >
                          Support
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>

          {/* Hamburger End */}

          {/* {windowSize <= 768 && smScreenSearch === true && isMenuClicked === false && */}
          <div
            className={`${windowSize <= 768 && smScreenSearch === true
              ? "large-screen-search-show"
              : "large-screen-search"
              }`}
          >
            {/* Search Box */}
            {isMenuClicked === false &&
              <div className="d-flex search-flex me-3">
                <InputGroup
                  className={`${searchBarActive === true ? "custom-search-width" : ""
                    }`}
                >
                  <img
                    src={userTheme === "light" ? searchIcon : searchIconDark}
                    alt="searc-icon"
                    className="search-icon"
                    onClick={() => {
                      onFieldKeyPress();
                    }}
                    style={{ cursor: searchData ? "pointer" : "default" }}
                  />
                  <Input
                    id="search"
                    name="search"
                    labelkey="name"
                    placeholder="Search Your Favorite Player"
                    style={{ fontSize: "14px" }}
                    className={`${searchBarActive === true ? "custom-search-width" : ""
                      } search-input`}
                    // value={searchData ? searchData : searchValue}
                    value={searchData && searchData !== "" && searchData !== undefined ? searchData : value1 && searchData === "" ? "" : value1 === undefined ? "" : value1}
                    onChange={(e) => {
                      setSearchData(e.target.value);
                      localStorage.setItem("searchValue", "");
                    }}
                    // options={SearchSuggestion}
                    // selected={searchData}
                    onKeyPress={(e) => onFieldKeyPress(e)}
                    onFocus={() => {
                      setSearchBarActive(true);
                      setHideContent(true);
                    }}
                    onBlur={() => {
                      if (searchData == "") {
                        setSearchBarActive(false);
                        setHideContent(false);
                      }
                    }}
                  //minLength={1}
                  // renderMenuItemChildren={(option) => (
                  //   <>
                  //     <div className="d-flex justify-content-between search-menu-suggestion">
                  //       <span>{option.name}</span>
                  //       <span>${option?.player_profit.toLocaleString()}</span>
                  //     </div>
                  //   </>
                  // )}
                  />
                  {searchBarActive === true && (searchData !== "" || searchValue) &&
                    <>
                      <img
                        src={userTheme === "light" ? crossIcon : crossIconDark}
                        alt="searc-icon"
                        className="close-icon"
                        onClick={() => {
                          // onFieldKeyPress();
                          setSearchData("")
                          searchValue = localStorage.removeItem("searchValue");
                        }}
                        style={{ cursor: searchData || searchValue ? "pointer" : "default" }}
                      />
                    </>
                  }
                  {searchBarActive === true && <InputGroupText
                    // className="me-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onFieldKeyPress();
                      // setSearchData("")
                    }}>
                    Search
                  </InputGroupText>}
                </InputGroup>

                {/* <InputGroup>
                <img
                  src={userTheme === "light" ? searchIcon : searchIconDark}
                  alt="searc-icon"
                  className="search-icon"
                  onClick={() => {
                    onFieldKeyPress();
                  }}
                  style={{ cursor: searchData ? "pointer" : "default" }}
                />
                <Input
                  name="search"
                  labelKey="name"
                  placeholder="Search Your Favorite Player"
                  className={`${searchBarActive === true ? "custom-search-width" : ""
                    } search-input`}
                  value={searchData}
                  onChange={(e) => {
                    setSearchData(e.target.value);
                    localStorage.setItem("searchValue", "");
                  }}
                  // options={SearchSuggestion}
                  // selected={searchData}
                  onKeyPress={(e) => onFieldKeyPress(e)}
                  onFocus={() => {
                    setSearchBarActive(true);
                    setHideContent(true);
                  }}
                  onBlur={() => {
                    if (searchData == "") {
                      setSearchBarActive(false);
                      setHideContent(false);
                    }
                  }}
                />
                <InputGroupText>
                  Search
                </InputGroupText>
              </InputGroup> */}

              </div>}
            {/* <button className="search-btn me-3"
              onClick={() => {
                onFieldKeyPress();
                // setSearchData("")
              }}
            >
              Search
            </button> */}
            {/* Donation Button */}
            <button
              className="btn btn-outline-success donation-btn"
              onClick={() => navigate("/donation")}
            >
              Support
            </button>
            <div
              className={` ${windowSize < 768 && smScreenSearch === true
                ? "d-none"
                : "dollar-btn"
                }`}
              onClick={() => navigate("/donation")}
            >
              <img src={dollarIcon} alt="" className="dollar-image" />
            </div>
          </div>
          {/* } */}
        </div>
      </nav>
    </>
  );
};

export default connect(null, { GetSearchResults, GetEpisodeHeaderDetail })(Header);
