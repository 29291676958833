import React, { useEffect } from "react";
import Header from "../../DefaultLayout/Header";
import { Container, Row, Button, Col } from "reactstrap";
import "./donation.css";
import Footer from "../../DefaultLayout/Footer";
import WhiteArrow from "../../assets/leftArrow.svg";
import DarkArrow from "../../assets/rightArrow.svg";
import CopyBlack from "../../assets/copyblack.svg";
import CopyWhite from "../../assets/copyWhite.svg";
import { Link } from "react-router-dom";

const Donation = (props) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const copyText = (text, id) => {
    const tooltip = document.getElementById(id);
    var input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    if (tooltip.style.display === "none") {
      tooltip.style.display = "block";
    } else {
      tooltip.style.display = "none";
    }
    setTimeout(function () {
      tooltip.style.display = "none";
    }, 5000);
    return result;
  };

  return (
    <>
      <Header seTheme={props.seTheme} />
      <div className="donation-main-div">
        <Container fluid className="text-center donation-container">
          <Row className="d-flex justify-content-start pb-4">
            <Link to="/" className="d-flex justify-content-start">
              <img
                src={StoredTheme === "light" ? DarkArrow : WhiteArrow}
                alt=""
                width="50px"
                height="40px"
                style={{ width: "50px", cursor: "pointer" }}
              />
            </Link>
          </Row>
          <Row className="justify-content-center donation-header-row">
            <h1 className="donation-header text-start">Thank you, from me.</h1>
          </Row>
          <Row className=" donation-content-row">
            <div className="donation-content-div">
              {/* <h3 className="donation-content">
                For the cost of calling the RIVER with 3rd pair and NO kicker, you too can support my efforts to maintain this site and keep it going!
                I understand most of you can’t afford your Friend’s Free Netflix during this high inflation time.  Donations will go directly to pay off the developers,  Teddy KGB, Grandma, what ever is left to Petrovsky.
              </h3> */}
              <h3 className="donation-content text-start">
                Hey Guys/Gals,
              </h3>
              <h3 className="donation-content text-start">
                I’ve completed version 2 of <a href={"https://www.trackingpoker.com/"} target="_blank" rel="noreferrer">Trackingpoker.com</a>! What started as a fun project during
                covid turned into a full-time effort to keep the site running.
                These are the stats that I wanted to share with you from the start.
                I want to present the data as a crypto token.
                {/* Well guys, I finished version 2  <a href={"https://www.trackingpoker.com/"} target="_blank" rel="noreferrer">Trackingpoker.com</a>. What
                all started out as a small project during covid, has turned into a full time job maintaining this site. */}
                {/* For the cost of calling the RIVER with 3rd pair and NO kicker,
                you too can support my efforts to maintain this site! */}
              </h3>
              <h3 className="donation-content text-start pt-2">
                So for the price of calling a RIVER with a 3rd pair and no kicker, you can also
                support <a href={"https://www.trackingpoker.com/"} target="_blank" rel="noreferrer">Trackingpoker.com</a> by
                donating via <a href={"https://gofund.me/2d6713b0"} target="_blank" rel="noreferrer">gofundme</a>.
                {/* So for the cost of calling the RIVER with 3rd pair and NO kicker, you too can
                support <a href={"https://www.trackingpoker.com/"} target="_blank" rel="noreferrer">Trackingpoker.com</a> through donations. */}
                {/* I understand most of you can’t afford your Friend’s Free Netflix
                during this high inflation time but any donations will go
                directly to paying off the developers, Teddy KGB, Grandma, and
                whatever left to Petrovsky. */}
              </h3>
              <h3 className="donation-content text-start pt-2">
                I know that most of us don’t have the money to pay for our friend’s free Netflix during these high inflationary times, but any donations will go directly to funding the development team, Teddy KGB, Grandma, and  Petrovsky.
                {/* I know most of us can’t afford our Friend’s Free Netflix during this high inflationary time but any donations you make will go directly to paying off the developers, Teddy KGB, Grandma, and whatever left to Petrovsky. */}
              </h3>
            </div>
          </Row>

          <Row className="justify-content-center donation-header-row">
            <h1 className="donation-header text-start">Crypto Support</h1>
          </Row>
          <Row className=" donation-content-row">
            <div className="donation-content-div">
              <h3 className="donation-content text-start">
                To all the generous crypto whales in poker, if you recently made
                millions trading dog/frog/cat memes and you would like to
                support the site, I’ve made my wallets available below. 🙏🏻 Thank
                you for your support!
              </h3>
            </div>
          </Row>

          {/* <Row className="justify-content-center donation-btn-row">
            <Button className="donation-button">Venmo</Button>
          </Row>*/}
          <Row className="donation-at-row  d-flex">
            <Col sm={3} className="pe-0 d-flex">
              <p className="donation-at-header text-start">Venmo</p>
              <img
                src={StoredTheme === "light" ? CopyBlack : CopyWhite}
                alt=""
                width="20px"
                height="20px"
                style={{ cursor: "pointer" }}
                onClick={() => copyText("@TrackingPoker", "tooltip")}
              />
              <tooltip id="tooltip" style={{ display: "none" }}>
                Copied
              </tooltip>
            </Col>
          </Row>
          <Row className="donation-at-row justify-content-center">
            <h3 className="donation-at text-start">@TrackingPoker</h3>
          </Row>

          <Row className="donation-at-row  pt-4">
            <Col sm={3} className="pe-0 d-flex">
              <h3 className="donation-at-header text-start">USDT (erc 20)</h3>

              <img
                src={StoredTheme === "light" ? CopyBlack : CopyWhite}
                alt=""
                width="20px"
                height="20px"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  copyText(
                    "0xaA585C34235105582Ac79fc6e59861463bDE6c22",
                    "tooltip1"
                  )
                }
              />
              <tooltip id="tooltip1" style={{ display: "none" }}>
                Copied
              </tooltip>
            </Col>
          </Row>
          <Row className="donation-at-row justify-content-center">
            <h3 className="donation-at text-start">
              0xaA585C34235105582Ac79fc6e59861463bDE6c22
            </h3>
          </Row>

          <Row className="donation-at-row  pt-4">
            <Col sm={3} className="pe-0 d-flex">
              <h3 className="donation-at-header text-start">Bitcoin</h3>
              <img
                src={StoredTheme === "light" ? CopyBlack : CopyWhite}
                alt=""
                width="20px"
                height="20px"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  copyText(
                    "bc1q72nylj269u88y0a3e6rwnny6m8qfnh7mzn6m0s",
                    "tooltip2"
                  )
                }
              />
              <tooltip id="tooltip2" style={{ display: "none" }}>
                Copied
              </tooltip>
            </Col>
          </Row>
          <Row className="donation-at-row justify-content-center">
            <h3 className="donation-at text-start">
              bc1q72nylj269u88y0a3e6rwnny6m8qfnh7mzn6m0s
            </h3>
          </Row>

          <Row className="donation-at-row  pt-4">
            <Col sm={3} className="pe-0 d-flex">
              <h3 className="donation-at-header text-start">Ether</h3>
              <img
                src={StoredTheme === "light" ? CopyBlack : CopyWhite}
                alt=""
                width="20px"
                height="20px"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  copyText(
                    "0xaA585C34235105582Ac79fc6e59861463bDE6c22",
                    "tooltip3"
                  )
                }
              />
              <tooltip id="tooltip3" style={{ display: "none" }}>
                Copied
              </tooltip>
            </Col>
          </Row>
          <Row className="donation-at-row justify-content-center">
            <h3 className="donation-at text-start">
              0xaA585C34235105582Ac79fc6e59861463bDE6c22
            </h3>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Donation;
