import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import searchReducer from "./searchReducer";

const reducer = combineReducers({
  searchData: searchReducer,
});

export default reducer;
