import React from "react";
import Skeleton from "react-loading-skeleton";

const HighsAndLowsCard = ({ playerData, loading, sessions, earningGraphData }) => {

  let ind = [];
  let winloss = [];

  for (let index = 0; index < earningGraphData.length; index++) {
    const element = earningGraphData[index];

    ind.push(index);
    winloss.push(element?.win_loss);
  }

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const highLowData = [
    { name: "Biggest Cashout", value: `$${playerData?.biggest_cashout} ` },
    { name: "Biggest Lost", value: formatCurrency(playerData?.biggest_loss) },
    {
      name: "Longest Win Streak",
      value: `${playerData?.longest_win_streak} Games (${formatCurrency(
        playerData?.longest_win_streak_amount
      )})`,
    },
    {
      name: "Longest Losing Streak",
      value: `${playerData?.longest_loss_streak} Games (${formatCurrency(
        playerData?.longest_loss_streak_amount
      )})`,
    },
    { name: "All Time High", value: `$${playerData?.all_time_high}` },
    { name: "All Time Low", value: formatCurrency(playerData?.all_time_low) },
    {
      name: "Best Month",
      value: `${playerData?.best_month} ${playerData?.best_year}`,
    },
    {
      name: "Worst Month",
      value: `${playerData?.wrost_month} ${playerData?.wrost_year}`,
    },
  ];

  const check = (str, str2) => {
    if (/^[BA]/.test(str2)) {
      if (/^[$]/.test(str)) {
        return true;
      } else if (/^[-]/.test(str)) {
        return false;
      }
    }
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const positiveNumbers = winloss.filter(number => number > 0);
  const highestPositiveNumber = Math.max(...positiveNumbers);

  const negativeNumbers = winloss.filter(number => number < 0);
  const highestNegativeNumber = Math.max(...negativeNumbers);

  const findHighestNegativeValue = (array) => {
    const negativeNumbers = winloss.filter(num => num < 0);

    const highestNegativeValue = Math.max(...negativeNumbers);

    return highestNegativeValue;
  };

  const highestNegativeValue = findHighestNegativeValue(winloss);

  return (
    <div className="my-2 earning-high-low-card">
      <div className="earning-high-low-card-header">
        <h2>The Highs and Lows Stats</h2>
      </div>

      {/* {sessions >= 19 ? */}
      <div className="earning-high-low-card-content">
        <table className="table table-borderless m-0">
          <tbody className="high-low-data-table-body">
            {/* {highLowData.map((eachData, i) => ( */}
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                Biggest Cashout
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
                style={{
                  color:
                    playerData?.biggest_cashout > 0 ? "#21b976" : "#fd0303",
                }}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  playerData?.biggest_cashout ?
                    formatCurrency(playerData?.biggest_cashout)
                    : "-"
                )}
              </td>
            </tr>
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                Biggest Lost
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
                style={{
                  color: playerData?.biggest_loss > 0 ? "#21b976" : "#fd0303",
                }}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  playerData?.biggest_loss ?
                    formatCurrency(playerData?.biggest_loss)
                    : "-"
                )}
              </td>
            </tr>
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                Longest Win Streak
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  <p className="m-0">
                    {playerData?.longest_win_streak ? playerData?.longest_win_streak + " " + "Games" + " " : "-"}
                    {playerData?.longest_win_streak_amount ? <span
                      style={{
                        color:
                          playerData?.longest_win_streak_amount > 0
                            ? "#21b976"
                            : "#fd0303",
                      }}
                    >{`(${formatCurrency(
                      playerData?.longest_win_streak_amount
                    )})`}</span> : "-"}
                  </p>
                )}
                {/* {loading ? <Skeleton /> :
                  <span style={{ color: playerData?.longest_win_streak_amount > 0 ? "#21b976" : "#fd0303" }}>
                    {playerData?.longest_win_streak + " " + "Games" + " " + `(${formatCurrency(playerData?.longest_win_streak_amount)})`}
                  </span>
                } */}
              </td>
            </tr>
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                Longest Losing Streak
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  <p className="m-0">
                    {playerData?.longest_loss_streak ? playerData?.longest_loss_streak + " " + "Games" + " " : "-"}
                    {playerData?.longest_loss_streak_amount ? <span
                      style={{
                        color:
                          playerData?.longest_loss_streak_amount > 0
                            ? "#21b976"
                            : "#fd0303",
                      }}
                    >{`(${formatCurrency(
                      playerData?.longest_loss_streak_amount
                    )})`}</span> : "-"}
                  </p>
                )}
                {/* {loading ? <Skeleton /> :
                  (<span style={{ color: playerData?.longest_loss_streak_amount > 0 ? "#21b976" : "#fd0303" }}>
                    {playerData?.longest_loss_streak + " " + "Games" + " " + `(${formatCurrency(playerData?.longest_loss_streak_amount)})`}
                  </span>)
                } */}
              </td>
            </tr>
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                All Time High
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
                style={{
                  color: "#21b976",
                }}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  // formatCurrency(playerData?.all_time_high)
                  //   highestPositiveNumber === -Infinity && "$0" 
                  //   winloss.length === 0 && "$0"
                  // winloss.length !==0 || -Infinity && highestPositiveNumber
                  highestPositiveNumber === -Infinity || winloss.length === 0 ? "$0" : formatCurrency(highestPositiveNumber)
                )}
              </td>
            </tr>
            {/* <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                All Time Low
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
                style={{
                  color: highestNegativeValue > 0 ? "#21b976" : "#fd0303",
                }}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  // formatCurrency(playerData?.all_time_low)
                  winloss.length === 0 || highestNegativeValue === -Infinity ? "$0" : formatCurrency(highestNegativeValue)
                )}
              </td>
            </tr> */}
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                Best Month
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  playerData?.best_month && playerData?.best_year ? playerData?.best_month + " " + playerData?.best_year : playerData?.best_month ? playerData?.best_month : "-" ? playerData?.best_year ? playerData?.best_year : "-" : "-"
                )}
              </td>
            </tr>
            <tr>
              <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                Worst Month
              </td>

              <td
                className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word`}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  playerData?.wrost_month && playerData?.wrost_year ? playerData?.wrost_month + " " + playerData?.wrost_year : playerData?.wrost_month ? playerData?.wrost_month : "-" ? playerData?.wrost_year ? playerData?.wrost_year : "-" : "-"
                  // playerData?.wrost_month + " " + playerData?.wrost_year
                )}
              </td>
            </tr>
            {/* ))} */}
          </tbody>
        </table>
      </div>
      {/* :
        <div className="earning-high-low-card-content" style={{ height: "85%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          No Available Data
        </div>
      } */}
    </div>
  );
};

export default HighsAndLowsCard;
