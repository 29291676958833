import React from "react";
import "./footer.css";
import { Row } from "reactstrap";

const Footer = () => {
  return (
    <>
      {/* <Row> */}
      {/* <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginBottom: "26px" }}>
        <div style={{ textAlign: 'center' }} className="advertise-with-us">
        </div>
      </div> */}
      {/* </Row> */}
      <div className="spot-error">
        Spot an Error?{" "}
        <a href="mailto:trackingpokerdotcom@gmail.com">Email me</a> to look it
        over!
      </div>
      <footer className="footer">
        <div className="container-fluid ">
          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-md-4 col-lg-6 d-flex footer-logo-name">
              <a className="navbar-brand" href="/">
                <h6 className="heading-part">
                  <span>Tracking</span>poker.com
                </h6>
              </a>
            </div>

            <div className="col-sm-12 col-md-8 col-lg-6 main-footer-link">
              <div className="footer-links">
                <a className="nav-link" href="mailto:trackingpokerdotcom@gmail.com">
                  Contact Me
                </a>
                <a className="nav-link" href="/data-disclaimer">
                  Disclaimer
                </a>
                <a className="nav-link" target="_blank" rel="noreferrer" href="https://twitter.com/TrackingPoker">
                  Twitter
                </a>
                <a className="nav-link" href="/advertise">
                  Advertise
                </a>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-7 footer-sponser-head">
              <a className="navbar-brand" href="/">
                <h6 className="sponser-heading-part">Sponsered Links</h6>
              </a>
            </div>

            <div className="col-sm-12 col-md-8 col-lg-5 main-sponser-footer-link">
              <div className="footer-sponser-links">
                <a className="nav-link" href="/">
                  PokerLinktoSponsorsite.com
                </a>
                <a className="nav-link" href="/">
                  RadPoker.com
                </a>
                <a className="nav-link" href="/">
                  PokerLinktoSponsorsite.com
                </a>
                <a className="nav-link" href="/">
                  PokerLinktoSponsorsite.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Sponsored Links */}

      <footer className="sponser-link-footer">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div
              className="col-sm-12 col-md-12 col-lg-12 sponser-link"
              style={{ textAlign: "center" }}
            >
              <span>Sponsored Links</span>
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-12 sponser-link-contact"
              style={{ textAlign: "center" }}
            >
              <span>
                Place Your Sponsored Links with Us. Contact us and we will help
                you get started today!
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
