import axios from "axios";
import { SEARCH_RESILTS } from "../actions";
const PROXY = process.env.REACT_APP_URL;

// const config = {
//     headers: {
//         "Content-Type": "application/json",
//     },
// };

// Get All Top Winners
export const GetUserDataByIDYear = (id, year) => async (dispatch) => {
  const res = await axios.get(`${PROXY}player_info/${id}/${year}`);
  return res?.data?.result;
};

export const GetUserEarningGraphData = (id, year) => async (dispatch) => {
  const res = await axios.get(`${PROXY}player_earning_graph/${id}/${year}`);
  return res?.data?.result;
};

export const GetUserRankingNumber = (id, year) => async (dispatch) => {
  const res = await axios.get(`${PROXY}player_rank/${id}/${year}`);
  return res?.data?.result;
};

export const GetUserEpisodesData =
  (id, year, page, limit) => async (dispatch) => {
    const res = await axios.get(
      `${PROXY}player_episode/${id}/${year}?page=${page}&limit=${limit}`
    );
    return res?.data?.result;
  };

export const GetSearchResults = (search) => async (dispatch) => {
  const res = await axios.get(
    `${PROXY}profitCalculate/search-player?search=${search}`
  );
  dispatch({
    type: SEARCH_RESILTS,
    payload: res.data.result,
  });
  return res;
};
