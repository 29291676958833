import React, { useEffect, useState } from "react";

import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import "./leaderBoard.css";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { GetOneDayCashoutAndLostData } from "../../store/slices/LeaderBoardTabsAction";
import { connect } from "react-redux";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import OneDayLostCustomPagination from "./OneDayLostPagination";
import defaultPicture from "../../assets/profilePic.png";

const LeaderBoardStatsOneDayLost = (props) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("results");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(50);
  const [Page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");

  useEffect(() => {
    if (props?.playerKey === "onedaylost") {
      setLoading(true);
      props
        .GetOneDayCashoutAndLostData(
          props.year === "All Time" ? "all" : props.year,
          2,
          1,
          50
        )
        .then((res) => {
          setTableData(res?.docs);
          setTotalPages(res?.totalPages);
          setLimit(res?.limit);
          setLoading(false);
        });
    }
  }, [props.year]);

  const paginationHandle = async (e, p) => {
    setPage(p + 1);
    await props
      .GetOneDayCashoutAndLostData(
        props.year === "All Time" ? "all" : props.year,
        1,
        p + 1,
        limit
      )
      .then((res) => {
        setTableData(res?.docs);
        // setTotalPages(res?.totalPages)
        // setLimit(res?.limit)
      });
  };

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const paginationSection = () => {
    const data = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 9,
      nextPage: null,
      page: 1,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 5,
      totalPages: 4,
    };
    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= data.totalPages; i++) {
      if (
        i === 1 ||
        (data.page < 4 && i <= 5) ||
        i === data.page - 1 ||
        i === data.page + 1 ||
        i === data.page ||
        i === data.totalPages ||
        (data.page >= data.totalPages - 3 && i >= data.totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={data.page === i ? true : false}
              onClick={data.page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }
    return (
      <nav>
        <Pagination className="pagination-main">
          <PaginationItem
            onClick={
              data.hasPrevPage === true
                ? () => onPageClick(data.prevPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              previous
              disabled={data.hasPrevPage === true ? false : true}
              tag="button"
            >
              <i
                className="fa fa-angle-left fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
          {Pages}
          <PaginationItem
            onClick={
              data.hasNextPage === true
                ? () => onPageClick(data.nextPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              next
              tag="button"
              disabled={data.hasNextPage === true ? false : true}
            >
              <i
                className="fa fa-angle-right fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    );
  };

  const onPageClick = (page) => {
    var pageNum = page;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    handleSort(tableData, getComparator(isAsc ? "desc" : "asc", property));
  };

  const HandlePagePrev = () => {
    // if (currentPage > 1) {
    //     setCurrentPage(currentPage - 1)
    // }
  };

  const HandlePageNext = () => {
    // if (currentPage < totalPages) {
    //     setCurrentPage(currentPage + 1)
    // }
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <table className="table leaderboard-table">
          <thead className="leaderboard-table-head">
            <tr>
              <th className="leaderboard-th-1">Date</th>
              <th className="text-start leaderboard-th-2">Players Name</th>
              <th className="leaderboard-th-3 text-end">Results</th>
              <th className="leaderboard-th-4 text-end">Episode</th>
              <th className="leaderboardDayCashout-th-5 text-end">Stakes</th>
              <th className="leaderboardDayCashout-th-6 text-end">Game Type</th>
              <th className="table-last-extra"></th>
            </tr>
          </thead>

          <tbody className="leaderboard-table-body">
            {loading && (
              <tr>
                <td className="leaderboard-table-data-index">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-start leaderboard-table-data-name">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="leaderboard-table-data-results">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td>
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
              </tr>
            )}
            {tableData.map((tdata, i) => (
              <tr
                className="leaderboard-tr"
                key={i}
                style={{ cursor: "pointer", verticalAlign: "middle" }}
                onClick={() =>
                  // navigate(
                  //   `/player-profile/${tdata?.playerId}/${props?.year}`,
                  //   {
                  //     state: {
                  //       id: tdata?.playerId,
                  //       year: props.year,
                  //       selectedYear: props.selectedOption,
                  //     },
                  //   }
                  // )
                  navigate(`/playersprofile/${tdata?.slug}/HCL-poker-results/${props?.year === "All Time" ? "all" : props?.year}`,
                    {
                      state: {
                        id: tdata?.playerId,
                        year: props.year,
                        selectedYear: props.selectedOption,
                      },
                    }
                  )
                }
              >
                <td className="leaderboard-table-data-index">
                  {tdata?.episode_date
                    ? tdata?.episode_date
                    : "-"}
                </td>
                <td className="text-start leaderboard-table-data-name">
                  {tdata?.user_profile_pic && <img src={tdata?.user_profile_pic} alt="profile-img" />}
                  {tdata?.first_name ? tdata?.first_name : "-"}{" "}
                  {tdata?.last_name ? tdata?.last_name : ""}
                </td>
                <td
                  // className="leaderboard-table-data-results"
                  className={`${tdata?.win_loss > 0
                    ? "profit-data leaderboard-table-data-results text-end"
                    : "loss-data leaderboard-table-data-results text-end"
                    }`}
                >
                  {tdata?.win_loss === null
                    ? "$0"
                    : formatCurrency(tdata?.win_loss)}
                  {/* ${tdata?.results.toLocaleString()} */}
                </td>
                <td className="text-end">
                  {tdata?.episode_number ? tdata?.episode_number : "-"}
                </td>
                <td className={`text-end`}>
                  {/* ${tdata?.percentage > 0 ? "profit-data" : "loss-data"}  */}
                  {tdata?.game_stakes ? tdata?.game_stakes : "-"}
                </td>
                <td className="text-end">
                  {tdata?.game_type ? tdata?.game_type : "-"}
                </td>
                <td className="table-last-extra"></td>
              </tr>
            ))}
            {!loading && tableData.length === 0 && (
              <tr>
                <td colSpan="7">
                  <div className="text-center">No data found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <div className="hustler-pagination">
                <Pagination className="pagination-main">
                    <PaginationItem
                        onClick={() => HandlePagePrev()}
                        className="custom-page"
                    >
                        <PaginationLink
                            previous
                            tag="button"
                        >
                            <i
                                className="fa fa-angle-left fa-lg"
                                style={{ color: "#6D727E", fontSize: "20px" }}
                            ></i>
                        </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages)].map((page, i) => (
                        <PaginationItem active={i + 1 === Page} key={i}>
                            <PaginationLink onClick={e => paginationHandle(e, i)}>
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem
                        onClick={() => HandlePageNext()}
                        className="custom-page"
                    >
                        <PaginationLink
                            next
                            tag="button"
                        >
                            <i
                                className="fa fa-angle-right fa-lg"
                                style={{ color: "#6D727E", fontSize: "20px" }}
                            ></i>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </div> */}
      {tableData.length !== 0 && (
        <OneDayLostCustomPagination
          totalPages={totalPages}
          currentPage={Page}
          setPage={setPage}
          onChangePage={(e) => paginationHandle(e, Page)}
          year={props?.year}
          setTableData={setTableData}
        />
      )}
    </div>
  );
};

export default connect(null, {
  GetOneDayCashoutAndLostData,
})(LeaderBoardStatsOneDayLost);
