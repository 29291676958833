import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Label, FormGroup, Input, Button, Alert } from "reactstrap";
import moment from "moment";

import rightArrow from "../../assets/arrow_right_icon.svg";
import leftArrow from "../../assets/arrow_left_icon.svg";
import leftdarkarrow1 from "../../assets/arrowdarkleft1.svg";
import rightdarkarrow1 from "../../assets/arrowdarkright1.svg";
import leftdarkarrow from "../../assets/arrowdarkleft.svg";
import rightdarkarrow from "../../assets/arrowdarkright.svg";
import './poll.css';
import { GetPollList, AddVote, GetPollResultList } from "../../store/slices/PollAction";
import { connect } from "react-redux";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Carousel, { consts } from 'react-elastic-carousel';
import 'react-toastify/dist/ReactToastify.css';

import ToastifyContainer from './ToastifyContainer'
import { toast } from 'react-toastify'

const QuesAns = ({ items, GetPollList, AddVote, GetPollResultList }) => {
    const carousel = useRef();

    const [pollList, setPollList] = useState([])
    const [voteArray, setVoteArray] = useState([])
    const [viewOrVotePage, setViewOrVotePage] = useState(false)
    const [result, setResult] = useState([])
    const [ip, setIP] = useState('');
    const [loading, setLoading] = useState(false)
    let offset = new Date().getTimezoneOffset()
    const [showAlert, setShowAlert] = useState(false); // Add state to control the alert visibility
    const [currentIndex, setCurrentIndex] = useState(0)
    const [totalItems, setTotalItems] = useState(0); // State variable to store the total number of items

    const [value, setValue] = useState(false)
    const [data, setdata] = useState()
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    const BreakPoints = [
        { width: 1440, itemsToShow: 5 },
        { width: 1024, itemsToShow: 5 },
        { width: 768, itemsToShow: 5 },
    ];

    const getData = async () => {

        const res = await axios.get("https://api.ipify.org/?format=json").then((res) => {
            GetPollList(res?.data?.ip, offset).then((res) => {
                setPollList(res)
                setTotalItems(res.length);
                // setLoading(false)
                // res.map((vote) => {
                //     setVoteArray(vote.vote)
                // })
            })
            setIP(res?.data?.ip);
        })
    };

    useEffect(() => {
        setLoading(true)
        getData().then((res) => {
            setLoading(false)
        });
    }, []);

    const HandleAddVote = (answerID, questionID) => {

        const addData = {
            "ip_address": ip,
            "questionId": questionID?._id,
            "anwserId": answerID?._id,
            "offset": offset
        }
        if (questionID.flag === false) {
            // alert("Time over you can not vote");
            setShowAlert(true);
            toast.error("Time over you can not vote")
        } else {
            AddVote(addData).then((res) => {
                GetPollList(ip, offset).then((res) => {
                    setPollList(res)
                })
            })
        }

    }

    const HandleView = (id) => {
        setViewOrVotePage(true)
        GetPollResultList(id).then((res) => {
            setResult(res)
        })
    }

    const EachItem = ({ itemData }) => {

        const handleVoteClick = (answer, itemData) => {
            // HandleAddVote(answer, itemData);
            if (selectedAnswer !== null) {
                HandleAddVote(selectedAnswer, itemData);
            } else {
                // Handle case when no answer is selected
                toast.error("Please select an answer.");
            }
        };

        const handleRadioChange = (answerId) => {
            setSelectedAnswer(answerId);
        };

        return (
            <>
                <div>
                    <div className="poll-main-card">
                        <div className="poll-card-body">
                            {loading ? <Skeleton width={"100%"} /> : <h2 className="card-title poll-card-title">{itemData?.question}</h2>}
                        </div>
                        <div>
                            <FormGroup tag="fieldset" >
                                {itemData?.answers?.map((answer, index) => {
                                    setdata(answer)
                                    return (
                                        <FormGroup check className="formcheck radiongrp">
                                            <Label check className="formcheck-label">
                                                <Input type="radio" id="value" name="value" className="form-radio"
                                                    // checked={value}
                                                    // checked={selectedAnswer === answer._id}
                                                    // value={value}
                                                    // onChange={() => handleRadioChange(answer._id)}
                                                    // onChange={(e) => setValue(e.target.checked)}
                                                    onClick={() => HandleAddVote(answer, itemData)}
                                                />{' '}
                                                &nbsp; &nbsp; &nbsp; &nbsp;{loading ? <Skeleton width={150} /> : answer?.answer}
                                            </Label>
                                        </FormGroup>
                                    )
                                })}
                            </FormGroup>
                            <div className="text-start totalVotes" style={{ display: "flex" }}>
                                <div>
                                    {loading ? <Skeleton width={150} /> : itemData?.totalVotes + " " + (itemData?.totalVotes === 0 || itemData?.totalVotes === 1 ? "Vote" : "Votes")}
                                </div>
                                <div>
                                    <ul className="time-left-ul">
                                        <li>
                                            {itemData?.timeleft === 0 ? "0 days left" : itemData?.timeleft + " " + "left"}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="poll-card-footer"
                    // onClick={() => HandleView(itemData?._id)}
                    onClick={() => handleVoteClick(data, itemData)}
                >
                    <h2 style={{ cursor: "pointer" }}>Submit</h2>
                </div> */}
            </>
        );
    };

    const EachItemForViewResult = ({ itemData }) => {

        return (
            <div>
                <div className="poll-main-card-1">
                    <div className="poll-card-body">
                        {loading ? <Skeleton width={"100%"} /> : <h2 className="card-title poll-card-title">{itemData?.question}</h2>}
                    </div>
                    <div>
                        <FormGroup tag="fieldset">
                            {itemData?.vote?.map((answer, index) => {
                                return (
                                    <div className="main-view-div col-12">
                                        <div className="col-9 progress-div">
                                            { /* Move answer above the progress bar */}
                                            {loading ? <Skeleton width={"100%"} /> :
                                                <>
                                                    <div className="answer">{answer?.answer}</div>
                                                    <ProgressBar
                                                        className="main-div-progress"
                                                        // variant={itemData?.totalVotes > 0 ? "info" : "danger"}
                                                        now={answer?.votes}
                                                    />
                                                </>
                                            }
                                        </div>
                                        <div className="col-3 text-end total-vote">
                                            {loading ? <Skeleton width={20} /> : Math.round(answer?.votes) + "%"}
                                        </div>
                                    </div>
                                    // <div className="main-view-div col-12">
                                    //     <div className="col-8 progress-div">
                                    //         <ProgressBar
                                    //             className="main-div-progress"
                                    //             // variant={itemData?.totalVotes > 0 ? "info" : "danger"}
                                    //             label={answer?.answer}
                                    //             now={itemData?.totalVotes}
                                    //         />
                                    //     </div>
                                    //     <div className="col-4 text-end">
                                    //         {Math.round(answer?.votes)}%
                                    //     </div>
                                    // </div>
                                )
                            })}
                        </FormGroup>
                    </div>
                    <div className="text-start totalVotes" style={{ display: "flex" }}>
                        <div>
                            {loading ? <Skeleton width={150} /> : itemData?.totalVotes ? itemData?.totalVotes + " " + (itemData?.totalVotes === 1 ? "Vote" : "Votes") : "-"}
                        </div>
                        <div>
                            <ul className="time-left-ul">
                                <li>
                                    {itemData?.timeleft === 0 ? "0 days left" : itemData?.timeleft + " " + "left"}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="poll-card-footer">
                    <h2 onClick={() => setViewOrVotePage(false)}>Back</h2>
                </div> */}
            </div>
        );
    };

    let StoredTheme = localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : "light";

    const handleCarouselChange = (currentItem, pageIndex) => {
        setCurrentIndex(pageIndex);
    };

    const isLastIndex = currentIndex === totalItems - 1; // Check if it's the last index

    return (
        <div className="my-2">
            <ToastifyContainer />
            <Container fluid className="poll-card">
                <Row className="poll-card-row">
                    <Col xs={12} className="poll-card-inner">
                        <Col xs={10}>
                            <h2 className="poll-card-inner-heading">
                                ✏️ Take a Poll
                            </h2>
                        </Col>
                        <Col xs={2} className="poll-card-inner-div">
                            {pollList.length > 1 && <div className="poll-card-inner-arrows">
                                {localStorage.theme === "light" || localStorage.theme === undefined ?
                                    <img
                                        src={currentIndex === 0 ? leftdarkarrow1 : leftArrow}
                                        // src={
                                        //     localStorage.getItem("theme") === "light"
                                        //         ? currentIndex === 0 ? leftdarkarrow1 : leftArrow
                                        //         : currentIndex === 0 ? leftdarkarrow : leftdarkarrow1
                                        // }
                                        // style={{ height: '28px', width: "28px" }}
                                        className="card-slide-icon"
                                        alt="left-icon"
                                        onClick={() => carousel.current.slidePrev()}
                                    />
                                    :
                                    <img
                                        src={currentIndex === 0 ? leftdarkarrow : leftdarkarrow1}
                                        // src={
                                        //     localStorage.getItem("theme") === "light"
                                        //         ? currentIndex === 0 ? leftdarkarrow1 : leftArrow
                                        //         : currentIndex === 0 ? leftdarkarrow : leftdarkarrow1
                                        // }
                                        // style={{ height: '28px', width: "28px" }}
                                        className="card-slide-icon"
                                        alt="left-icon"
                                        onClick={() => carousel.current.slidePrev()}
                                    />}
                                {localStorage.theme === "light" || localStorage.theme === undefined ? <img
                                    src={isLastIndex === true ? rightdarkarrow1 : rightArrow}
                                    className="card-slide-icon"
                                    alt="right-icon"
                                    onClick={() => carousel.current.slideNext()}
                                />
                                    :
                                    <img
                                        src={isLastIndex === true ? rightdarkarrow : rightdarkarrow1}
                                        className="card-slide-icon"
                                        alt="right-icon"
                                        onClick={() => carousel.current.slideNext()}
                                    />}
                                {/* <img
                                    src={
                                        localStorage.getItem("theme") === "light"
                                            ? currentIndex === 0 ? leftdarkarrow1 : leftArrow
                                            : currentIndex === 0 ? leftdarkarrow : leftdarkarrow1
                                    }
                                    // style={{ height: '28px', width: "28px" }}
                                    className="card-slide-icon"
                                    alt="left-icon"
                                    onClick={() => carousel.current.slidePrev()}
                                />
                                <img
                                    src={
                                        localStorage.getItem("theme") === "light"
                                            ? isLastIndex === true ? rightdarkarrow1 : rightArrow
                                            : isLastIndex === true ? rightdarkarrow : rightdarkarrow1
                                    }
                                    className="card-slide-icon"
                                    alt="right-icon"
                                    onClick={() => carousel.current.slideNext()}
                                /> */}
                            </div>}
                        </Col>
                    </Col>
                </Row>
            </Container>

            {/* Card Carousel */}
            <Container fluid className="poll-slider-card">
                <Row
                    className="poll-slider-card-row"
                >
                    <Col xs={12} style={{ padding: 0 }}>
                        {loading ?
                            <Skeleton
                                width={"100%"}
                                height={300}
                                baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                            />
                            : <Carousel
                                itemsToShow={1}
                                // breakPoints={BreakPoints}
                                showArrows={false}
                                pagination={false}
                                showEmptySlots={false}
                                // enableSwipe={false}
                                // easing="ease"
                                outerSpacing={0}
                                // itemPadding={[0, 10, 0, 0]}
                                ref={carousel}
                                onChange={handleCarouselChange}
                            >
                                {
                                    pollList.length > 0 && pollList?.map((newData) => {
                                        return (
                                            newData.vote.length > 0 ?
                                                <EachItemForViewResult itemData={newData} />
                                                :
                                                <EachItem itemData={newData} />
                                        )
                                    })
                                }
                                {pollList.length === 0 &&
                                    <div className="poll-card-no-data">
                                        <span className="no-data-span">No poll found</span>
                                    </div>
                                }
                            </Carousel>}
                        {/* } */}
                    </Col>
                </Row>
            </Container>

            {/* {pollList.length > 0 && pollList?.map((newData) => {
                return (
                    newData.vote.length === 0 ?
                        <></>
                        :
                        <Container fluid className="poll-card-submit">
                            <Row className="poll-card-row">
                                <Col lg={12} xs={12} className="poll-card-inner-div-submit">

                                    <div className="poll-card-footer"
                                    // onClick={() => HandleView(itemData?._id)}
                                    >
                                        <h2>Submit</h2>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                )
            })
            } */}

        </div>
    );
};

// export default QuesAns;
export default connect(null, {
    GetPollList,
    AddVote,
    GetPollResultList
})(QuesAns);
