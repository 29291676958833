import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import {
  GetEpisodeDataList,
  GetEpisodeHeaderDetail,
} from "../../store/slices/EpisodesAction";
import EpisodeListCustomPagination from "./EpisodeListPagination";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import useRecursiveTimeout from "../../Helper/Advertise";
import { GetAdvertiseBannerList } from "../../store/slices/AdvertiseAction";
import { Link } from "react-router-dom";

const EpisodeListStats = (props) => {
  const navigate = useNavigate();

  const getCurrentYear = () => new Date().getFullYear();
  const currentYear = getCurrentYear();
  const yearsList = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());
  yearsList.push("All Time");

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const [Page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [headerData, setHeaderData] = useState();
  const [headerLoading, setHeaderLoading] = useState(false);

  const [topBanner, setTopBanner] = useState("");
  const [centerBanner, setCenterBanner] = useState("");
  const [bottomBanner, setBottomBanner] = useState("");
  const [topBannerLink, setTopBannerLink] = useState("");
  const [centerBannerLink, setCenterBannerLink] = useState("");
  const [bottomBannerLink, setBottomBannerLink] = useState("");
  const [bannerLoading, setBannerLoading] = useState(false);

  const [episodeData, setEpisodeData] = useState([]);
  const optionsList = yearsList;
  // const optionsList = ["2023", "2022", "2021", "All Time"];
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    setLoading(true);
    setHeaderLoading(true);
    setBannerLoading(true);
    props
      .GetEpisodeDataList(
        selectedYear === "All Time" ? "all" : selectedYear,
        1,
        100
      )
      .then((res) => {
        setLoading(false);
        setEpisodeData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
    props.GetEpisodeHeaderDetail().then((res) => {
      setHeaderLoading(false);
      setHeaderData(res);
    });
    props.GetAdvertiseBannerList().then((res) => {
      setBannerLoading(false);
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, [selectedYear]);

  useRecursiveTimeout(async () => {
    props.GetAdvertiseBannerList().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, 300000);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const paginationHandle = async (e, p) => {
    setPage(p + 1);
    setLoading(true);
    await props
      .GetEpisodeDataList(
        selectedYear === "All Time" ? "all" : selectedYear,
        p + 1,
        limit
      )
      .then((res) => {
        setEpisodeData(res?.docs);
        setLoading(false);
        // setTotalPages(res?.totalPages)
        // setLimit(res?.limit)
      });
  };

  const onPageClick = (page) => {
    console.log(page);
  };

  const handleEpisodeDetailPage = (data) => {
    navigate(`/episode-details/${data?._id}`, {
      state: { selectedYear: selectedYear },
    });
  };

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";


  const biggestWinner = "Charles Yu";
  const modifiedName = biggestWinner.replace(/\s+/g, '-');

  const formatDate = (date) => {

    var d = new Date(date).setHours(0, 0, 0, 0),
      d = new Date(d),

      // var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {bannerLoading ? (
          <Skeleton
            baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
            className="banner-img-episode"
          />
        ) : (
          windowSize > 426 ?
            <a href={topBannerLink} target="_blank" rel="noreferrer">
              <img src={topBanner} className="banner-img-episode" />
            </a>
            :
            windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
              <img src={bottomBanner} className="banner-img-episode" />
            </a>
          // <a href={topBannerLink} target="_blank" rel="noreferrer">
          //   <img src={topBanner} className="banner-img-episode" />
          // </a>
        )}
      </div>

      <Container fluid className="main-episode-list-stats">
        <Row className="episode-list-stats-row-head">
          <Col lg={3} md={4} sm={4} xs={6} className="episode-list-stats-col">
            <h2 className="episode-list-stats-header">Episode List of HCL</h2>
          </Col>
          <Col lg={9} md={8} sm={8} xs={6} className="episode-list-stats-col">
            <div className="last-updated-episode">
              <p className="m-0">
                Last Updated -{" "}
                <span>
                  {/* {moment(headerData?.episodeDate).format("MM/DD/YYYY")} */}
                  {headerData?.episodeDate ? headerData?.episodeDate : "-"}
                </span>
              </p>
            </div>
          </Col>
        </Row>

        <Row className="episode-list-stats-row-content">
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="episode-list-stats-col"
          >
            <div className="episode-list-stats-row">
              <div className="content-head">
                <h3>Episode Tracked</h3>
                <h1>
                  {headerLoading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    headerData?.episodeNumber
                  )}
                </h1>
              </div>
              <div className="content-head">
                <h3>Most Popular Game</h3>
                <h1>
                  {headerLoading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    headerData?.game
                  )}
                </h1>
              </div>
              <div className="content-head">
                <h3>Popular Stakes</h3>
                <h1>
                  {headerLoading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    headerData?.steakes
                  )}
                </h1>
              </div>
              {/* <div className="content-head">
                <h3>Popular Stakes</h3>
                <h1>{headerLoading ? <Skeleton /> : headerData?.steakes}</h1>
              </div> */}
              <div className="content-head">
                <h3>Most Session Played</h3>
                <h1>
                  {headerLoading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    headerData?.player
                  )}
                </h1>
              </div>
              <div className="content-head">
                <h3>Money Changed Hands (All Time)</h3>
                <h1
                  style={{
                    color:
                      headerData?.moneyChangeTotal > 0 ? "#21b976" : "#fd0303",
                  }}
                >
                  {headerLoading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    formatCurrency(headerData?.moneyChangeTotal)
                  )}
                </h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="main-casino-episode-lists">
        <Row>
          <div className="my-2 casino-episode-lists">
            <div className="casino-episode-lists-header">
              <h2>{`${selectedYear} Hustler Casino Live Episode List`}</h2>
              <h2 className="hustler-below-425">{`${selectedYear} Hustler Casino Live Results`}</h2>
              <div className="year-dropdown">
                {/* <select
                  name="year-select"
                  id="year-select"
                  className="year-select"
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value={"2023"}>2023</option>
                  <option value={"2022"}>2022</option>
                  <option value={"2021"}>2021</option>
                  <option value={"All Time"}>All Time</option>
                </select> */}
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={isOptionsOpen ? "expanded" : ""}
                  onClick={toggleOptions}
                  onKeyDown={handleListKeyDown}
                >
                  {optionsList[selectedOption]}
                </button>
                <ul
                  className={`options ${isOptionsOpen ? "show" : ""}`}
                  role="listbox"
                  aria-activedescendant={optionsList[selectedOption]}
                  tabIndex={-1}
                  onKeyDown={handleListKeyDown}
                >
                  {optionsList.map((option, index) => (
                    <li
                      id={option}
                      role="option"
                      aria-selected={selectedOption == index}
                      tabIndex={0}
                      onKeyDown={handleKeyDown(index)}
                      onClick={(e) => {
                        setSelectedThenCloseDropdown(index);
                        setSelectedYear(e.target.innerHTML);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="casino-episode-lists-content">
              <table className="table casino-episode-lists-table">
                <thead className="casino-episode-lists-table-head">
                  <tr>
                    <th className="text-start casino-episode-lists-th-1">
                      Date
                    </th>
                    <th className="text-start casino-episode-lists-th-2">
                      Episodes
                    </th>
                    <th className="text-start casino-episode-lists-th-3">
                      Biggest Winner
                    </th>
                    <th className="text-start casino-episode-lists-th-4">
                      Stakes
                    </th>
                    <th className="text-start casino-episode-lists-th-4">
                      Game Type
                    </th>
                    <th className="text-start casino-episode-lists-th-5">
                      Changed Hands
                    </th>
                  </tr>
                </thead>
                <tbody className="casino-episode-lists-table-body">
                  {loading && (
                    <tr>
                      <td className="leaderboard-table-data-index">
                        <Skeleton
                          count={10}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      </td>
                      <td className="text-start leaderboard-table-data-name">
                        <Skeleton
                          count={10}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      </td>
                      <td className="leaderboard-table-data-results">
                        <Skeleton
                          count={10}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      </td>
                      <td className="text-end">
                        <Skeleton
                          count={10}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      </td>
                      <td className="text-end">
                        <Skeleton
                          count={10}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      </td>
                      <td>
                        <Skeleton
                          count={10}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {episodeData.map((tdata, i) => {
                    const biggestWinner = tdata?.biggestWinner;
                    const modifiedName = biggestWinner.replace(/\s+/g, '-');
                    return (
                      <tr key={i} className="hustler-episode-tr">
                        <td className="text-start casino-episode-lists-table-data-index">
                          {tdata?.episode_date ? tdata?.episode_date : "-"}
                          {/* {tdata?.episode_date
                            ? moment(tdata?.episode_date).format("MM/DD/YYYY")
                            : "-"} */}
                        </td>
                        <td
                          className="text-start casino-episode-lists-table-data-episode_number"
                          onClick={() => handleEpisodeDetailPage(tdata)}
                        >
                          {tdata?.episode_number
                            ? "Episode" + " " + tdata?.episode_number
                            : "-"}
                        </td>
                        <td
                          className="text-start casino-episode-lists-table-data-name"
                          onClick={() =>
                            // navigate(
                            //   `/player-profile/${tdata?.biggestWinnerId}/${selectedYear === "All Time" ? "all" : selectedYear
                            //   }`
                            // )
                            navigate(`/playersprofile/${tdata?.slug}/HCL-poker-results/${selectedYear === "All Time" ? "all" : selectedYear}`)
                          }
                        >
                          {tdata?.biggestWinner ? tdata?.biggestWinner : "-"}
                        </td>
                        <td className="text-start casino-episode-lists-table-data-stakes">
                          {tdata?.game_stakes ? tdata?.game_stakes : "-"}
                        </td>
                        <td className="text-start casino-episode-lists-table-data-stakes">
                          {tdata?.game_type ? tdata?.game_type : "-"}
                        </td>
                        <td
                          className="text-start casino-episode-lists-table-changed_hands"
                          style={{
                            color:
                              tdata?.changedhands > 0 ? "#21b976" : "#fd0303",
                          }}
                        >
                          {tdata?.changedhands
                            ? formatCurrency(tdata?.changedhands)
                            : "$0"}
                        </td>
                      </tr>
                    )
                  })}
                  {!loading && episodeData?.length === 0 && (
                    <tr>
                      <td colSpan="7">
                        <div className="text-center">No data found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* <div className="episode-pagination">{paginationSection()}</div> */}
              {episodeData?.length !== 0 && (
                <EpisodeListCustomPagination
                  totalPages={totalPages}
                  currentPage={Page}
                  setPage={setPage}
                  year={selectedYear}
                  onChangePage={(e) => paginationHandle(e, Page)}
                  setEpisodeData={setEpisodeData}
                  setLoading={setLoading}
                />
              )}
            </div>
          </div>
        </Row>
      </Container>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {bannerLoading ? (
          <Skeleton
            className="banner-img-episode-1"
            baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
          />
        ) : (
          windowSize > 426 ?
            <a href={topBannerLink} target="_blank" rel="noreferrer">
              <img src={topBanner} className="banner-img-episode-1" />
            </a>
            :
            windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
              <img src={bottomBanner} className="banner-img-episode-1" />
            </a>
          // <a href={bottomBannerLink} target="_blank" rel="noreferrer">
          //   <img src={bottomBanner} className="banner-img-episode-1" />
          // </a>
        )}
      </div>
    </>
  );
};

export default connect(null, {
  GetEpisodeDataList,
  GetEpisodeHeaderDetail,
  GetAdvertiseBannerList,
})(EpisodeListStats);
