import "./App.css";
import React, { useState, useEffect } from "react";

import Home from "./view/Home/Home";
import LiveStream from "./view/LiveStream/LiveStream";
import { Routes, Route } from "react-router-dom";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import PlayerProfile from "./view/PlayerProfile/PlayerProfile";
import PlayerProfileWithoutSessions from "./view/PlayerProfile/PlayerProfileWOSession"
import PlayerProfileBelow19 from "./view/PlayerProfileBelow19/PlayerProfile";
import EpisodeList from "./view/EpisodeList/EpisodeList";
import EachEpisode from "./view/EpisodeList/EachEpisode";
import DataDisclaimer from "./view/DataDisclaimer/DataDisclaimer";
import Donation from "./view/DonationPage/Donation";
import Advertise from "./view/Advertise/Advertise";
import SearchResult from "./view/SearchResult/SearchResult";
import { store, persister } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, seTheme] = useState(userTheme);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <div className="App app-div" data-theme={theme}>
          <Router>
            <Routes>
              {/* <Route exact path="/" element={<Home />} /> */}
              <Route exact path="/" element={<LiveStream seTheme={seTheme} />} />
              {/* <Route
                exact
                path="/live-stream"
                element={<LiveStream seTheme={seTheme} />}
              /> */}
              <Route
                exact
                path="/episode-list"
                element={<EpisodeList seTheme={seTheme} />}
              />
              <Route
                exact
                path="/episode-details/:id"
                element={<EachEpisode seTheme={seTheme} />}
              />

              <Route
                exact
                path="/donation"
                element={<Donation seTheme={seTheme} />}
              />
              <Route
                exact
                path="/search-result"
                element={<SearchResult seTheme={seTheme} />}
              />
              {/* <Route
                exact
                path="/playersprofile/:id/HCL-poker-results/:year"
                element={<PlayerProfile seTheme={seTheme} />}
              /> */}
              <Route
                exact
                path="/playersprofile/:id/HCL-poker-results/:year"
                element={<PlayerProfileWithoutSessions seTheme={seTheme} />}
              />
              <Route
                exact
                path="/player-profile-below-19"
                element={<PlayerProfileBelow19 seTheme={seTheme} />}
              />
              <Route
                exact
                path="/data-disclaimer"
                element={<DataDisclaimer seTheme={seTheme} />}
              />
              <Route
                exact
                path="/advertise"
                element={<Advertise seTheme={seTheme} />}
              />
            </Routes>
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
