import React, { useState, useEffect } from "react";
import "./PlayerProfileBelow19.css";

import darkMode from "../../assets/9254147_moon_crescent_night_mode_dark_icon (1).svg";
import lightMode from "../../assets/lightbulb.svg";
import Header from "../../DefaultLayout/Header";

import { Container, Nav, NavItem, NavLink, Row } from "reactstrap";

import HustlerCasinoLiveResults from "./hustlerCasinoLiveResults";
import Footer from "../../DefaultLayout/Footer";
import upArrow from "../../assets/211624_c_up_arrow_icon.svg";

const PlayerProfile = ({ seTheme }) => {
  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, setTheme] = useState(userTheme);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (window.innerWidth < 767) {
    window.onscroll = () => {
      toggleTopButton();
    };
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function toggleTopButton() {
    if (window.innerWidth < 767) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.remove("d-none");
      } else {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.add("d-none");
      }
    }
  }
  return (
    <>
      <Header seTheme={seTheme} />

      {/* Tab Navigation */}
      <div className="container-fluid main-tab-nav">
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row h-100">
              <div className="col-lg-8 col-md-10 col-sm-12 col-12 d-flex">
                <Nav className="main-nav">
                  <NavItem>
                    <NavLink href="/">Hustler Casino Live</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/episode-list">Episode List</NavLink>
                  </NavItem>
                  <NavItem className="main-nav-item">
                    <NavLink active href="/player-profile">
                      Player’s Profile
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="col-lg-4 col-md-2 text-end dark-mode-btn">
                <img
                  src={theme === "light" ? darkMode : lightMode}
                  alt="dark-mode-btn"
                  onClick={changeThemeHandler}
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid main-c">
        {/* <div className="main-div"> */}
        <div className="row main-r-below-19">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="first-row-below-19 col-lg-12 col-md-12">
              <div className="player-name col-lg-3 col-md-3">
                <h2 className="m-0">Player’s Name</h2>
              </div>
              <div className="last-played col-lg-5 col-md-5"></div>
              <div className="year-dropdown-selection col-lg-4 col-md-4">
                <p className="m-0">
                  Last Played on Stream - <span>09/13/22</span>
                </p>
              </div>
            </div>

            <div className="second-row-below-19">
              <div className="head">
                <h3>Profit/Loss</h3>
                <h1>$1,589,000</h1>
              </div>
              <div className="head">
                <h3>Ranking</h3>
                <h1>#1</h1>
              </div>
              <div className="head">
                <h3>Win Rate %</h3>
                <h1>65%</h1>
              </div>
              <div className="head">
                <h3>A. VPIP%</h3>
                <h1>30%</h1>
              </div>
              <div className="head">
                <h3>A. Pre Flop %</h3>
                <h1>18%</h1>
              </div>
              <div className="head">
                <h3>Sessions</h3>
                <h1>60</h1>
              </div>
              <div className="head">
                <h3>Session Avg.</h3>
                <h1>$45,090</h1>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="main-third-content-below-19">
        <div class="row third-component-row-below-19">
          <div className=" col-12  mt-0">
            <div class="row ">
              <div class="col-8 players-name-col">
                <div class="player-name">
                  <h2 className="m-0">Player’s Name</h2>
                </div>

                <div className="last-played">
                  <p className="last-paragraph">
                    Last Played on Stream - <span>09/13/22</span>
                  </p>
                </div>
              </div>
              <div class="col-4 "></div>
            </div>
            <hr className="hr-tag" />
            <div class="row">
              <div class="col-4">
                {" "}
                <div className="head">
                  <h3 class="mb-1 text-start">Profit/Loss</h3>
                  <h1 class="mb-0 text-start">$1,589,000</h1>
                </div>
              </div>

              <div class="col-4">
                <div className="head">
                  <h3 class="mb-1 text-start ps-3">Ranking</h3>
                  <h1 class="mb-0 text-start ps-3">#1</h1>
                </div>
              </div>
              <div class="col-4">
                <div className="head">
                  <h3 class="mb-1 text-start ps-3">Win Rate %</h3>
                  <h1 class="mb-0 text-start ps-3">65%</h1>
                </div>
              </div>
            </div>
            <hr className="hr-tag" />
            <div class="row">
              <div class="col-4">
                {" "}
                <div className="head">
                  <h3 class="mb-1 text-start">Session Avg.</h3>
                  <h1 class="mb-0 text-start">$45,090</h1>
                </div>
              </div>
              <div class="col-4">
                <div className="head">
                  <h3 class="mb-1 text-start ps-3">A. VPIP%</h3>
                  <h1 class="mb-0 text-start ps-3">30%</h1>
                </div>
              </div>
              <div class="col-4">
                {" "}
                <div className="head">
                  <h3 class="mb-1 text-start">A. Pre Flop %</h3>
                  <h1 class="mb-0 text-start">18%</h1>
                </div>
              </div>
            </div>
            <hr className="hr-tag" />
            <div class="row">
              <div class="col-4">
                {" "}
                <div className="head">
                  <h3 class="mb-1 text-start">Session</h3>
                  <h1 class="mb-0 text-start">60</h1>
                </div>
              </div>
              <div class="col-4">
                <div className="head"></div>
              </div>
              <div class="col-4">
                {" "}
                <div className="head"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hustler Casino Live Results */}
      <Container fluid>
        <Row>
          <HustlerCasinoLiveResults />
        </Row>
      </Container>

      <Footer />
      {windowSize < 767 && (
        <button
          class="btn btn-sm  rounded-circle position-fixed bottom-0 end-0 translate-middle d-none scoll-button"
          onClick={() => scrollToTop()}
          id="back-to-up"
          style={{
            background: "linear-gradient(184deg, #FA00FF 0%, #9105FF 100%)",
            color: "#ffffff",
          }}
        >
          {/* <i class="fa fa-arrow-up" aria-hidden="true"></i> */}
          <img src={upArrow} alt="" width="16px" />
        </button>
      )}
    </>
  );
};

export default PlayerProfile;
