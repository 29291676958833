import React, { useState, useEffect, useCallback } from "react";
import "./liveStream.css";
import Header from "../../DefaultLayout/Header";
import CurrentStreamCard from "./CurrentStreamCard";
import ProfitPlayer from "./ProfitPlayer";

import { Row, Col, Container, NavLink, NavItem, Nav, Button, FormGroup, Input } from "reactstrap";
import LastWeek from "./LastWeek";
import LeaderBoardStats from "./LeaderBoardStats";
import Footer from "../../DefaultLayout/Footer";
import darkMode from "../../assets/9254147_moon_crescent_night_mode_dark_icon (1).svg";
import lightMode from "../../assets/lightbulb.svg";
import upArrow from "../../assets/211624_c_up_arrow_icon.svg";
import {
  GetChipsFlyingData,
  GetPlayerOfMonth,
} from "../../store/slices/LeaderBoardTabsAction";
import { connect } from "react-redux";
import CurrentMonthData from "./PlayerOfTheMonth";
import BannerImage from "../../assets/banner.png";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import BannerImage1 from "../../assets/banner-1.png";
import useRecursiveTimeout from "../../Helper/Advertise";
import { GetAdvertiseBannerList } from "../../store/slices/AdvertiseAction";
import { Link } from "react-router-dom";
import QuesAns from "./QuesAns";

const LiveStream = ({
  seTheme,
  GetChipsFlyingData,
  GetPlayerOfMonth,
  GetAdvertiseBannerList,
}) => {

  const getCurrentYear = () => new Date().getFullYear();
  const currentYear = getCurrentYear();
  const yearsList = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());
  yearsList.push("All Time");

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const optionsList = yearsList;
  // const optionsList = ["2023", "2022", "2021", "All Time"];

  const [chipsFlyingData, setChipsFlyingData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState([]);
  const [loading, setLoading] = useState(false);

  const [topBanner, setTopBanner] = useState("");
  const [centerBanner, setCenterBanner] = useState("");
  const [bottomBanner, setBottomBanner] = useState("");
  const [topBannerLink, setTopBannerLink] = useState("");
  const [centerBannerLink, setCenterBannerLink] = useState("");
  const [bottomBannerLink, setBottomBannerLink] = useState("");
  const [bannerLoading, setBannerLoading] = useState(false);
  const [value, setValue] = useState(localStorage.getItem('toggleValue') === "false" ? false : true);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setBannerLoading(true);
    setLoading(true);
    GetChipsFlyingData(selectedYear === "All Time" ? "all" : selectedYear).then(
      (res) => {
        setChipsFlyingData(res);
        setLoading(false);
      }
    );

    GetAdvertiseBannerList().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });

    GetPlayerOfMonth().then((monthres) => {
      setBannerLoading(false);
      setCurrentMonth(monthres);
    });
  }, [selectedYear]);

  useRecursiveTimeout(async () => {
    setBannerLoading(true);
    GetAdvertiseBannerList().then((res) => {
      setBannerLoading(false);
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, 300000);

  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, setTheme] = useState(userTheme);

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  };

  if (window.innerWidth < 767) {
    window.onscroll = () => {
      toggleTopButton();
    };
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function toggleTopButton() {
    if (window.innerWidth < 767) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.remove("d-none");
      } else {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.add("d-none");
      }
    }
  }

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const items = [
    { id: 1, question: "Who’s your favorite player on Stream?", answers: ["Nick Vertucci", "Wesley Flan"] },
    { id: 2, question: "Question", answers: ["Nick Vertucci", "Wesley Flan", "Nick Vertucci", "Wesley Flan"] },
    { id: 2, question: "Who’s your favorite player on Stream?", answers: ["Nick Vertucci", "Wesley Flan", "Nick Vertucci", "Wesley Flan", "Nick Vertucci", "Wesley Flan", "Nick Vertucci", "Wesley Flan"] },
  ]

  const HandleCheck = (e) => {
    setValue(e.target.checked)
    localStorage.setItem('toggleValue', e.target.checked)
  }

  return (
    <>
      <Header id={1} seTheme={seTheme} />

      {/* Tab Navigation */}
      <div className="container-fluid main-tab-nav">
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row h-100">
              <div className="col-lg-8 col-md-10 col-sm-12 col-12 d-flex">
                <Nav className="main-nav">
                  <NavItem className="main-nav-item">
                    <NavLink active href="/">
                      Hustler Casino Live
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/episode-list">Episode List</NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: "not-allowed" }}>
                    {/* <NavLink href={disabled ? "javascript: void (0);" : "/player-profile/:id/:year"} disabled>Player’s Profile</NavLink> */}
                    <NavLink href={"/player-profile"} disabled>
                      Player’s Profile
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="col-lg-4 col-md-2 text-end dark-mode-btn">
                <img
                  src={theme === "light" ? darkMode : lightMode}
                  alt="dark-mode-btn"
                  onClick={changeThemeHandler}
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid main-casino-live-update">
        <div
          className="main-banner"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {bannerLoading && (
            <Skeleton
              className="banner-img"
              baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
            />
          )}
          {!bannerLoading && (
            windowSize > 426 ?
              <a href={topBannerLink} target="_blank" rel="noreferrer">
                <img src={topBanner} className="banner-img" />
              </a>
              :
              windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                <img src={bottomBanner} className="banner-img" />
              </a>

            // <a href={topBannerLink} target="_blank" rel="noreferrer">
            //   <img src={topBanner} className="banner-img" alt="#" />
            // </a>
          )}
        </div>

        {windowSize >= 769 &&
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-3 casino-live-update">

              <div className="col-xl-8 col-lg-10 col-sm-12 casino-live-update-new">
                <div className="casino-live-update-new-title">
                  <h2 className="on-stream">
                    Hustler Casino Live “On-Stream” Stats
                  </h2>
                </div>
                <div className="last-update-div">
                  <h2 className="last-update-live-stream">
                    <span>Last Updated -</span>{" "}
                    {loading ? (
                      <Skeleton
                        baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                      />
                    ) : (
                      currentMonth?.last_updated
                    )}
                  </h2>
                </div>
              </div>

              <div className="small-screen-button col-xl-4 col-lg-2 col-md-12 col-sm-12 col-12">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-5 highlight-div">
                  <span className="highlight-label">Highlights</span>
                  <FormGroup switch className={value === true ? "formgrp-1" : "formgrp-2"}>
                    <div class="form-switch formgrp-switch">
                      <input type="checkbox" id="switch" className="formgrp-input" value={value} onChange={(e) => HandleCheck(e)} checked={value} />
                      <label for="switch" class="switch"></label>
                    </div>
                  </FormGroup>
                </div>
              </div>

            </div>
          </div>}

        {windowSize <= 768 &&
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-3 casino-live-update">
              <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12">
                <h2 className="on-stream">
                  Hustler Casino Live “On-Stream” Stats
                </h2>
              </div>
              <div className="small-screen-button col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-7 last-update-div">
                  <h2 className="last-update-live-stream">
                    <span>Last Updated -</span>{" "}
                    {loading ? (
                      <Skeleton
                        baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                      />
                    ) : (
                      currentMonth?.last_updated
                    )}
                  </h2>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-5 highlight-div">
                  <span className="highlight-label">Highlights</span>
                  <FormGroup switch className={value === true ? "formgrp-1" : "formgrp-2"}>
                    <div class="form-switch formgrp-switch">
                      <input type="checkbox" id="switch" className="formgrp-input" value={value} onChange={(e) => HandleCheck(e)} checked={value} />
                      <label for="switch" class="switch"></label>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>}

      </div>

      {/* Last Week Top 10 */}
      {/* <LastWeek items={items} /> */}

      {/* Live Stream & Profitable Player */}

      {value === true ? <Container fluid>
        <Row className="live-play-row">

          <Col lg="4" md="12" sm="12" xs="12" className="main-profit-card">
            <CurrentMonthData currentMonth={currentMonth} loading={loading} />
          </Col>

          <Col lg="4" md="12" sm="12" xs="12" className="main-stream-card">
            <CurrentStreamCard
              chipsFlyingData={chipsFlyingData}
              loading={loading}
              selectedYear={selectedYear}
            />
          </Col>

          {/* {windowSize <= 426 && */}
          <Col lg="4" md="12" sm="12" xs="12" className="main-stream-card">
            <QuesAns
              items={items}
              theme={theme}
            />
          </Col>
          {/* } */}

          <Col>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {bannerLoading ? (
                <Skeleton
                  className="banner-img-small"
                  baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                />
              ) : (
                <a href={centerBannerLink} target="_blank" rel="noreferrer">
                  <img src={centerBanner} className="banner-img-small" />
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Container> : <></>}

      {/* Leaderboard Stats */}
      <Container fluid>
        <Row>
          <LeaderBoardStats
            setSelectedYear={setSelectedYear}
            setIsOptionsOpen={setIsOptionsOpen}
            setSelectedOption={setSelectedOption}
            selectedYear={selectedYear}
            isOptionsOpen={isOptionsOpen}
            selectedOption={selectedOption}
            optionsList={optionsList}
            userTheme={userTheme}
          />
        </Row>
        <Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {bannerLoading ? (
              <Skeleton
                className="banner-img-1"
                baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
              />
            ) : (
              windowSize > 426 ?
                <a href={topBannerLink} target="_blank" rel="noreferrer">
                  <img src={topBanner} className="banner-img-1" />
                </a>
                :
                windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                  <img src={bottomBanner} className="banner-img-1" />
                </a>
            )}
          </div>
        </Row>
      </Container>

      <Footer />
      {windowSize < 767 && (
        <button
          class="btn btn-sm  rounded-circle position-fixed bottom-0 end-0 translate-middle d-none scoll-button"
          onClick={() => scrollToTop()}
          id="back-to-up"
          style={{
            background: "linear-gradient(184deg, #FA00FF 0%, #9105FF 100%)",
            color: "#ffffff",
          }}
        >
          <img src={upArrow} alt="" width="16px" />
        </button>
      )}
    </>
  );
};

// export default LiveStream;
export default connect(null, {
  GetChipsFlyingData,
  GetPlayerOfMonth,
  GetAdvertiseBannerList,
})(LiveStream);
