import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { PaginationItem, PaginationLink } from 'reactstrap';
import { GetEpisodeDataList } from '../../store/slices/EpisodesAction';
import { connect } from 'react-redux';

const EpisodeListCustomPagination = ({ totalPages, currentPage, setPage, year, onChangePage, GetEpisodeDataList, setEpisodeData, setLoading }) => {
    const maxPageDisplay = 3; // Adjust this value as needed
    const paginationItems = [];

    const handlePageChange = (page) => {
        setPage(page);
        setLoading(true)
        GetEpisodeDataList(year === "All Time" ? "all" : year, page, 100).then((res) => {
            setEpisodeData(res?.docs)
            setLoading(false)
            // setTotalPages(res?.totalPages)
            // setLimit(res?.limit)
        })
    };

    // Helper function to generate pagination items
    const generatePaginationItems = (start, end) => {
        for (let i = start; i <= end; i++) {
            paginationItems.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                    className='active-tab'
                >
                    {i}
                </Pagination.Item>
            );
        }
    };

    if (totalPages <= maxPageDisplay) {
        // Display all page numbers if there are few pages
        generatePaginationItems(1, totalPages);
    } else {
        // Calculate page ranges with ellipsis
        const leftEllipsis = currentPage > maxPageDisplay / 2 + 1;
        const rightEllipsis = currentPage < totalPages - maxPageDisplay / 2;

        if (leftEllipsis) {
            // Add the first page and left ellipsis
            paginationItems.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    {1}
                </Pagination.Item>
            );
            paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
        }

        const startIndex = Math.max(1, currentPage - Math.floor(maxPageDisplay / 2));
        const endIndex = Math.min(currentPage + Math.floor(maxPageDisplay / 2), totalPages);

        generatePaginationItems(startIndex, endIndex);

        if (rightEllipsis) {
            // Add the right ellipsis and last page
            paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
            paginationItems.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }
    }

    const HandlePagePrev = () => {
        if (currentPage > 1) {
            setPage(currentPage - 1)
            GetEpisodeDataList(year === "All Time" ? "all" : year, currentPage - 1, 100).then((res) => {
                setEpisodeData(res?.docs)
                // setTotalPages(res?.totalPages)
                // setLimit(res?.limit)
            })
        }
    }

    const HandlePageNext = () => {
        setLoading(true)
        if (currentPage < totalPages) {
            setPage(currentPage + 1)
            GetEpisodeDataList(year === "All Time" ? "all" : year, currentPage + 1, 100).then((res) => {
                setEpisodeData(res?.docs)
                setLoading(false)
                // setTotalPages(res?.totalPages)
                // setLimit(res?.limit)
            })
        }
    }

    return (
        <div className="hustler-pagination">
            <nav>
                <Pagination className="pagination-main">
                    <PaginationItem
                        onClick={() => HandlePagePrev()}
                        className="custom-page"
                    >
                        <PaginationLink
                            previous
                            tag="button"
                        >
                            <i
                                className="fa fa-angle-left fa-lg"
                                style={{ color: "#6D727E", fontSize: "20px" }}
                            ></i>
                        </PaginationLink>
                    </PaginationItem>
                    {paginationItems}
                    <PaginationItem
                        onClick={() => HandlePageNext()}
                        className="custom-page"
                    >
                        <PaginationLink
                            next
                            tag="button"
                        >
                            <i
                                className="fa fa-angle-right fa-lg"
                                style={{ color: "#6D727E", fontSize: "20px" }}
                            ></i>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </nav>
        </div>
    );
}

export default connect(null, {
    GetEpisodeDataList
})(EpisodeListCustomPagination);
