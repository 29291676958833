import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";

const DiggingDetailsCard = ({
  playerData,
  loading,
  sessions,
  selectedYear,
}) => {
  const navigate = useNavigate();
  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const diggingDetails = [
    {
      name: "Most Played Stakes",
      value: `${playerData?.most_played_game} (${playerData?.most_played_game_count})`,
    },
    {
      name: "Table Buddy",
      value: `${playerData?.buddyFirstName}${" "}${playerData?.buddyLastName
        } (${playerData?.buddyCount})`,
    },
    {
      name: "Most Profitable Game",
      value: `${playerData?.most_profitable_game}`,
    },
    {
      name: "Favorite Day on Stream",
      value: `${playerData?.mostPlayed_day} (${playerData?.mostPlayed_count})`,
    },
    {
      name: "Avg VPIP% in Last 10 Games",
      value: `${playerData?.avg_last10_vpip}%`,
    },
    {
      name: "Avg PFR%  in Last 10 Games",
      value: `${playerData?.avg_last10_pfr}%`,
    },
    {
      name: "Profit/Loss in Last 10 Games",
      value: formatCurrency(playerData?.avg_last10_win_loss),
    },
    { name: "Current Streak", value: `+${playerData?.current_streak}` },
  ];

  // const check = (str) => /^[LH]/.test(str);
  const check = (str, str2) => {
    if (/^[BA]/.test(str2)) {
      if (/^[$]/.test(str)) {
        return true;
      } else if (/^[-]/.test(str)) {
        return false;
      }
    }
  };

  const handleChangePath = (id) => {
    return navigate(
      `/playersprofile/${id}/HCL-poker-results/${selectedYear}`, {
      state: { playerid: id },
    })

    // return navigate(`/player-profile/${id}/${selectedYear}`, {
    //   state: { playerid: id },
    // });
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div className="my-2 digging-in-the-details">
      <div className="digging-in-the-details-header">
        <h2>Digging in the Details</h2>
      </div>

      {loading ?
        <div className="digging-in-the-details-content">
          <table className="table table-borderless">
            <tbody className="high-low-data-table-body">
              {/* {diggingDetails.map((eachData, i) => ( */}
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                {/* <Link to={`/player-profile/${playerData?.buddyId}/${selectedYear}`}> */}
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word table-buddy`}
                // onClick={() => navigate(`/player-profile/${playerData?.buddyId}/${selectedYear}`)}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                {/* </Link> */}
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                  style={{
                    color:
                      playerData?.avg_last10_win_loss > 0
                        ? "#21b976"
                        : "#fd0303",
                  }}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                  style={{
                    color:
                      playerData?.positive_streak === true
                        ? "#21b976"
                        : "#fd0303",
                  }}
                >
                  <Skeleton
                    baseColor={
                      StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                    }
                  />
                </td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
        :
        // sessions >= 20 ? (
        <div className="digging-in-the-details-content">
          <table className="table table-borderless">
            <tbody className="high-low-data-table-body">
              {/* {diggingDetails.map((eachData, i) => ( */}
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Most Played Stakes
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    playerData?.most_played_game ? playerData?.most_played_game + " " + `(${playerData?.most_played_game_count})` : "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Table Buddy
                </td>
                {/* <Link to={`/player-profile/${playerData?.buddyId}/${selectedYear}`}> */}
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word table-buddy`}
                  // onClick={() => navigate(`/player-profile/${playerData?.buddyId}/${selectedYear}`)}
                  onClick={() => handleChangePath(playerData?.buddy_slug)}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    playerData?.buddyFirstName || playerData?.buddyLastName || playerData?.buddyCount ?
                      `${playerData?.buddyFirstName}${" "}${playerData?.buddyLastName} (${playerData?.buddyCount})` : "-"
                  )}
                </td>
                {/* </Link> */}
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Most Profitable Game
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    playerData?.most_profitable_game ? playerData?.most_profitable_game : "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Favorite Day on Stream
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    playerData?.mostPlayed_day || playerData?.mostPlayed_count ?
                      `${playerData?.mostPlayed_day} (${playerData?.mostPlayed_count})` : "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Avg VPIP% in Last 10 Games
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : playerData?.avg_last10_vpip ? (
                    Math.round(playerData?.avg_last10_vpip) + "%"
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Avg PFR%  in Last 10 Games
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : playerData?.avg_last10_pfr ? (
                    Math.round(playerData?.avg_last10_pfr) + "%"
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Profit/Loss in Last 10 Games
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                  style={{
                    color:
                      playerData?.avg_last10_win_loss > 0
                        ? "#21b976"
                        : "#fd0303",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : (
                    playerData?.avg_last10_win_loss ?
                      formatCurrency(Math.round(playerData?.avg_last10_win_loss)) : "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  Current Streak
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                  style={{
                    color:
                      playerData?.positive_streak === true
                        ? "#21b976"
                        : "#fd0303",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : playerData?.positive_streak === true ? (
                    "+" + playerData?.current_streak
                  ) : playerData?.positive_streak === false ? (
                    "-" + playerData?.current_streak
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-lg-5 col-md-4 col-sm-4 col-4 text-left-word">
                  First Appearance
                </td>
                <td
                  className={`col-lg-4 col-md-4 col-sm-4 col-4 text-right-word `}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : playerData?.first_appearance ? playerData?.first_appearance : "-"}
                </td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
        // ) : (
        //   <div
        //     className="digging-in-the-details-content"
        //     style={{
        //       height: "85%",
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //     }}
        //   >
        //     <span className="no-available-data">No Available Data</span>
        //   </div>
        // )
      }
    </div>
  );
};

export default DiggingDetailsCard;
