import React, { useEffect } from "react";
import Header from "../../DefaultLayout/Header";
import Footer from "../../DefaultLayout/Footer";
import { Col, Container, Row } from "reactstrap";
import WhiteArrow from "../../assets/leftArrow.svg";
import DarkArrow from "../../assets/rightArrow.svg";
import "./dataDisclaimer.css";
import { Link } from "react-router-dom";

const DataDisclaimer = (props) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <>
      <Header seTheme={props.seTheme} />

      <div className="donation-main-div">
        <Container fluid className="text-center donation-container">
          <Row className="d-flex justify-content-start pb-4">
            <Link to="/" className="d-flex justify-content-start">
              <img
                src={StoredTheme === "light" ? DarkArrow : WhiteArrow}
                alt=""
                width="50px"
                height="40px"
                style={{ width: "50px", cursor: "pointer" }}
              />
            </Link>
          </Row>
          <Row className="justify-content-center donation-header-row">
            <h1 className="donation-header text-start">Data Scraping</h1>
          </Row>
          <Row className=" donation-content-row">
            <div className="donation-content-div">
              {/* <h3 className="donation-content">
                For the cost of calling the RIVER with 3rd pair and NO kicker, you too can support my efforts to maintain this site and keep it going!
                I understand most of you can’t afford your Friend’s Free Netflix during this high inflation time.  Donations will go directly to pay off the developers,  Teddy KGB, Grandma, what ever is left to Petrovsky.
              </h3> */}
              <h3 className="data-error-description text-start">
                As most of you guys already know, the data on this site was manually scrapped after each live stream. This information is intended for entertainment purposes and nothing else.
              </h3>
            </div>
          </Row>

          <Row className="justify-content-center donation-header-row">
            <h1 className="donation-header text-start">If you found an error in the data...</h1>
          </Row>
          <Row className=" donation-content-row">
            <div className="donation-content-div">
              <h3 className="data-error-description text-start">
                If you find any errors or mistakes on the site, <a href="mailto:trackingpokerdotcom@gmail.com">trackingpokerdotcom@gmail.com</a> and I’ll look into it. You can reach me on  <a href="https://twitter.com/TrackingPoker" target="_blank" rel="noreferrer">Twitter</a>.
              </h3>
            </div>
          </Row>

          <Row className="justify-content-center donation-header-row">
            <h1 className="donation-header text-start">Can you update my name?</h1>
          </Row>
          <Row className=" donation-content-row">
            <div className="donation-content-div">
              <h3 className="data-error-description text-start">
                Yes of course, <a href="mailto:trackingpokerdotcom@gmail.com">trackingpokerdotcom@gmail.com</a> the correct name.
              </h3>
            </div>
          </Row>

        </Container>
        <Footer />
      </div>

      {/* <div className="data-disclaimer-page">
        <Container fluid className="mt-3 mb-2">
          <Row className="p-3">
            <h1 className="data-disclaimer-header">
              How accurate are these data sets?
            </h1>

            <h3 className="data-accuracy-description">
              The data is for (ON STREAM ONLY) and painstakingly scrubbed from
              the end of each live stream.
              <span>“Cumulative Winnings”, “VPIP”,</span> and{" "}
              <span>“Pre-Flop Raise” .</span>&nbsp;Keep in mind, players will
              continue to play off stream, which means the wins/loss will not
              reflect their actual wins/loss. This information was intended for
              entertainment purposes and nothing else.
            </h3>
          </Row>
        </Container>

        <Container fluid className="mb-5 data-error-part">
          <Row className="pb-3 ps-3 pe-3">
            <h1 className="data-error-header">
              If you found an error in the data...
            </h1>

            <h3 className="data-error-description">
              If you find any errors or mistakes on the site,&nbsp;
              <a>please email me</a>&nbsp; and I’ll look into it. You can reach
              me on&nbsp;
              <a> /reddit</a>&nbsp;or&nbsp;
              <a>Twitter</a>.
            </h3>
          </Row>
        </Container>
      </div> */}

      {/* <Footer /> */}
    </>
  );
};

export default DataDisclaimer;
