import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const StakesBreakDownCard = ({ playerData, loading, sessions }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  let array = [];
  let count = [];
  let stacksData = [];
  let newArray;
  for (let index = 0; index < playerData?.stack_data?.length; index++) {
    const element = playerData?.stack_data[index];

    array.push(element?._id ? element?._id : "Others");
    count.push(element?.count);
    // stacksData.push(element?.stack_data)

    // for (let index = 0; index < element?.stack_data.length; index++) {
    //     const stacks = element?.stack_data[index];
    //     stacksData.push(stacks)
    //     newArray = stacksData.filter(item => item !== '');
    // }
  }
  let options = {
    series: count,
    labels: array,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              //formatter: () => '343',
              fontSize: 30,
              color: "#f90000",
            },
          },
        },
      },
    },
    legend: {
      show: false,
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "sans-serif",
      // show: true,
      // horizontalAlign: 'left',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 1025,
        options: {
          chart: {
            width: 180,
          },
        },
      },
      // {
      //     breakpoint: 769,
      //     options: {
      //         chart: {
      //             // width: 200,
      //             innerHeight: 300
      //         },
      //         legend: {
      //             show: true,
      //             fontSize: "12px",
      //             fontWeight: '500',
      //             fontFamily: 'sans-serif',
      //             // show: true,
      //             // horizontalAlign: 'left',
      //         },
      //     },
      // },
      {
        breakpoint: 376,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
    ],
  };
  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  return (
    <div className="my-2 stakes-breakdown">
      <div className="stakes-breakdown-header">
        <h2>Stakes Breakdown</h2>
      </div>

      {/* {sessions >= 19 ? */}
      <div className="stakes-breakdown-content">
        <div className="row">
          {/* <div className="col-lg-4 col-md-6 col-12 donut-chart">
                        {loading ?
                            <Skeleton height={200} />
                            : <Chart
                                type="donut"
                                options={options}
                                series={options.series}
                                width={200}
                                height={200}
                            />}
                    </div> */}
          {/* <div className="col-lg-12 col-md-12 col-xs-12 stakes-data-col">
                        <div className='col-lg-5 col-md-5 col-5'>
                            <ul className='chart-ul'>
                                {loading ?
                                    <Skeleton count={8} />
                                    : playerData?.stack_data?.map((res) => {
                                        return (
                                            <li>{res._id ? res._id + " " + `(${res?.count})` : "Others" + " " + `(${res?.count})`}</li>
                                        )
                                    })}
                            </ul>
                        </div>
                        <div className='col-lg-5 col-md-5 col-5'>
                            <table className='table table-borderless m-0'>
                                <tbody>
                                    {playerData?.stack_data?.map((eachData, i) => {
                                        // const percentage = (eachData?.Sum_win_loss / 100).toFixed(2);
                                        const percentage = (eachData?.Sum_win_loss / 500000) * 100
                                        // const percentage = 100 * eachData?.Sum_win_loss / 500000
                                        return (
                                            <tr>
                                                <td className={`col-lg-4 col-md-4 col-sm-4 col-4 stakes-value`}>
                                                    {loading === true ? <Skeleton width={100} /> : <ProgressBar variant={eachData?.Sum_win_loss > 5 ? 'info' : 'danger'} now={eachData?.Sum_win_loss} style={{ width: "100%" }} />}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-2 col-md-2 col-2'>
                            <table className='table table-borderless m-0'>
                                <tbody>
                                    {playerData?.stack_data?.map((eachData, i) => (
                                        <tr>
                                            <td className={`col-lg-4 col-md-4 col-sm-4 col-4 stakes-value`} style={{ color: eachData?.Sum_win_loss > 0 ? "#21b976" : "#fd0303" }}>
                                                {loading === true ? <Skeleton width={100} /> : formatCurrency(eachData?.Sum_win_loss)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div> */}
          <table className="table table-borderless">
            <thead className="stakes-brekdown-table-head">
              <tr>
                <th className="text-start stakes-table-th-1">Game Type</th>
                <th className="stakes-table-th-2"></th>
                <th className="text-start stakes-table-th-3">Win Rate%</th>
                <th className="text-end stakes-table-th-4">Win/Loss</th>
              </tr>
            </thead>

            <tbody className="stakes-brekdown-table-body">
              {loading && (
                <tr>
                  <td>
                    <Skeleton
                      count={10}
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  </td>
                  <td>
                    <Skeleton
                      count={10}
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  </td>
                  <td>
                    <Skeleton
                      count={10}
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  </td>
                  {windowSize > 768 && (
                    <td className="text-end">
                      <Skeleton
                        count={10}
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    </td>
                  )}
                </tr>
              )}
              {!loading &&
                playerData?.stack_data?.map((tdata, i) => (
                  <tr>
                    {windowSize <= 768 ? (
                      <td className="text-start stakes-table-td-1">
                        {tdata._id
                          ? tdata._id + " " + `(${tdata?.count})`
                          : "Others" + " " + `(${tdata?.count})`}
                      </td>
                    ) : (
                      <td className="text-start stakes-table-td-1">
                        {tdata._id ? tdata._id : "Others"}
                      </td>
                    )}
                    <td className="text-start stakes-table-td-2">
                      {tdata?.count + " " + "Sessions"}
                    </td>
                    <td className="stakes-table-td-3">
                      <ProgressBar
                        variant={tdata?.winRate > 0 ? "info" : "danger"}
                        label={
                          tdata?.winRate > 0
                            ? `${Math.round(tdata?.winRate)}%`
                            : `0%`
                        }
                        now={tdata?.winRate > 0 ? tdata?.winRate : 18}
                        style={{ width: "100%" }}
                      />
                    </td>
                    <td
                      className="text-end stakes-table-td-4"
                      style={{
                        color: tdata?.Sum_win_loss > 0 ? "#21b976" : "#FD0303",
                      }}
                    >
                      {tdata?.Sum_win_loss
                        ? formatCurrency(tdata?.Sum_win_loss)
                        : "-"}
                    </td>
                  </tr>
                ))}
              {!loading && playerData?.stack_data?.length === 0 || playerData?.stack_data?.length === undefined && (
                <tr>
                  <td colSpan="12">
                    <div className="text-center no-data-current-month">No Available Data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* : */}
      {/* <div className="stakes-breakdown-content" style={{ height: "85%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    No Available Data
                </div>
            } */}
    </div>
  );
};

export default StakesBreakDownCard;
