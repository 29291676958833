import React, { useEffect, useState } from "react";

import downArrow from "../../assets/down-arrow.svg";
import downArrowdark from "../../assets/down-arrow-dark.svg";
import upArrow from "../../assets/up-arrow.svg";
import upArrodark from "../../assets/up-arrow-dark.svg";
import "./leaderBoard.css";
import { PaginationItem, PaginationLink } from "reactstrap";
import { GetAllRegulars } from "../../store/slices/LeaderBoardTabsAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "react-bootstrap";
import sortingIconDark from "../../assets/sortingIconblack.svg";
import sortingIconLight from "../../assets/sortingIconwhite-01.svg";
import defaultPicture from "../../assets/profilePic.png";

const LeaderBoardStatsRegular = (props) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalPages, setTotalPages] = useState("");
  const element = document.getElementById('main-leaderboard');

  useEffect(() => {
    if (props.playerKey === "regulars") {
      setLoading(true);
      props
        .GetAllRegulars(props.year === "All Time" ? "all" : props?.year, Page, limit)
        .then((res) => {
          setLoading(false);
          setTableData(res?.docs);
          setTotalPages(res?.totalPages);
          setLimit(res?.limit);
          setPage(res?.page)
        });
    }
  }, [props.year]);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const onPageClick = (page) => {
    var pageNum = page;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const [orderBy, setOrderBy] = useState("episodeCount");
  const [order, setOrder] = useState("desc"); // Default sorting order

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      // Toggle sorting order if the same column is clicked
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Sort by a new column
      setOrderBy(property);
      setOrder("asc"); // Start with ascending order by default
    }
  };

  // const indexOfLastItem = Page * limit;
  // const indexOfFirstItem = indexOfLastItem - limit;
  // const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // // Sorting function
  // const sortedData = currentItems.slice().sort((a, b) => {
  //   const aValue = a[orderBy];
  //   const bValue = b[orderBy];
  //   if (order === "asc") {
  //     return aValue < bValue ? -1 : 1;
  //   } else {
  //     return aValue > bValue ? -1 : 1;
  //   }
  // });

  // const indexOfLastItem = Math.min(Page * limit, tableData.length);
  // const indexOfFirstItem = indexOfLastItem - limit;
  // const currentItems = tableData?.slice(indexOfFirstItem, indexOfLastItem);

  // // Sorting function
  // const sortedData = currentItems.slice().sort((a, b) => {
  //   const aValue = a[orderBy];
  //   const bValue = b[orderBy];
  //   if (order === "asc") {
  //     return aValue < bValue ? -1 : 1;
  //   } else {
  //     return aValue > bValue ? -1 : 1;
  //   }
  // });

  const indexOfLastItem = Math.min(Page * limit, tableData.length);
  const indexOfFirstItem = Math.max(0, indexOfLastItem - limit); // Ensure it doesn't go below 0
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Sorting function
  const sortedData = currentItems.slice().sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const maxPageDisplay = 3; // Adjust this value as needed
  const paginationItems = [];

  const handlePageChange = (page) => {
    setPage(page);
    setLoading(true)
    element.scrollIntoView({ behavior: 'smooth' });
    props.GetAllRegulars(props.year === "All Time" ? "all" : props.year, page, limit).then((res) => {
      setTableData(res?.docs);
      setLoading(false);
      setTotalPages(res?.totalPages);
      setLimit(res?.limit);
      setPage(res?.page)
    });
  };

  // Helper function to generate pagination items
  const generatePaginationItems = (start, end) => {
    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === Page}
          onClick={() => handlePageChange(i)}
          className='active-tab'
        >
          {i}
        </Pagination.Item>
      );
    }
  };

  if (totalPages <= maxPageDisplay) {
    // Display all page numbers if there are few pages
    generatePaginationItems(1, totalPages);
  } else {
    // Calculate page ranges with ellipsis
    const leftEllipsis = Page > maxPageDisplay / 2 + 1;
    const rightEllipsis = Page < totalPages - maxPageDisplay / 2;

    if (leftEllipsis) {
      // Add the first page and left ellipsis
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
    }

    const startIndex = Math.max(1, Page - Math.floor(maxPageDisplay / 2));
    const endIndex = Math.min(Page + Math.floor(maxPageDisplay / 2), totalPages);

    generatePaginationItems(startIndex, endIndex);

    if (rightEllipsis) {
      // Add the right ellipsis and last page
      paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
      paginationItems.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }
  }

  const HandlePagePrev = () => {
    if (Page > 1) {
      setLoading(true)
      setPage(Page - 1)
      element.scrollIntoView({ behavior: 'smooth' });
      props.GetAllRegulars(props.year === "All Time" ? "all" : props.year, Page - 1, limit).then((res) => {
        setTableData(res?.docs);
        setLoading(false);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
        setPage(res?.page)
      });
    }
  }

  const HandlePageNext = () => {
    if (Page < totalPages) {
      setPage(Page + 1)
      setLoading(true)
      element.scrollIntoView({ behavior: 'smooth' });
      props.GetAllRegulars(props.year === "All Time" ? "all" : props.year, Page + 1, limit).then((res) => {
        setTableData(res?.docs);
        setLoading(false);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
        setPage(res?.page)
      });
    }
  }

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <table className="table leaderboard-table">
          <thead className="leaderboard-table-head">
            <tr>
              <th className="leaderboard-th-1">#</th>
              <th className="text-start leaderboard-th-2">Players Name</th>
              <th
                className="leaderboard-th-3 text-end"
                onClick={() => {
                  handleRequestSort("result");
                }}
              >
                Results
                {orderBy === "result" ? (
                  <img
                    src={
                      order === "desc" && orderBy === "result"
                        ? props.userTheme === "light"
                          ? downArrow
                          : downArrowdark
                        : props.userTheme === "dark"
                          ? upArrodark
                          : upArrow
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                ) : (
                  <img
                    style={{ height: "11px", width: "9px" }}
                    src={
                      props?.userTheme === "light"
                        ? sortingIconDark
                        : sortingIconLight
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                )}
              </th>
              <th
                className="leaderboard-th-4 text-end"
                onClick={() => {
                  handleRequestSort("winRate");
                }}
              >
                Win Rate
                {orderBy === "winRate" ? (
                  <img
                    src={
                      order === "desc" && orderBy === "winRate"
                        ? props.userTheme === "light"
                          ? downArrow
                          : downArrowdark
                        : props.userTheme === "dark"
                          ? upArrodark
                          : upArrow
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                ) : (
                  <img
                    style={{ height: "11px", width: "9px" }}
                    src={
                      props?.userTheme === "light"
                        ? sortingIconDark
                        : sortingIconLight
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                )}
              </th>
              <th
                className="leaderboard-th-5 text-end"
                onClick={() => {
                  handleRequestSort("session");
                }}
              >
                $/Session
                {orderBy === "session" ? (
                  <img
                    src={
                      order === "desc" && orderBy === "session"
                        ? props.userTheme === "light"
                          ? downArrow
                          : downArrowdark
                        : props.userTheme === "dark"
                          ? upArrodark
                          : upArrow
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                ) : (
                  <img
                    style={{ height: "11px", width: "9px" }}
                    src={
                      props?.userTheme === "light"
                        ? sortingIconDark
                        : sortingIconLight
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                )}
              </th>
              <th
                className="text-end leaderboard-th-7"
                onClick={() => {
                  handleRequestSort("AVpip");
                }}
              >
                A.VPIP
                {orderBy === "AVpip" ? (
                  <img
                    src={
                      order === "desc" && orderBy === "AVpip"
                        ? props.userTheme === "light"
                          ? downArrow
                          : downArrowdark
                        : props.userTheme === "dark"
                          ? upArrodark
                          : upArrow
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                ) : (
                  <img
                    style={{ height: "11px", width: "9px" }}
                    src={
                      props?.userTheme === "light"
                        ? sortingIconDark
                        : sortingIconLight
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                )}
              </th>
              <th
                className="text-end leaderboard-th-8"
                onClick={() => {
                  handleRequestSort("APfr");
                }}
              >
                A.PFR
                {orderBy === "APfr" ? (
                  <img
                    src={
                      order === "desc" && orderBy === "APfr"
                        ? props.userTheme === "light"
                          ? downArrow
                          : downArrowdark
                        : props.userTheme === "dark"
                          ? upArrodark
                          : upArrow
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                ) : (
                  <img
                    style={{ height: "11px", width: "9px" }}
                    src={
                      props?.userTheme === "light"
                        ? sortingIconDark
                        : sortingIconLight
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                )}
              </th>
              <th
                className="text-end leaderboard-th-8"
                onClick={() => {
                  handleRequestSort("episodeCount");
                }}
              >
                Sessions
                {orderBy === "episodeCount" ? (
                  <img
                    src={
                      order === "desc" && orderBy === "episodeCount"
                        ? props.userTheme === "light"
                          ? downArrow
                          : downArrowdark
                        : props.userTheme === "dark"
                          ? upArrodark
                          : upArrow
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                ) : (
                  <img
                    style={{ height: "11px", width: "9px" }}
                    src={
                      props?.userTheme === "light"
                        ? sortingIconDark
                        : sortingIconLight
                    }
                    alt="sort-arrow"
                    className="leaderboard-sort-arrow"
                  />
                )}
              </th>
              <th className="table-last-extra"></th>
            </tr>
          </thead>

          <tbody className="leaderboard-table-body">
            {loading ? (
              <tr>
                <td className="leaderboard-table-data-index">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-start leaderboard-table-data-name">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="leaderboard-table-data-results">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td>
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
              </tr>
            ) : (
              sortedData.map((tdata, i) => (
                <tr
                  className="leaderboard-tr"
                  key={i}
                  style={{ cursor: "pointer", verticalAlign: "middle" }}
                  onClick={() =>
                    // navigate(
                    //   `/player-profile/${tdata?.playerId}/${props?.year}`,
                    //   {
                    //     state: {
                    //       id: tdata?.playerId,
                    //       year: props.year,
                    //       selectedYear: props.selectedOption,
                    //     },
                    //   }
                    // )
                    navigate(`/playersprofile/${tdata?.slug}/HCL-poker-results/${props?.year === "All Time" ? "all" : props?.year}`,
                      {
                        state: {
                          id: tdata?.playerId,
                          year: props.year,
                          selectedYear: props.selectedOption,
                        },
                      }
                    )
                  }
                >
                  <td className="leaderboard-table-data-index">
                    {limit * (Page - 1) + (i + 1)}
                  </td>
                  <td className="text-start leaderboard-table-data-name">
                    {tdata?.user_profile_pic && <img src={tdata?.user_profile_pic} alt="profile-img" />}
                    {tdata?.playerName ? tdata?.playerName : "-"}
                  </td>
                  <td
                    // className="leaderboard-table-data-results"
                    className={`${tdata?.result > 0
                      ? "profit-data leaderboard-table-data-results text-end"
                      : "loss-data leaderboard-table-data-results text-end"
                      }`}
                  >
                    {tdata?.result ? formatCurrency(tdata?.result) : "$0"}
                    {/* ${tdata?.results.toLocaleString()} */}
                  </td>
                  <td className="text-end">
                    {tdata?.winRate ? Math.round(tdata?.winRate) : "0"}%
                  </td>
                  <td
                    className={`text-end ${tdata?.session > 0 ? "profit-data" : "loss-data"
                      }`}
                  >
                    {/* ${tdata?.session.toLocaleString()} */}
                    {tdata?.session ? formatCurrency(tdata?.session) : "$0"}
                  </td>
                  <td className="text-end">
                    {tdata?.AVpip ? Math.round(tdata?.AVpip) : "0"}%
                  </td>
                  <td className="text-end">
                    {tdata?.APfr ? Math.round(tdata?.APfr) : "0"}%
                  </td>
                  <td className="text-end">
                    {tdata?.episodeCount ? tdata?.episodeCount : "-"}
                  </td>
                  <td className="table-last-extra"></td>
                </tr>
              ))
            )}
            {!loading && sortedData.length === 0 && (
              <tr>
                <td colSpan="12">
                  <div className="text-center">No data found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {sortedData.length !== 0 && (
        <div className="hustler-pagination">
          <nav>
            <Pagination className="pagination-main">
              <PaginationItem
                onClick={() => HandlePagePrev()}
                className="custom-page"
              >
                <PaginationLink previous tag="button">
                  <i
                    className="fa fa-angle-left fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>
              {paginationItems}
              <PaginationItem
                onClick={() => HandlePageNext()}
                className="custom-page"
              >
                <PaginationLink next tag="button">
                  <i
                    className="fa fa-angle-right fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </div>
      )}
    </div>
  );
};

// export default LeaderBoardStatsRegular;

export default connect(null, {
  GetAllRegulars,
})(LeaderBoardStatsRegular);
