import React, { useState } from "react";
import "./hustlerLiveResultTable.css";
import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const HustlerCasinoLiveResults = () => {
  // Sorting States
  const [orderBy, setOrderBy] = useState("results");
  const [order, setOrder] = useState("asc");
  const [selectedYear, setSelectedYear] = useState("2023");
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const optionsList = ["2023", "2022", "2021", "All Time"];
  const [tableData, setTableData] = useState([
    {
      id: 1,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 2,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 3,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 4,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 5,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 6,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 7,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 8,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 9,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 10,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 11,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 12,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 13,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 14,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 15,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 16,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 17,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 18,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 19,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 20,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 21,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 22,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 23,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 24,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 25,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 26,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 27,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 28,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 29,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 30,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 31,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 32,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 33,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 34,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 35,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 36,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 37,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 38,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 39,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 40,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 41,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 42,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 43,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 44,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 45,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 46,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 47,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 48,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 49,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 50,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
  ]);

  const paginationSection = () => {
    const data = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 9,
      nextPage: null,
      page: 1,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 5,
      totalPages: 4,
    };
    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= data.totalPages; i++) {
      if (
        i === 1 ||
        (data.page < 4 && i <= 5) ||
        i === data.page - 1 ||
        i === data.page + 1 ||
        i === data.page ||
        i === data.totalPages ||
        (data.page >= data.totalPages - 3 && i >= data.totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={data.page === i ? true : false}
              onClick={data.page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }
    return (
      <nav>
        <Pagination className="pagination-main">
          <PaginationItem
            onClick={
              data.hasPrevPage === true
                ? () => onPageClick(data.prevPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              previous
              disabled={data.hasPrevPage === true ? false : true}
              tag="button"
            >
              <i
                className="fa fa-angle-left fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
          {Pages}
          <PaginationItem
            onClick={
              data.hasNextPage === true
                ? () => onPageClick(data.nextPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              next
              tag="button"
              disabled={data.hasNextPage === true ? false : true}
            >
              <i
                className="fa fa-angle-right fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    );
  };

  const onPageClick = (page) => {
    var pageNum = page;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    handleSort(tableData, getComparator(isAsc ? "desc" : "asc", property));
  };

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="my-2 hustler-card">
        <div className="hustler-card-header">
          <h2>{`${selectedYear} Hustler Casino Live Results`}</h2>
          <div className="year-dropdown">
            {/* <select
              name="year-select"
              id="year-select"
              className="year-select"
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value={"2023"}>2023</option>
              <option value={"2022"}>2022</option>
              <option value={"2021"}>2021</option>
              <option value={"All Time"}>All Time</option>
            </select> */}
            <button
              type="button"
              aria-haspopup="listbox"
              aria-expanded={isOptionsOpen}
              className={isOptionsOpen ? "expanded" : ""}
              onClick={toggleOptions}
              onKeyDown={handleListKeyDown}
            >
              {optionsList[selectedOption]}
            </button>
            <ul
              className={`options ${isOptionsOpen ? "show" : ""}`}
              role="listbox"
              aria-activedescendant={optionsList[selectedOption]}
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
            >
              {optionsList.map((option, index) => (
                <li
                  id={option}
                  role="option"
                  aria-selected={selectedOption == index}
                  tabIndex={0}
                  onKeyDown={handleKeyDown(index)}
                  onClick={(e) => {
                    setSelectedThenCloseDropdown(index);
                    setSelectedYear(e.target.innerHTML);
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="hustler-card-body">
          <table className="table hustler-table">
            <thead className="hustler-table-head">
              <tr>
                <th className="text-start hustler-th-1">Date</th>
                <th className="text-start hustler-th-2">Episodes</th>
                <th
                  className="text-start hustler-th-3"
                  onClick={() => {
                    handleRequestSort("results");
                  }}
                >
                  Results
                  {/* {orderBy === "results" ? ( */}
                  <img
                    src={order === "desc" ? downArrow : upArrow}
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th
                  className="text-end hustler-th-4"
                  onClick={() => {
                    handleRequestSort("win_rate");
                  }}
                >
                  Vpip
                  {/* {orderBy === "win_rate" ? ( */}
                  <img
                    src={order === "desc" ? downArrow : upArrow}
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th
                  className="text-end hustler-th-5"
                  onClick={() => {
                    handleRequestSort("per_session");
                  }}
                >
                  Pre-flop
                  {/* {orderBy === "per_session" ? ( */}
                  <img
                    src={order === "desc" ? downArrow : upArrow}
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th
                  className="text-end hustler-th-6"
                  onClick={() => {
                    handleRequestSort("a_vpip");
                  }}
                >
                  Stakes
                  {/* {orderBy === "a_vpip" ? ( */}
                  <img
                    src={order === "desc" ? downArrow : upArrow}
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th className="hustler-table-last-extra"></th>
              </tr>
            </thead>

            <tbody className="hustler-table-body">
              {tableData.map((tdata, i) => (
                <tr key={i}>
                  <td className="text-start hustler-table-data-index">
                    {tdata?.date}
                  </td>
                  <td className="text-start hustler-table-data-name">
                    {tdata?.episodes}
                  </td>
                  <td className="text-start hustler-table-data-results">
                    {tdata?.results}
                  </td>
                  <td className="text-lg-end text-md-center">{tdata?.vvip}</td>
                  <td className="text-end">{tdata?.preflop}</td>
                  <td className="text-end">{tdata?.stakes}</td>
                  <td className="hustler-table-last-extra"></td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="hustler-pagination">{paginationSection()}</div>
        </div>
      </div>
    </>
  );
};

export default HustlerCasinoLiveResults;
