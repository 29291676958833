import axios from "axios";

const PROXY = process.env.REACT_APP_URL;

// const config = {
//     headers: {
//         "Content-Type": "application/json",
//     },
// };

export const GetAdvertiseBannerList = () => async (dispatch) => {
    const res = await axios.get(`${PROXY}advertise/list`);
    return res?.data?.result;
};
