import React, { useEffect, useState } from "react";

import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import "./leaderBoard.css";
import { Pagination } from "react-bootstrap";
import { PaginationItem, PaginationLink } from "reactstrap";
import { GetCommentaryData } from "../../store/slices/LeaderBoardTabsAction";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import OneDayLostCustomPagination from "./CommentaryPagination";
import defaultPicture from "../../assets/profilePic.png";

const LeaderBoardStatsCommentary = (props) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("result");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(100);
  const [Page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState("")
  const element = document.getElementById('main-leaderboard');

  useEffect(() => {
    if (props?.playerKey === "commentary") {
      setLoading(true);
      props
        .GetCommentaryData(
          props.year === "All Time" ? "all" : props.year,
          1,
          100
        )
        .then((res) => {
          setTableData(res);
          // setTotalPages(res?.totalPages)
          // setLimit(res?.limit)
          setLoading(false);
        });
    }
  }, [props.year]);

  const paginationHandle = async (e, p) => {
    setPage(p + 1);

    await props
      .GetCommentaryData(
        props.year === "All Time" ? "all" : props.year,
        p + 1,
        limit
      )
      .then((res) => {
        setTableData(res);
        // setTotalPages(res?.totalPages)
        // setLimit(res?.limit)
      });
  };

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const paginationSection = () => {
    const data = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 9,
      nextPage: null,
      page: 1,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 5,
      totalPages: 4,
    };
    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= data.totalPages; i++) {
      if (
        i === 1 ||
        (data.page < 4 && i <= 5) ||
        i === data.page - 1 ||
        i === data.page + 1 ||
        i === data.page ||
        i === data.totalPages ||
        (data.page >= data.totalPages - 3 && i >= data.totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={data.page === i ? true : false}
              onClick={data.page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }
    return (
      <nav>
        <Pagination className="pagination-main">
          <PaginationItem
            onClick={
              data.hasPrevPage === true
                ? () => onPageClick(data.prevPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              previous
              disabled={data.hasPrevPage === true ? false : true}
              tag="button"
            >
              <i
                className="fa fa-angle-left fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
          {Pages}
          <PaginationItem
            onClick={
              data.hasNextPage === true
                ? () => onPageClick(data.nextPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              next
              tag="button"
              disabled={data.hasNextPage === true ? false : true}
            >
              <i
                className="fa fa-angle-right fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    );
  };

  const onPageClick = (page) => {
    var pageNum = page;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const itemsPerPage = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Final pagination
  const maxPageDisplay = 3; // Adjust this value as needed
  const paginationItems = [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  // Helper function to generate pagination items
  const generatePaginationItems = (start, end) => {
    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
          className="active-tab"
        >
          {i}
        </Pagination.Item>
      );
    }
  };

  if (totalPages <= maxPageDisplay) {
    // Display all page numbers if there are few pages
    generatePaginationItems(1, totalPages);
  } else {
    // Calculate page ranges with ellipsis
    const leftEllipsis = currentPage > maxPageDisplay / 2 + 1;
    const rightEllipsis = currentPage < totalPages - maxPageDisplay / 2;

    if (leftEllipsis) {
      // Add the first page and left ellipsis
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
    }

    const startIndex = Math.max(
      1,
      currentPage - Math.floor(maxPageDisplay / 2)
    );
    const endIndex = Math.min(
      currentPage + Math.floor(maxPageDisplay / 2),
      totalPages
    );

    generatePaginationItems(startIndex, endIndex);

    if (rightEllipsis) {
      // Add the right ellipsis and last page
      paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  }

  const HandlePagePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const HandlePageNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <table className="table leaderboard-table">
          <thead className="leaderboard-table-head">
            <tr>
              <th className="leaderboard-commentary-th-1">#</th>
              <th className="text-start leaderboard-commentary-th-2">
                Commentator
              </th>
              {/* <th className="leaderboard-th-3 text-end"
                            >
                                Results
                            </th> */}
              {/* <th className="leaderboard-th-4 text-end"
                            >
                                Stakes
                            </th> */}
              <th className="leaderboard-episode-commentart-th-5 text-end">
                {/* Episode Number */}
                Number of Episodes
              </th>
              <th className="leaderboard-episode-commentart-th-5 text-end">
                Favorite Day
              </th>
              <th className="table-last-extra"></th>
            </tr>
          </thead>

          <tbody className="leaderboard-table-body">
            {loading && !currentItems.length && (
              <tr>
                <td className="leaderboard-table-data-index">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-start leaderboard-table-data-name">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="leaderboard-table-data-results">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-end">
                  <Skeleton
                    count={10}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                {/* <td className="text-end">
                                    <Skeleton count={10} />
                                </td> */}
              </tr>
            )}
            {currentItems.map((tdata, i) => (
              <tr className="leaderboard-tr" key={i} style={{ verticalAlign: "middle" }}>
                <td className="leaderboard-table-data-index">
                  {limit * (Page - 1) + (i + 1)}
                </td>
                <td className="text-start leaderboard-table-data-name-commentary">
                  {/* {tdata?.user_profile_pic && <img src={tdata?.user_profile_pic} alt="profile-img" />} */}
                  {tdata?.commenterName ? tdata?.commenterName : "-"}
                </td>
                {/* <td className="text-end" style={{ color: tdata?.result > 0 ? "#21b976" : "#fd0303" }}>{tdata?.result === null ? "$0" : formatCurrency(tdata?.result)}</td> */}
                {/* <td
                                    className={`text-end`}
                                >
                                    {tdata?.game_stakes}
                                </td> */}
                <td className="text-end">
                  {tdata?.episodeCount ? tdata?.episodeCount : "-"}
                </td>
                <td className="text-end">
                  {tdata?.favoriteDay ? tdata?.favoriteDay : "-"}
                </td>
                <td className="table-last-extra"></td>
              </tr>
            ))}
            {!loading && tableData.length === 0 && (
              <tr>
                <td colSpan="9">
                  <div className="text-center">No data found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <div className="hustler-pagination">
                <Pagination className="pagination-main">
                    <PaginationItem
                        onClick={() => HandlePagePrev()}
                        className="custom-page"
                    >
                        <PaginationLink
                            previous
                            tag="button"
                        >
                            <i
                                className="fa fa-angle-left fa-lg"
                                style={{ color: "#6D727E", fontSize: "20px" }}
                            ></i>
                        </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages)].map((page, i) => (
                        <PaginationItem active={i + 1 === Page} key={i}>
                            <PaginationLink onClick={e => paginationHandle(e, i)}>
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem
                        onClick={() => HandlePageNext()}
                        className="custom-page"
                    >
                        <PaginationLink
                            next
                            tag="button"
                        >
                            <i
                                className="fa fa-angle-right fa-lg"
                                style={{ color: "#6D727E", fontSize: "20px" }}
                            ></i>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </div> */}
      {/* {tableData.length !== 0 && <OneDayLostCustomPagination totalPages={totalPages} currentPage={Page} setPage={setPage} onChangePage={(e) => paginationHandle(e, Page)} year={props?.year} setTableData={setTableData} />} */}
      {currentItems.length !== 0 && (
        <div className="hustler-pagination">
          <nav>
            <Pagination className="pagination-main">
              <PaginationItem
                onClick={() => HandlePagePrev()}
                className="custom-page"
              >
                <PaginationLink previous tag="button">
                  <i
                    className="fa fa-angle-left fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>
              {paginationItems}
              <PaginationItem
                onClick={() => HandlePageNext()}
                className="custom-page"
              >
                <PaginationLink next tag="button">
                  <i
                    className="fa fa-angle-right fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </div>
      )}
    </div>
  );
};

export default connect(null, {
  GetCommentaryData,
})(LeaderBoardStatsCommentary);
