import React, { useCallback, useEffect, useState } from "react";
import { Row, Container, NavLink, NavItem, Nav } from "reactstrap";
import downArrow from "../../assets/down-arrow.svg";
import downArrowdark from "../../assets/down-arrow-dark.svg";
import upArrow from "../../assets/up-arrow.svg";
import upArrodark from "../../assets/up-arrow-dark.svg";
import "../LiveStream/leaderBoard.css";
import Footer from "../../DefaultLayout/Footer";
import Header from "../../DefaultLayout/Header";
import { GetAllRegulars } from "../../store/slices/LeaderBoardTabsAction";
import { GetAdvertiseBannerList } from "../../store/slices/AdvertiseAction";
import { connect, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "react-bootstrap";
import sortingIconDark from "../../assets/sortingIconblack.svg";
import sortingIconLight from "../../assets/sortingIconwhite-01.svg";
import darkMode from "../../assets/9254147_moon_crescent_night_mode_dark_icon (1).svg";
import lightMode from "../../assets/lightbulb.svg";
import { Link } from "react-router-dom";
import useRecursiveTimeout from "../../Helper/Advertise";
import { GetSearchResults } from "../../store/slices/ProfileByUser";

const SearchResult = (props) => {
  const { seTheme } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  // const [loading, setLoading] = useState(location.state?.loading);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [topBanner, setTopBanner] = useState("");
  const [bottomBanner, setBottomBanner] = useState("");
  const [topBannerLink, setTopBannerLink] = useState("");
  const [centerBannerLink, setCenterBannerLink] = useState("");
  const [bottomBannerLink, setBottomBannerLink] = useState("");
  const [loading, setLoading] = useState(false)

  const searchData = useSelector((state) => state.searchData);
  const { searchList } = searchData;
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  // useEffect(() => {
  //   if (searchList) {
  //     setLoading(location.state?.loading);
  //     setTimeout(function () {
  //       setTableData(searchList);
  //       setLoading(false);
  //     }, 2000);
  //   }
  // }, [location.state?.loading, searchList]);

  useEffect(() => {
    setLoading(true)
    props.GetSearchResults(location.state.value).then((res) => {
      setLoading(false)
      setTableData(res.data.result)
    });
  }, [location.state.value])

  useEffect(() => {
    setBannerLoading(true);
    // setLoading(true);

    props.GetAdvertiseBannerList().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
      setBannerLoading(false);
      // setLoading(false);I
    });
  }, []);

  useRecursiveTimeout(async () => {
    setBannerLoading(true);
    props.GetAdvertiseBannerList().then((res) => {
      setBannerLoading(false);
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, 300000);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const [orderBy, setOrderBy] = useState("episodeCount");
  const [order, setOrder] = useState("desc"); // Default sorting order

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      // Toggle sorting order if the same column is clicked
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Sort by a new column
      setOrderBy(property);
      setOrder("asc"); // Start with ascending order by default
    }
  };

  const itemsPerPage = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Sorting function
  const sortedData = currentItems.slice().sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const maxPageDisplay = 3; // Adjust this value as needed
  const paginationItems = [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Helper function to generate pagination items
  const generatePaginationItems = (start, end) => {
    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
          className="active-tab"
        >
          {i}
        </Pagination.Item>
      );
    }
  };

  if (totalPages <= maxPageDisplay) {
    // Display all page numbers if there are few pages
    generatePaginationItems(1, totalPages);
  } else {
    // Calculate page ranges with ellipsis
    const leftEllipsis = currentPage > maxPageDisplay / 2 + 1;
    const rightEllipsis = currentPage < totalPages - maxPageDisplay / 2;

    if (leftEllipsis) {
      // Add the first page and left ellipsis
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
    }

    const startIndex = Math.max(
      1,
      currentPage - Math.floor(maxPageDisplay / 2)
    );
    const endIndex = Math.min(
      currentPage + Math.floor(maxPageDisplay / 2),
      totalPages
    );

    generatePaginationItems(startIndex, endIndex);

    if (rightEllipsis) {
      // Add the right ellipsis and last page
      paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  }

  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, setTheme] = useState(userTheme);

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  };

  return (
    <>
      <Header id={1} seTheme={seTheme} value1={location.state.value} />

      {/* Tab Navigation */}
      <div className="container-fluid main-tab-nav">
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row h-100">
              <div className="col-lg-8 col-md-10 col-sm-12 col-12 d-flex">
                <Nav className="main-nav">
                  <NavItem className="main-nav-item">
                    <NavLink active href="/">
                      Hustler Casino Live
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/episode-list">Episode List</NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: "not-allowed" }}>
                    {/* <NavLink href={disabled ? "javascript: void (0);" : "/player-profile/:id/:year"} disabled>Player’s Profile</NavLink> */}
                    <NavLink href={"/player-profile"} disabled>
                      Player’s Profile
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="col-lg-4 col-md-2 text-end dark-mode-btn">
                <img
                  src={theme === "light" ? darkMode : lightMode}
                  alt="dark-mode-btn"
                  onClick={changeThemeHandler}
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid main-casino-live-update">
        <div
          className="main-banner"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {bannerLoading && (
            <Skeleton
              className="banner-img"
              baseColor={userTheme === "light" ? "#ebebeb" : "#1D1F23"}
            />
          )}
          {!bannerLoading && (
            windowSize > 426 ?
              <a href={topBannerLink} target="_blank" rel="noreferrer">
                <img src={topBanner} className="banner-img" />
              </a>
              :
              windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                <img src={bottomBanner} className="banner-img" />
              </a>
            // <a href={topBannerLink} target="_blank" rel="noreferrer">
            //   <img src={topBanner} className="banner-img" alt="#" />
            // </a>
          )}
        </div>
      </div>
      <Container fluid>
        <Row>
          <div className="mt-2 leaderboard-card">
            <div className="leaderboard-card-header search-header">
              <h2>{`Search Results ‘${location.state.value}’`} </h2>
              {<div className="search-result-div">{loading ? <Skeleton width={70} /> : `${sortedData.length} Results`}</div>}
            </div>
            <div className="leaderboard-card-body ">
              <div style={{ overflowX: "auto" }}>
                <table className="table leaderboard-table mb-0">
                  <thead className="leaderboard-table-head">
                    <tr>
                      <th className="leaderboard-th-1">#</th>
                      <th className="text-start leaderboard-th-2">
                        Players Name
                      </th>
                      <th
                        className="leaderboard-th-3 text-end"
                        onClick={() => {
                          handleRequestSort("result");
                        }}
                      >
                        Results
                        {orderBy === "result" ? (
                          <img
                            src={
                              order === "desc" && orderBy === "result"
                                ? userTheme === "light"
                                  ? downArrow
                                  : downArrowdark
                                : userTheme === "dark"
                                  ? upArrodark
                                  : upArrow
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        ) : (
                          <img
                            style={{ height: "11px", width: "9px" }}
                            src={
                              userTheme === "light"
                                ? sortingIconDark
                                : sortingIconLight
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        )}
                      </th>
                      <th
                        className="leaderboard-th-4 text-end"
                        onClick={() => {
                          handleRequestSort("winRate");
                        }}
                      >
                        Win Rate
                        {orderBy === "winRate" ? (
                          <img
                            src={
                              order === "desc" && orderBy === "winRate"
                                ? userTheme === "light"
                                  ? downArrow
                                  : downArrowdark
                                : userTheme === "dark"
                                  ? upArrodark
                                  : upArrow
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        ) : (
                          <img
                            style={{ height: "11px", width: "9px" }}
                            src={
                              userTheme === "light"
                                ? sortingIconDark
                                : sortingIconLight
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        )}
                      </th>
                      <th
                        className="leaderboard-th-5 text-end"
                        onClick={() => {
                          handleRequestSort("session");
                        }}
                      >
                        $/Session
                        {orderBy === "session" ? (
                          <img
                            src={
                              order === "desc" && orderBy === "session"
                                ? userTheme === "light"
                                  ? downArrow
                                  : downArrowdark
                                : userTheme === "dark"
                                  ? upArrodark
                                  : upArrow
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        ) : (
                          <img
                            style={{ height: "11px", width: "9px" }}
                            src={
                              userTheme === "light"
                                ? sortingIconDark
                                : sortingIconLight
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        )}
                      </th>
                      <th
                        className="text-end leaderboard-th-7"
                        onClick={() => {
                          handleRequestSort("AVpip");
                        }}
                      >
                        A.VPIP
                        {orderBy === "AVpip" ? (
                          <img
                            src={
                              order === "desc" && orderBy === "AVpip"
                                ? userTheme === "light"
                                  ? downArrow
                                  : downArrowdark
                                : userTheme === "dark"
                                  ? upArrodark
                                  : upArrow
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        ) : (
                          <img
                            style={{ height: "11px", width: "9px" }}
                            src={
                              userTheme === "light"
                                ? sortingIconDark
                                : sortingIconLight
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        )}
                      </th>
                      <th
                        className="text-end leaderboard-th-8"
                        onClick={() => {
                          handleRequestSort("APfr");
                        }}
                      >
                        A.PFR
                        {orderBy === "APfr" ? (
                          <img
                            src={
                              order === "desc" && orderBy === "APfr"
                                ? userTheme === "light"
                                  ? downArrow
                                  : downArrowdark
                                : userTheme === "dark"
                                  ? upArrodark
                                  : upArrow
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        ) : (
                          <img
                            style={{ height: "11px", width: "9px" }}
                            src={
                              userTheme === "light"
                                ? sortingIconDark
                                : sortingIconLight
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        )}
                      </th>
                      <th
                        className="text-end leaderboard-th-8"
                        onClick={() => {
                          handleRequestSort("episodeCount");
                        }}
                      >
                        Sessions
                        {orderBy === "episodeCount" ? (
                          <img
                            src={
                              order === "desc" && orderBy === "episodeCount"
                                ? userTheme === "light"
                                  ? downArrow
                                  : downArrowdark
                                : userTheme === "dark"
                                  ? upArrodark
                                  : upArrow
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        ) : (
                          <img
                            style={{ height: "11px", width: "9px" }}
                            src={
                              userTheme === "light"
                                ? sortingIconDark
                                : sortingIconLight
                            }
                            alt="sort-arrow"
                            className="leaderboard-sort-arrow"
                          />
                        )}
                      </th>
                      <th className="table-last-extra"></th>
                    </tr>
                  </thead>

                  <tbody className="leaderboard-table-body">
                    {loading ? (
                      <tr>
                        <td className="leaderboard-table-data-index">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-start leaderboard-table-data-name">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="leaderboard-table-data-results">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td>
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Skeleton
                            count={10}
                            baseColor={
                              userTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                      </tr>
                    ) : (
                      sortedData.map((tdata, i) => (
                        <tr
                          className="leaderboard-tr"
                          key={i}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            // navigate(
                            //   `/player-profile/${tdata?.playerId}/${props?.year}`,
                            //   {
                            //     state: {
                            //       id: tdata?.playerId,
                            //       year: props.year,
                            //       selectedYear: props.selectedOption,
                            //     },
                            //   }
                            // )
                            navigate(
                              // `/playersprofile/${tdata?.slug}/HCL-poker-results/${props?.year}`,
                              `/playersprofile/${tdata?.slug}/HCL-poker-results/all`,
                              {
                                state: {
                                  id: tdata?.playerId,
                                  year: props.year,
                                  selectedYear: props.selectedOption,
                                },
                              }
                            )
                          }
                        >
                          <td className="leaderboard-table-data-index">
                            {itemsPerPage * (currentPage - 1) + (i + 1)}
                          </td>
                          <td className="text-start leaderboard-table-data-name">
                            {tdata?.playerName ? tdata?.playerName : "-"}
                          </td>
                          <td
                            // className="leaderboard-table-data-results"
                            className={`${tdata?.result > 0
                              ? "profit-data leaderboard-table-data-results text-end"
                              : "loss-data leaderboard-table-data-results text-end"
                              }`}
                          >
                            {tdata?.result
                              ? formatCurrency(tdata?.result)
                              : "$0"}
                            {/* ${tdata?.results.toLocaleString()} */}
                          </td>
                          <td className="text-end">
                            {tdata?.winRate ? Math.round(tdata?.winRate) : "0"}%
                          </td>
                          <td
                            className={`text-end ${tdata?.session > 0 ? "profit-data" : "loss-data"
                              }`}
                          >
                            {/* ${tdata?.session.toLocaleString()} */}
                            {tdata?.session
                              ? formatCurrency(tdata?.session)
                              : "$0"}
                          </td>
                          <td className="text-end">
                            {tdata?.AVpip ? Math.round(tdata?.AVpip) : "0"}%
                          </td>
                          <td className="text-end">
                            {tdata?.APfr ? Math.round(tdata?.APfr) : "0"}%
                          </td>
                          <td className="text-end">
                            {tdata?.episodeCount ? tdata?.episodeCount : "-"}
                          </td>
                          <td className="table-last-extra"></td>
                        </tr>
                      ))
                    )}
                    {!loading && sortedData.length === 0 && (
                      <tr>
                        <td colSpan="10">
                          <div className="text-center">No data found</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {bannerLoading ? (
              <Skeleton
                className="banner-img-1"
                baseColor={userTheme === "light" ? "#ebebeb" : "#1D1F23"}
              />
            ) : (
              windowSize > 426 ?
                <a href={topBannerLink} target="_blank" rel="noreferrer">
                  <img src={topBanner} className="banner-img-1" />
                </a>
                :
                windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                  <img src={bottomBanner} className="banner-img-1" />
                </a>

              // <a href={bottomBannerLink} target="_blank" rel="noreferrer">
              //   <img src={bottomBanner} className="banner-img-small" />
              // </a>
            )}
          </div>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default connect(null, {
  GetAllRegulars,
  GetAdvertiseBannerList,
  GetSearchResults
})(SearchResult);
