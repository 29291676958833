import React, { useEffect, useState } from "react";
import "./hustlerLiveResultTable.css";
import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import { PaginationItem, PaginationLink } from "reactstrap";
import { Pagination } from "react-bootstrap";
import { GetUserEpisodesData } from "../../store/slices/ProfileByUser";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";

const HustlerCasinoLiveResults = (props) => {
  // Sorting States
  // const [orderBy, setOrderBy] = useState("results");
  // const [order, setOrder] = useState("asc");
  const navigate = useNavigate();
  const { id, year } = useParams();
  const [selectedYear, setSelectedYear] = useState(year);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props?.selectedYear);

  const [limit, setLimit] = useState(10);
  const [Page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");

  const [casinoLiveData, setCasinoLiveData] = useState([]);

  useEffect(() => {
    if (year === "2023") {
      setSelectedOption(0);
    } else if (year === "2022") {
      setSelectedOption(1);
    } else if (year === "2021") {
      setSelectedOption(2);
    } else if (year === "all") {
      setSelectedOption(3);
    }
    props
      .GetUserEpisodesData(id, year === "All Time" ? "all" : year, 1, limit)
      .then((res) => {
        setCasinoLiveData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
  }, [year]);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const optionsList = ["2023", "2022", "2021", "All Time"];
  const [tableData, setTableData] = useState([
    {
      id: 1,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 2,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 3,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 4,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 5,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 6,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 7,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 8,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 9,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 10,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 11,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 12,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 13,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 14,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 15,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 16,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 17,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 18,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 19,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 20,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 21,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 22,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 23,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 24,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 25,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 26,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 27,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 28,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 29,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 30,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 31,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 32,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 33,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 34,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 35,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 36,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 37,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 38,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 39,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 40,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 41,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 42,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 43,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 44,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 45,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 46,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 47,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 48,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 49,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
    {
      id: 50,
      date: "09/09/22",
      episodes: "Episode 266",
      results: "$1,117,000",
      vvip: "26%",
      preflop: "15%",
      stakes: "$200/400/800",
    },
  ]);

  const paginationSection = () => {
    const data = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 9,
      nextPage: null,
      page: Page,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 5,
      totalPages: totalPages,
    };
    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= data.totalPages; i++) {
      if (
        i === 1 ||
        (data.page < 4 && i <= 5) ||
        i === data.page - 1 ||
        i === data.page + 1 ||
        i === data.page ||
        i === data.totalPages ||
        (data.page >= data.totalPages - 3 && i >= data.totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={data.page === i ? true : false}
              onClick={data.page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }
    return (
      <nav>
        <Pagination className="pagination-main">
          <PaginationItem
            onClick={
              data.hasPrevPage === true
                ? () => onPageClick(data.prevPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              previous
              disabled={data.hasPrevPage === true ? false : true}
              tag="button"
            >
              <i
                className="fa fa-angle-left fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>

          {/* <PaginationItem active={i === Page} key={i}> */}
          {Pages}
          {/* </PaginationItem> */}

          <PaginationItem
            onClick={
              data.hasNextPage === true
                ? () => onPageClick(data.nextPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              next
              tag="button"
              disabled={data.hasNextPage === true ? false : true}
            >
              <i
                className="fa fa-angle-right fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    );
  };

  const onPageClick = (page) => {
    var pageNum = page;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  // const handleRequestSort = (property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);

  //   handleSort(tableData, getComparator(isAsc ? "desc" : "asc", property));
  // };

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);

    if (index === 0) {
      props.GetUserEpisodesData(id, "2023", Page, limit).then((res) => {
        navigate(`/player-profile/${id}/2023`);
        setCasinoLiveData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
    } else if (index === 1) {
      props.GetUserEpisodesData(id, "2022", Page, limit).then((res) => {
        navigate(`/player-profile/${id}/2022`);
        setCasinoLiveData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
    } else if (index === 2) {
      props.GetUserEpisodesData(id, "2021", Page, limit).then((res) => {
        navigate(`/player-profile/${id}/2021`);
        setCasinoLiveData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
    } else if (index === 3) {
      props.GetUserEpisodesData(id, "all", Page, limit).then((res) => {
        navigate(`/player-profile/${id}/all`);
        setCasinoLiveData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
    }

    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  const paginationHandle = async (e, p) => {
    setPage(p + 1);

    await props.GetUserEpisodesData(id, year, p + 1, limit).then((res) => {
      setCasinoLiveData(res?.docs);
      // setTotalPages(res?.totalPages)
      // setLimit(res?.limit)
    });
  };

  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setPage(currentPage);
  };

  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc"); // Default sorting order

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      // Toggle sorting order if the same column is clicked
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Sort by a new column
      setOrderBy(property);
      setOrder("asc"); // Start with ascending order by default
    }
  };

  // Sorting function
  const sortedData = casinoLiveData.slice().sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  // Final pagination
  const maxPageDisplay = 3; // Adjust this value as needed
  const paginationItems = [];

  const handlePageChange = (page) => {
    setPage(page);
    props
      .GetUserEpisodesData(id, year === "All Time" ? "all" : year, page, limit)
      .then((res) => {
        setCasinoLiveData(res?.docs);
        // setTotalPages(res?.totalPages)
        // setLimit(res?.limit)
      });
  };

  // Helper function to generate pagination items
  const generatePaginationItems = (start, end) => {
    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === Page}
          onClick={() => handlePageChange(i)}
          className="active-tab"
        >
          {i}
        </Pagination.Item>
      );
    }
  };

  if (totalPages <= maxPageDisplay) {
    // Display all page numbers if there are few pages
    generatePaginationItems(1, totalPages);
  } else {
    // Calculate page ranges with ellipsis
    const leftEllipsis = Page > maxPageDisplay / 2 + 1;
    const rightEllipsis = Page < totalPages - maxPageDisplay / 2;

    if (leftEllipsis) {
      // Add the first page and left ellipsis
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
    }

    const startIndex = Math.max(1, Page - Math.floor(maxPageDisplay / 2));
    const endIndex = Math.min(
      Page + Math.floor(maxPageDisplay / 2),
      totalPages
    );

    generatePaginationItems(startIndex, endIndex);

    if (rightEllipsis) {
      // Add the right ellipsis and last page
      paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  }

  const HandlePagePrev = () => {
    if (Page > 1) {
      setPage(Page - 1);
      props
        .GetUserEpisodesData(
          id,
          year === "All Time" ? "all" : year,
          Page - 1,
          limit
        )
        .then((res) => {
          setCasinoLiveData(res?.docs);
          // setTotalPages(res?.totalPages)
          // setLimit(res?.limit)
        });
    }
  };

  const HandlePageNext = () => {
    if (Page < totalPages) {
      setPage(Page + 1);
      props
        .GetUserEpisodesData(
          id,
          year === "All Time" ? "all" : year,
          Page + 1,
          limit
        )
        .then((res) => {
          setCasinoLiveData(res?.docs);
          // setTotalPages(res?.totalPages)
          // setLimit(res?.limit)
        });
    }
  };
  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  return (
    <>
      <div className="my-2 hustler-card">
        <div className="hustler-card-header">
          <h2>{`${selectedYear} Hustler Casino Live Results`}</h2>
          <div className="year-dropdown">
            <button
              type="button"
              aria-haspopup="listbox"
              aria-expanded={isOptionsOpen}
              className={isOptionsOpen ? "expanded" : ""}
              onClick={toggleOptions}
              onKeyDown={handleListKeyDown}
            >
              {optionsList[selectedOption]}
            </button>
            <ul
              className={`options ${isOptionsOpen ? "show" : ""}`}
              role="listbox"
              aria-activedescendant={optionsList[selectedOption]}
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
            >
              {optionsList.map((option, index) => (
                <li
                  id={option}
                  role="option"
                  aria-selected={selectedOption == index}
                  tabIndex={0}
                  onKeyDown={handleKeyDown(index)}
                  onClick={(e) => {
                    setSelectedThenCloseDropdown(index);
                    setSelectedYear(e.target.innerHTML);
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="hustler-card-body">
          <table className="table hustler-table">
            <thead className="hustler-table-head">
              <tr>
                <th className="text-start hustler-th-1">Date</th>
                <th className="text-start hustler-th-2">Episodes</th>
                <th
                  className="text-start hustler-th-3"
                  onClick={() => {
                    handleRequestSort("result");
                  }}
                >
                  Results
                  {/* {orderBy === "results" ? ( */}
                  <img
                    src={
                      order === "desc" && orderBy === "result"
                        ? downArrow
                        : upArrow
                    }
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th
                  className="text-end hustler-th-4"
                  onClick={() => {
                    handleRequestSort("vpip");
                  }}
                >
                  Vpip
                  {/* {orderBy === "win_rate" ? ( */}
                  <img
                    src={
                      order === "desc" && orderBy === "vpip"
                        ? downArrow
                        : upArrow
                    }
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th
                  className="text-end hustler-th-5"
                  onClick={() => {
                    handleRequestSort("pfr");
                  }}
                >
                  Pre-flop
                  {/* {orderBy === "per_session" ? ( */}
                  <img
                    src={
                      order === "desc" && orderBy === "pfr"
                        ? downArrow
                        : upArrow
                    }
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th
                  className="text-end hustler-th-6"
                  onClick={() => {
                    handleRequestSort("game_stakes");
                  }}
                >
                  Stakes
                  {/* {orderBy === "a_vpip" ? ( */}
                  <img
                    src={
                      order === "desc" && orderBy === "game_stakes"
                        ? downArrow
                        : upArrow
                    }
                    alt="sort-arrow"
                    className="hustler-sort-arrow"
                  />
                  {/* ) : null} */}
                </th>
                <th className="hustler-table-last-extra"></th>
              </tr>
            </thead>

            <tbody className="hustler-table-body">
              {sortedData.map((tdata, i) => (
                <tr key={i}>
                  <td className="text-start hustler-table-data-index">
                    {props.loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      tdata?.episode_date
                    )}
                  </td>
                  <td className="text-start hustler-table-data-name">
                    {props.loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      "Episode" + " " + tdata?.episode_number
                    )}
                  </td>
                  <td
                    className="text-start hustler-table-data-results"
                    style={{
                      width: "8%",
                      color: tdata?.result > 0 ? "#21b976" : "#fd0303",
                    }}
                  >
                    {props.loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      formatCurrency(tdata?.result)
                    )}
                  </td>
                  <td className="text-lg-end text-md-center">
                    {props.loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      Math.round(tdata?.vpip) + "%"
                    )}
                  </td>
                  <td className="text-end">
                    {props.loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : tdata?.pfr === "-" ? (
                      "0"
                    ) : (
                      Number(Math.round(tdata?.pfr)) + "%"
                    )}
                  </td>
                  <td className="text-end">
                    {props.loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      tdata?.game_stakes
                    )}
                  </td>
                  <td className="hustler-table-last-extra"></td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* <div className="hustler-pagination">{paginationSection()}</div> */}

          {/* <div className="hustler-pagination">
            <Pagination className="pagination-main">

              <PaginationItem
                // onClick={
                //   data.hasPrevPage === true
                //     ? () => onPageClick(data.prevPage)
                //     : null
                // }
                className="custom-page"
              >
                <PaginationLink
                  previous
                  // disabled={data.hasPrevPage === true ? false : true}
                  tag="button"
                >
                  <i
                    className="fa fa-angle-left fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>

              {[...Array(totalPages)].map((page, i) => (
                <PaginationItem active={i + 1 === Page} key={i}>
                  <PaginationLink onClick={e => paginationHandle(e, i)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem
                // onClick={
                //   data.hasNextPage === true
                //     ? () => onPageClick(data.nextPage)
                //     : null
                // }
                className="custom-page"
              >
                <PaginationLink
                  next
                  tag="button"
                // disabled={data.hasNextPage === true ? false : true}
                >
                  <i
                    className="fa fa-angle-right fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div > */}

          <div className="hustler-pagination">
            <nav>
              <Pagination className="pagination-main">
                <PaginationItem
                  onClick={() => HandlePagePrev()}
                  className="custom-page"
                >
                  <PaginationLink previous tag="button">
                    <i
                      className="fa fa-angle-left fa-lg"
                      style={{ color: "#6D727E", fontSize: "20px" }}
                    ></i>
                  </PaginationLink>
                </PaginationItem>
                {paginationItems}
                <PaginationItem
                  onClick={() => HandlePageNext()}
                  className="custom-page"
                >
                  <PaginationLink next tag="button">
                    <i
                      className="fa fa-angle-right fa-lg"
                      style={{ color: "#6D727E", fontSize: "20px" }}
                    ></i>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

// export default HustlerCasinoLiveResults;

export default connect(null, {
  GetUserEpisodesData,
})(HustlerCasinoLiveResults);
