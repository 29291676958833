import React, { useState, useEffect, useCallback } from "react";
import "./playerProfile.css";
import profilePicture from "../../assets/profilePic.png";
import sortArrow from "../../assets/Polygon.png";
import darkMode from "../../assets/9254147_moon_crescent_night_mode_dark_icon (1).svg";
import lightMode from "../../assets/lightbulb.svg";
import Header from "../../DefaultLayout/Header";
import Chart from "react-apexcharts";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import TotalEarningCard from "./totalEarningCard";
import HighsAndLowsCard from "./highAndLowCard";
import StakesBreakDownCard from "./stakesBreakdown";
import DiggingDetailsCard from "./diggingDetailsCard";
import HustlerCasinoLiveResults from "./hustlerCasinoLiveResults";
import Footer from "../../DefaultLayout/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  GetUserDataByIDYear,
  GetUserRankingNumber,
  GetUserEarningGraphData,
  GetUserEpisodesData,
} from "../../store/slices/ProfileByUser";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Pagination } from "react-bootstrap";
import { PaginationItem, PaginationLink } from "reactstrap";
import uparrowfooter from "../../assets/211624_c_up_arrow_icon.svg";
import sortingIconDark from "../../assets/sortingIconblack.svg";
import sortingIconLight from "../../assets/sortingIconwhite-01.svg";
import defaultIcon from "../../assets/profilePic.png";
import useRecursiveTimeout from "../../Helper/Advertise";
import { GetAdvertiseBannerList } from "../../store/slices/AdvertiseAction";
import downArrow from "../../assets/down-arrow.svg";
import downArrowdark from "../../assets/down-arrow-dark.svg";
import upArrow from "../../assets/up-arrow.svg";
import upArrodark from "../../assets/up-arrow-dark.svg";
import { Link } from "react-router-dom";

const PlayerProfile = (props, { seTheme }) => {

  const getCurrentYear = () => new Date().getFullYear();
  const currentYear = getCurrentYear();
  const yearsList = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());
  yearsList.push("All Time");

  const location = useLocation();
  const { id, year } = useParams();
  const navigate = useNavigate();
  const [playerData, setPlayerData] = useState([]);
  const [playerRank, setPlayerRank] = useState("");
  const [loading, setLoading] = useState(false);
  const [earningGraphData, setEarningGraphData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    location?.state?.selectedYear
  );

  const [limit, setLimit] = useState(100);
  const [Page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [casinoLiveData, setCasinoLiveData] = useState([]);

  const [topBanner, setTopBanner] = useState("");
  const [centerBanner, setCenterBanner] = useState("");
  const [bottomBanner, setBottomBanner] = useState("");
  const [topBannerLink, setTopBannerLink] = useState("");
  const [centerBannerLink, setCenterBannerLink] = useState("");
  const [bottomBannerLink, setBottomBannerLink] = useState("");
  const [bannerLoading, setBannerLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setBannerLoading(true);

    for (let i = 0; i < yearsList.length; i++) {
      if (year == yearsList[i]) {
        setSelectedOption(i);
      } else if (year == "all") {
        setSelectedOption(yearsList.length - 1);
      }
    }

    // if (year === 2024) {
    //   setSelectedOption(0);
    // } else if (year === "2023") {
    //   setSelectedOption(1);
    // } else if (year === "2022") {
    //   setSelectedOption(2);
    // } else if (year === "2021") {
    //   setSelectedOption(3);
    // } else if (year === "all") {
    //   setSelectedOption(4);
    // }

    props
      .GetUserDataByIDYear(id, year === "All Time" ? "all" : year)
      .then((res1) => {
        setPlayerData(res1);
        setLoading(false);
        // res1?.sessions >= 20 &&
        // res1?.sessions > 0 &&
        // props
        //   .GetUserEarningGraphData(id, year === "All Time" ? "all" : year)
        //   .then((res2) => {
        //     setEarningGraphData(res2);
        //   });
      });
    props
      .GetUserEarningGraphData(id, year === "All Time" ? "all" : year)
      .then((res2) => {
        setEarningGraphData(res2);
      });
    props
      .GetUserRankingNumber(id, year === "All Time" ? "all" : year)
      .then((res) => {
        setPlayerRank(res);
      });
    props
      .GetUserEpisodesData(id, year === "All Time" ? "all" : year, 1, 100)
      .then((res) => {
        setCasinoLiveData(res?.docs);
        setTotalPages(res?.totalPages);
        setLimit(res?.limit);
      });
    props.GetAdvertiseBannerList().then((res) => {
      setBannerLoading(false);
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });

    // remove (year) dependency temporarily
  }, [location?.state?.playerid]);

  useRecursiveTimeout(async () => {
    props.GetAdvertiseBannerList().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, 300000);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, setTheme] = useState(userTheme);

  const [selectedYear, setSelectedYear] = useState(year);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isOptionsOpen1, setIsOptionsOpen1] = useState(false);

  const [isFixed, setIsFixed] = useState(false);
  const [isFixedMD, setIsFixedMD] = useState(false);
  const [isFixedMobile, setIsFixedMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 200) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

      if (scrollPosition > 199) {
        setIsFixedMD(true);
      } else {
        setIsFixedMD(false);
      }

      if (scrollPosition > 155) {
        setIsFixedMobile(true);
      } else {
        setIsFixedMobile(false);
      }

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const optionsList = yearsList;
  // const optionsList = ["2023", "2022", "2021", "All Time"];

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    props.seTheme(newTheme);
  };
  window.onscroll = () => {
    toggleTopButton();
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function toggleTopButton() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("back-to-up") &&
        document.getElementById("back-to-up").classList.remove("d-none");
    } else {
      document.getElementById("back-to-up") &&
        document.getElementById("back-to-up").classList.add("d-none");
    }
  }

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const toggleOptions1 = () => {
    setIsOptionsOpen1(!isOptionsOpen1);
  };

  const setSelectedThenCloseDropdown = (index, option) => {
    setLoading(true);
    setSelectedOption(index);

    // if (index === 0) {

    if (option === undefined) {
      setSelectedOption(0)
      navigate(`/playersprofile/${id}/HCL-poker-results/${option === "All Time" ? "all" : currentYear}`);
    }
    else {
      navigate(`/playersprofile/${id}/HCL-poker-results/${option === "All Time" ? "all" : option}`);

      props
        .GetUserDataByIDYear(id, option === "All Time" ? "all" : option)
        .then((res) => {
          setPlayerData(res);
          setLoading(false);
          // playerData?.sessions >= 20 &&
          // props.GetUserEarningGraphData(id, option === "All Time" ? "all" : option)
          //   .then((res) => {
          //     setEarningGraphData(res);
          //   });
        });
      props.GetUserEarningGraphData(id, option === "All Time" ? "all" : option)
        .then((res) => {
          setEarningGraphData(res);
        });
      props
        .GetUserRankingNumber(id, option === "All Time" ? "all" : option)
        .then((res) => {
          setPlayerRank(res);
        });
      props
        .GetUserEpisodesData(id, option === "All Time" ? "all" : option, Page, limit)
        .then((res) => {
          // navigate(`/player-profile/${id}/${index === 0 ? "2023" : index === 1 ? "2022" : index === 2 ? "2021" : index === 3 ? "all" : ""}`)
          setCasinoLiveData(res?.docs);
          setTotalPages(res?.totalPages);
          setLimit(res?.limit);
        });

    }

    // }
    // else if (index === 1) {
    //   navigate(`/player-profile/${id}/${selectedYear}`)
    //   props.GetUserDataByIDYear(id, selectedYear).then((res) => {
    //     setPlayerData(res)
    //     setLoading(false)
    //   })
    //   props.GetUserRankingNumber(id, selectedYear).then((res) => {
    //     setPlayerRank(res)
    //   })
    //   playerData?.sessions >= 19 && props.GetUserEarningGraphData(id, selectedYear).then((res) => {
    //     setEarningGraphData(res)
    //   })
    //   props.GetUserEpisodesData(id, selectedYear, Page, limit).then((res) => {
    //     navigate(`/player-profile/${id}/${selectedYear}`)
    //     setCasinoLiveData(res?.docs)
    //     setTotalPages(res?.totalPages)
    //     setLimit(res?.limit)
    //   })
    // }
    // else if (index === 2) {
    //   navigate(`/player-profile/${id}/2021`)
    //   props.GetUserDataByIDYear(id, "2021").then((res) => {
    //     setPlayerData(res)
    //     setLoading(false)
    //   })
    //   props.GetUserRankingNumber(id, "2021").then((res) => {
    //     setPlayerRank(res)
    //   })
    //   playerData?.sessions >= 19 && props.GetUserEarningGraphData(id, "2021").then((res) => {
    //     setEarningGraphData(res)
    //   })
    //   props.GetUserEpisodesData(id, "2021", Page, limit).then((res) => {
    //     navigate(`/player-profile/${id}/2021`)
    //     setCasinoLiveData(res?.docs)
    //     setTotalPages(res?.totalPages)
    //     setLimit(res?.limit)
    //   })
    // }
    // else if (index === 3) {
    //   navigate(`/player-profile/${id}/all`)
    //   props.GetUserDataByIDYear(id, "all").then((res) => {
    //     setPlayerData(res)
    //     setLoading(false)
    //   })
    //   props.GetUserRankingNumber(id, "all").then((res) => {
    //     setPlayerRank(res)
    //   })
    //   playerData?.sessions >= 19 && props.GetUserEarningGraphData(id, "all").then((res) => {
    //     setEarningGraphData(res)
    //   })
    //   props.GetUserEpisodesData(id, "all", Page, limit).then((res) => {
    //     navigate(`/player-profile/${id}/all`)
    //     setCasinoLiveData(res?.docs)
    //     setTotalPages(res?.totalPages)
    //     setLimit(res?.limit)
    //   })
    // }

    setIsOptionsOpen(false);
    setIsOptionsOpen1(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  const [orderBy, setOrderBy] = useState("episode_number");
  const [order, setOrder] = useState("desc"); // Default sorting order

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      // Toggle sorting order if the same column is clicked
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Sort by a new column
      setOrderBy(property);
      setOrder("asc"); // Start with ascending order by default
    }
  };

  // Sorting function
  const sortedData = casinoLiveData.slice().sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  // Final pagination
  const maxPageDisplay = 3; // Adjust this value as needed
  const paginationItems = [];

  const handlePageChange = (page) => {
    setPage(page);
    props
      .GetUserEpisodesData(id, year === "All Time" ? "all" : year, page, limit)
      .then((res) => {
        setCasinoLiveData(res?.docs);
        // setTotalPages(res?.totalPages)
        // setLimit(res?.limit)
      });
  };

  // Helper function to generate pagination items
  const generatePaginationItems = (start, end) => {
    for (let i = start; i <= end; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === Page}
          onClick={() => handlePageChange(i)}
          className="active-tab"
        >
          {i}
        </Pagination.Item>
      );
    }
  };

  if (totalPages <= maxPageDisplay) {
    // Display all page numbers if there are few pages
    generatePaginationItems(1, totalPages);
  } else {
    // Calculate page ranges with ellipsis
    const leftEllipsis = Page > maxPageDisplay / 2 + 1;
    const rightEllipsis = Page < totalPages - maxPageDisplay / 2;

    if (leftEllipsis) {
      // Add the first page and left ellipsis
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
    }

    const startIndex = Math.max(1, Page - Math.floor(maxPageDisplay / 2));
    const endIndex = Math.min(
      Page + Math.floor(maxPageDisplay / 2),
      totalPages
    );

    generatePaginationItems(startIndex, endIndex);

    if (rightEllipsis) {
      // Add the right ellipsis and last page
      paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  }

  const HandlePagePrev = () => {
    if (Page > 1) {
      setPage(Page - 1);
      props
        .GetUserEpisodesData(
          id,
          year === "All Time" ? "all" : year,
          Page - 1,
          limit
        )
        .then((res) => {
          setCasinoLiveData(res?.docs);
          // setTotalPages(res?.totalPages)
          // setLimit(res?.limit)
        });
    }
  };

  const HandlePageNext = () => {
    if (Page < totalPages) {
      setPage(Page + 1);
      props
        .GetUserEpisodesData(
          id,
          year === "All Time" ? "all" : year,
          Page + 1,
          limit
        )
        .then((res) => {
          setCasinoLiveData(res?.docs);
          // setTotalPages(res?.totalPages)
          // setLimit(res?.limit)
        });
    }
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <>
      <Header seTheme={props?.seTheme} />

      {/* Tab Navigation */}
      <div className="container-fluid main-tab-nav">
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row h-100">
              <div className="col-lg-8 col-md-10 col-sm-12 col-12 d-flex">
                <Nav className="main-nav">
                  <NavItem>
                    <NavLink href="/">Hustler Casino Live</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/episode-list">Episode List</NavLink>
                  </NavItem>
                  <NavItem className="main-nav-item">
                    <NavLink active href={`/playersprofile/${id}/HCL-poker-results/${year}`}>
                      Player’s Profile
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="col-lg-4 col-md-2 text-end dark-mode-btn">
                <img
                  src={theme === "light" ? darkMode : lightMode}
                  alt="dark-mode-btn"
                  onClick={changeThemeHandler}
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container fluid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {bannerLoading ? (
            <Skeleton
              height={"90px"}
              className="banner-img-episode"
              baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
            />
          ) : (
            windowSize > 426 ?
              <a href={topBannerLink} target="_blank" rel="noreferrer">
                <img src={topBanner} className="banner-img-episode" />
              </a>
              :
              windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                <img src={bottomBanner} className="banner-img-episode" />
              </a>
            // <a href={topBannerLink} target="_blank" rel="noreferrer">
            //   <img src={topBanner} className="banner-img-episode" />
            // </a>
          )}
        </div>
      </Container>

      {/* 1024px */}
      {windowSize >= 769 && (
        <div className={isFixed ? "container-fluid main-c-fixed" : "container-fluid main-c"}>
          {playerData?.sessions >= 20 ? (
            <div className="row main-r">

              {playerData?.user_profile_pic !== ""
                ?
                <div className={isFixed ? "col-lg-12 col-md-12 col-sm-12 d-flex align-items-center first-row-fixed-above-19" : "col-lg-12 col-md-12 col-sm-12 d-flex align-items-center first-row-above-19"}>
                  <div className="player-image col-lg-10 col-md-10 col-sm-2">

                    <div className="player-image col-lg-1 col-md-1">
                      <img src={playerData?.user_profile_pic === "" ? defaultIcon : playerData?.user_profile_pic} class="rounded" alt="..." />
                    </div>

                    <div className="col-lg-11 col-md-11">
                      <div
                        className="player-name col-lg-8 col-md-12"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          <h2 className="m-0">
                            {loading ? (
                              <Skeleton
                                width={230}
                                baseColor={
                                  StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                                }
                              />
                            ) : (
                              playerData?.user_first_name && playerData?.user_last_name ?
                                playerData?.user_first_name + " " + playerData?.user_last_name :
                                playerData?.user_first_name ? playerData?.user_first_name : "-" ?
                                  playerData?.user_last_name ? playerData?.user_last_name : "-" : "-"
                            )}
                          </h2>
                        </div>

                        <div className="last-played" style={{ marginLeft: "40px" }}>
                          <p className="m-0">
                            Last Played on Stream -{" "}
                            <span>
                              {loading ? (
                                <Skeleton
                                  width={80}
                                  baseColor={
                                    StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                                  }
                                />
                              ) : playerData?.last_played ? (
                                playerData?.last_played
                              ) : (
                                "-"
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="first-row col-lg-11 col-md-11"> */}
                  {/* <div className="col-lg-4">
                    <div className="last-played">
                      <p className="m-0">
                        Last Played on Stream - <span>{loading ? <Skeleton width={80} /> : playerData?.last_played ? playerData?.last_played : "-"}</span>
                      </p>
                    </div>
                  </div> */}
                  {/* </div> */}

                  <div className="first-row col-lg-2 col-md-2">
                    <div className="year-dropdown-selection col-lg-12 col-md-12">
                      <button
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded={isOptionsOpen}
                        className={isOptionsOpen ? "expanded" : ""}
                        onClick={toggleOptions}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList[selectedOption]}
                      </button>
                      <ul
                        className={`options ${isOptionsOpen ? "show" : ""}`}
                        role="listbox"
                        aria-activedescendant={optionsList[selectedOption]}
                        tabIndex={-1}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList.map((option, index) => (
                          <li
                            id={option}
                            role="option"
                            aria-selected={selectedOption == index}
                            tabIndex={0}
                            onKeyDown={handleKeyDown(index)}
                            onClick={(e) => {
                              setSelectedThenCloseDropdown(index, option);
                              setSelectedYear(e.target.innerHTML);
                            }}
                            className="text-center"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                :
                <div className={isFixed ? "col-lg-12 col-md-12 col-sm-12 d-flex align-items-center first-row-fixed-above-19" : "col-lg-12 col-md-12 col-sm-12 d-flex align-items-center first-row-above-19"}>
                  {/* <div className="player-image col-lg-1 col-sm-2"> */}
                  {/* <img src={playerData?.user_profile_pic === "" ? defaultIcon : playerData?.user_profile_pic} class="rounded" alt="..." /> */}
                  {/* </div> */}

                  <div className="first-row col-lg-12 col-md-12">
                    <div
                      className="player-name col-lg-8 col-md-7"
                      style={{ display: "flex" }}
                    >
                      <div>
                        <h2 className="m-0">
                          {loading ? (
                            <Skeleton
                              width={230}
                              baseColor={
                                StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                              }
                            />
                          ) : (
                            playerData?.user_first_name && playerData?.user_last_name ?
                              playerData?.user_first_name + " " + playerData?.user_last_name :
                              playerData?.user_first_name ? playerData?.user_first_name : "-" ?
                                playerData?.user_last_name ? playerData?.user_last_name : "-" : "-"
                          )}
                        </h2>
                      </div>

                      <div className="last-played" style={{ marginLeft: "40px" }}>
                        <p className="m-0">
                          Last Played on Stream -{" "}
                          <span>
                            {loading ? (
                              <Skeleton
                                width={80}
                                baseColor={
                                  StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                                }
                              />
                            ) : playerData?.last_played ? (
                              playerData?.last_played
                            ) : (
                              "-"
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-lg-4">
                    <div className="last-played">
                      <p className="m-0">
                        Last Played on Stream - <span>{loading ? <Skeleton width={80} /> : playerData?.last_played ? playerData?.last_played : "-"}</span>
                      </p>
                    </div>
                  </div> */}
                    <div className="year-dropdown-selection col-lg-4 col-md-5">
                      <button
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded={isOptionsOpen}
                        className={isOptionsOpen ? "expanded" : ""}
                        onClick={toggleOptions}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList[selectedOption]}
                      </button>
                      <ul
                        className={`options ${isOptionsOpen ? "show" : ""}`}
                        role="listbox"
                        aria-activedescendant={optionsList[selectedOption]}
                        tabIndex={-1}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList.map((option, index) => (
                          <li
                            id={option}
                            role="option"
                            aria-selected={selectedOption == index}
                            tabIndex={0}
                            onKeyDown={handleKeyDown(index)}
                            onClick={(e) => {
                              setSelectedThenCloseDropdown(index, option);
                              setSelectedYear(e.target.innerHTML);
                            }}
                            className="text-center"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              }

              <div
                className="col-lg-12 col-sm-12"
                style={{ paddingLeft: "18px" }}
              >
                <div className={isFixed ? "second-row-fixed" : "second-row"}>
                  <div className="head">
                    <h3>Ranking #</h3>
                    <h1 style={{ textTransform: "capitalize" }}>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerRank ? (
                        playerRank
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Profit/Loss</h3>
                    <h1
                      style={{
                        color:
                          playerData?.profit_loss > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.profit_loss ? (
                        formatCurrency(playerData?.profit_loss)
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Win Rate %</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.win_rate ? (
                        Math.round(playerData?.win_rate) + "%"
                      ) : (
                        "0"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>A. VPIP%</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_vpip ? (
                        Math.round(playerData?.avg_vpip) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>A. Pre Flop %</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_pre_flop ? (
                        Math.round(playerData?.avg_pre_flop) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Sessions</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.sessions ? (
                        playerData?.sessions
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Session Avg.</h3>
                    <h1
                      style={{
                        color:
                          playerData?.avg_session > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {" "}
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_session ? (
                        formatCurrency(Math.round(playerData?.avg_session))
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Current Streak</h3>
                    <h1
                      style={{
                        color:
                          playerData?.positive_streak === true
                            ? "#21b976"
                            : "#fd0303",
                      }}
                    >
                      {" "}
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.positive_streak === true ? (
                        "+" + playerData?.current_streak
                      ) : playerData?.positive_streak === false ? (
                        "-" + playerData?.current_streak
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  {/* <div className="head">
                <h3>Best Month</h3>
                <h1 style={{ color: playerData?.best_month_amount > 0 ? "#21b976" : "#fd0303" }}>{formatCurrency(playerData?.best_month_amount)}</h1>
              </div> */}
                </div>
              </div>
            </div>
          ) : (
            // Full screen player data for <20
            <div className="row main-r-below-19">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className={isFixed ? 'fixed col-lg-12 col-md-12' : 'first-row-below-19 col-lg-12 col-md-12'} id="fixedFirstRow">
                    {/* <div className='first-row-below-19 col-lg-12 col-md-12'> */}
                    <div className="player-name col-lg-9 col-md-9 d-flex align-items-center">
                      <div>
                        <h2 className="m-0">
                          {loading ? (
                            <Skeleton
                              width={230}
                              baseColor={
                                StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                              }
                            />
                          ) : (
                            playerData?.user_first_name && playerData?.user_last_name ?
                              playerData?.user_first_name + " " + playerData?.user_last_name :
                              playerData?.user_first_name ? playerData?.user_first_name : "-" ?
                                playerData?.user_last_name ? playerData?.user_last_name : "-" : "-"
                          )}
                        </h2>
                      </div>
                      <div
                        className="last-played"
                        style={{ marginLeft: "40px" }}
                      >
                        <p className="m-0">
                          Last Played on Stream -{" "}
                          <span>
                            {loading ? (
                              <Skeleton
                                width={80}
                                baseColor={
                                  StoredTheme === "light"
                                    ? "#ebebeb"
                                    : "#1D1F23"
                                }
                              />
                            ) : playerData?.last_played ? (
                              playerData?.last_played
                            ) : (
                              "-"
                            )}
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-5">
                    <div className="last-played ">
                      <p className="m-0">
                        Last Played on Stream - <span>{loading ? <Skeleton width={80} /> : playerData?.last_played ? playerData?.last_played : "-"}</span>
                      </p>
                    </div>
                  </div> */}
                    <div className="year-dropdown-selection col-lg-3 col-md-3">
                      <button
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded={isOptionsOpen}
                        className={isOptionsOpen ? "expanded" : ""}
                        onClick={toggleOptions}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList[selectedOption]}
                      </button>
                      <ul
                        className={`options ${isOptionsOpen ? "show" : ""}`}
                        role="listbox"
                        aria-activedescendant={optionsList[selectedOption]}
                        tabIndex={-1}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList.map((option, index) => (
                          <li
                            id={option}
                            role="option"
                            aria-selected={selectedOption == index}
                            tabIndex={0}
                            onKeyDown={handleKeyDown(index)}
                            onClick={(e) => {
                              setSelectedThenCloseDropdown(index, option);
                              setSelectedYear(e.target.innerHTML);
                            }}
                            className="text-center"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className={isFixed ? "second-fixed-row-below-19" : "second-row-below-19"}>
                  <div className="head">
                    <h3>Profit/Loss</h3>
                    <h1
                      style={{
                        color:
                          playerData?.profit_loss > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.profit_loss ? (
                        formatCurrency(playerData?.profit_loss)
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Ranking #</h3>
                    <h1 style={{ textTransform: "capitalize" }}>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerRank ? (
                        playerRank
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Win Rate %</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.win_rate ? (
                        Math.round(playerData?.win_rate) + "%"
                      ) : (
                        "0"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>A. VPIP%</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_vpip ? (
                        Math.round(playerData?.avg_vpip) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>A. Pre Flop %</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_pre_flop ? (
                        Math.round(playerData?.avg_pre_flop) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Sessions</h3>
                    <h1>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.sessions ? (
                        playerData?.sessions
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Session Avg.</h3>
                    <h1
                      style={{
                        color:
                          playerData?.avg_session > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {" "}
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_session ? (
                        formatCurrency(Math.round(playerData?.avg_session))
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                  <div className="head">
                    <h3>Current Streak</h3>
                    <h1
                      style={{
                        color:
                          playerData?.positive_streak === true
                            ? "#21b976"
                            : "#fd0303",
                      }}
                    >
                      {" "}
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.positive_streak === true ? (
                        "+" + playerData?.current_streak
                      ) : playerData?.positive_streak === false ? (
                        "-" + playerData?.current_streak
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* 786px */}
      {windowSize >= 426 && (
        <div className="main-second-content">
          <div className={isFixedMD ? "main-second-content-fixed d-md-flex d-sm-flex d-flex" : "main-second-content-not-fixed d-md-flex d-sm-flex d-flex"}>
            {playerData?.sessions >= 20 && (
              playerData?.user_profile_pic !== "" &&
              <div className="col-md-1 col-sm-2 col-2 player-image mt-2">
                {loading ? (
                  <Skeleton circle width={40} height={40} />
                ) : (
                  <img
                    src={
                      playerData?.user_profile_pic === ""
                        ? defaultIcon
                        : playerData?.user_profile_pic
                    }
                    class="rounded"
                    alt="..."
                  />
                )}
              </div>
            )}

            <div
              className={
                playerData?.sessions >= 20
                  ? playerData?.user_profile_pic !== "" ? "col-md-11 col-sm-11 col-11 d-md-flex d-flex mt-2" : "col-md-12 col-sm-12 col-12 d-md-flex d-flex mt-2"
                  : "col-md-12 col-sm-12 col-12 d-md-flex d-flex mt-2"
              }
            >
              <div className="col-md-9 col-sm-8 col-8 d-flex align-items-center">
                <div className="player-name">
                  <h2>
                    {loading ? (
                      <Skeleton
                        width={100}
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      playerData?.user_first_name && playerData?.user_last_name ?
                        playerData?.user_first_name + " " + playerData?.user_last_name :
                        playerData?.user_first_name ? playerData?.user_first_name : "-" ?
                          playerData?.user_last_name ? playerData?.user_last_name : "-" : "-"
                    )}
                  </h2>
                </div>
                <div>
                  <div className="last-played" style={{ marginLeft: "40px" }}>
                    <p className="m-0">
                      Last Played on Stream -{" "}
                      <span>
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : playerData?.last_played ? (
                          playerData?.last_played
                        ) : (
                          "-"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-4 col-4">
                <div className="year-dropdown-selection col-lg-4">
                  {/* <select
                  name="year-select"
                  id="year-select"
                  className="year-selection"
                >
                  <option value={"2023"}>2023</option>
                  <option value={"2022"}>2022</option>
                  <option value={"2021"}>2021</option>
                  <option value={"2020"}>2020</option>
                </select> */}
                  <button
                    type="button"
                    aria-haspopup="listbox"
                    aria-expanded={isOptionsOpen}
                    className={isOptionsOpen ? "expanded" : ""}
                    onClick={toggleOptions}
                    onKeyDown={handleListKeyDown}
                  >
                    {optionsList[selectedOption]}
                  </button>
                  <ul
                    className={`options ${isOptionsOpen ? "show" : ""}`}
                    role="listbox"
                    aria-activedescendant={optionsList[selectedOption]}
                    tabIndex={-1}
                    onKeyDown={handleListKeyDown}
                  >
                    {optionsList.map((option, index) => (
                      <li
                        id={option}
                        role="option"
                        aria-selected={selectedOption == index}
                        tabIndex={0}
                        onKeyDown={handleKeyDown(index)}
                        onClick={(e) => {
                          setSelectedThenCloseDropdown(index, option);
                          setSelectedYear(e.target.innerHTML);
                        }}
                        className="text-center"
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="hr-tag" />
          {/* 786px */}
          <div
            className={isFixedMD ? "col-md-12 second-main-row-fixed d-md-flex d-flex" : "col-md-12 second-main-row d-md-flex d-flex"}
            style={{ paddingLeft: "4px" }}
          >
            <div className="head">
              <h3 class="mb-1">Ranking #</h3>
              <h1 style={{ textTransform: "capitalize" }}>
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : playerRank ? (
                  playerRank
                ) : (
                  "-"
                )}
              </h1>
            </div>
            <div className="head">
              <h3 class="mb-1">Profit/Loss</h3>
              <h1
                style={{
                  color: playerData?.profit_loss > 0 ? "#21b976" : "#fd0303",
                }}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : playerData?.profit_loss ? (
                  formatCurrency(playerData?.profit_loss)
                ) : (
                  "-"
                )}
              </h1>
            </div>
            <div className="head">
              <h3 class="mb-1">Win Rate %</h3>
              <h1>
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : playerData?.win_rate ? (
                  Math.round(playerData?.win_rate) + "%"
                ) : (
                  "0"
                )}
              </h1>
            </div>
            <div className="head">
              <h3 class="mb-1">Session Avg.</h3>
              <h1
                style={{
                  color: playerData?.avg_session > 0 ? "#21b976" : "#fd0303",
                }}
              >
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : playerData?.avg_session ? (
                  formatCurrency(Math.round(playerData?.avg_session))
                ) : (
                  "-"
                )}
              </h1>
            </div>
            <div className="head">
              <h3 class="mb-1">A. VPIP%</h3>
              <h1>
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : playerData?.avg_vpip ? (
                  Math.round(playerData?.avg_vpip) + "%"
                ) : (
                  "-"
                )}
              </h1>
            </div>
            <div className="head">
              <h3 class="mb-1">A. Pre Flop %</h3>
              <h1>
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : playerData?.avg_pre_flop ? (
                  Math.round(playerData?.avg_pre_flop) + "%"
                ) : (
                  "-"
                )}
              </h1>
            </div>
            {/* <div className="head">
            <h3 class="mb-1">Last 10 Games</h3>
            <h1>{formatCurrency(playerData?.avg_last10_win_loss)}</h1>
          </div> */}
          </div>
          <hr className="hr-tag" />
          <div class="col-md-12 d-md-flex d-flex second-main-row-div-2">
            <div className="col-md-2">
              <div className="head">
                <h3 class="mb-1">Sessions</h3>
                <h1>
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : playerData?.sessions ? (
                    playerData?.sessions
                  ) : (
                    "-"
                  )}
                </h1>
              </div>
            </div>

            <div className="col-md-2 sm-current-streak">
              <div className="head">
                <h3 class="mb-1">Current </h3>
                <h1
                  style={{
                    color:
                      playerData?.positive_streak === true
                        ? "#21b976"
                        : "#fd0303",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : playerData?.positive_streak === true ? (
                    "+" + playerData?.current_streak
                  ) : playerData?.positive_streak === false ? (
                    "-" + playerData?.current_streak
                  ) : (
                    "-"
                  )}
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 425px */}
      {playerData?.sessions >= 20 ? (
        <div className="main-third-content">
          {/* {playerData?.sessions >= 19 &&
            <div className="player-image-third mt-2">
              <img src={playerData?.user_profile_pic} class="rounded" alt="..." />
            </div>
          } */}

          {/* new */}
          {/* <div className="col-12 d-flex align-items-center">
            <div className="col-2">
              <div className="" style={{ textAlign: 'left' }}>
                <img src={playerData?.user_profile_pic} alt="..." height={40} width={40} />
              </div>
            </div>
            <div className="col-10">
              <div class="player-name">
                <h2 className="m-0">{loading ? <Skeleton /> : playerData?.user_first_name + " " + playerData?.user_last_name}</h2>
              </div>
            </div>
          </div>

          <div className="col-12 d-flex align-items-center">
            <div className="col-6 last-played">
              <p className="last-paragraph">
                Last Played on Stream - <span>{loading ? <Skeleton /> : playerData?.last_played ? playerData?.last_played : "-"}</span>
              </p>
            </div>
            <div class="col-6">
              <div className="year-dropdown-selection">
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={isOptionsOpen ? "expanded" : ""}
                  onClick={toggleOptions}
                  onKeyDown={handleListKeyDown}
                >
                  {optionsList[selectedOption]}
                </button>
                <ul
                  className={`options ${isOptionsOpen ? "show" : ""}`}
                  role="listbox"
                  aria-activedescendant={optionsList[selectedOption]}
                  tabIndex={-1}
                  onKeyDown={handleListKeyDown}
                >
                  {optionsList.map((option, index) => (
                    <li
                      id={option}
                      role="option"
                      aria-selected={selectedOption == index}
                      tabIndex={0}
                      onKeyDown={handleKeyDown(index)}
                      onClick={(e) => {
                        setSelectedThenCloseDropdown(index);
                        setSelectedYear(e.target.innerHTML);
                      }}
                      className="text-center"
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
          {/* new end */}

          <div className="row third-component-row">
            <div className=" col-12  mt-0">
              <div className={isFixedMobile ? "row third-component-row-detail-fixed" : "row third-component-row-detail"}>
                <div className="col-12 d-flex align-items-center">
                  {playerData?.user_profile_pic !== "" &&
                    <div className="col-2">
                      <div className="" style={{ textAlign: "left" }}>
                        {loading ? (
                          <Skeleton circle height={"40px"} width={"40px"} />
                        ) : (
                          <img
                            src={
                              playerData?.user_profile_pic === ""
                                ? defaultIcon
                                : playerData?.user_profile_pic
                            }
                            alt="..."
                            height={40}
                            width={40}
                            style={{ borderRadius: "40px" }}
                          />
                        )}
                      </div>
                    </div>}
                  <div className="col-10">
                    <div class="player-name">
                      <h2 className="m-0">
                        {loading ? (
                          <Skeleton
                            width={100}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : (
                          playerData?.user_first_name && playerData?.user_last_name ?
                            playerData?.user_first_name + " " + playerData?.user_last_name :
                            playerData?.user_first_name ? playerData?.user_first_name : "-" ?
                              playerData?.user_last_name ? playerData?.user_last_name : "-" : "-"
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 d-flex align-items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="last-played mt-2">
                    <p className="last-paragraph">
                      Last Played on Stream -{" "}
                      <span>
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : playerData?.last_played ? (
                          playerData?.last_played
                        ) : (
                          "-"
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="mt-2">
                    <div className="year-dropdown-selection">
                      <button
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded={isOptionsOpen}
                        className={isOptionsOpen ? "expanded" : ""}
                        onClick={toggleOptions}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList[selectedOption]}
                      </button>
                      <ul
                        className={`options ${isOptionsOpen ? "show" : ""}`}
                        role="listbox"
                        aria-activedescendant={optionsList[selectedOption]}
                        tabIndex={-1}
                        onKeyDown={handleListKeyDown}
                      >
                        {optionsList.map((option, index) => (
                          <li
                            id={option}
                            role="option"
                            aria-selected={selectedOption == index}
                            tabIndex={0}
                            onKeyDown={handleKeyDown(index)}
                            onClick={(e) => {
                              setSelectedThenCloseDropdown(index, option);
                              setSelectedYear(e.target.innerHTML);
                            }}
                            className="text-center"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="hr-tag" />
              <div class={isFixedMobile ? "row third-component-row-inner-detail-fixed" : "row third-component-row-inner-detail"}>
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Ranking #</h3>
                    <h1 style={{ textTransform: "capitalize" }}>
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerRank ? (
                        playerRank
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Profit/Loss</h3>
                    <h1
                      class="mb-0 text-start"
                      style={{
                        color:
                          playerData?.profit_loss > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.profit_loss ? (
                        formatCurrency(playerData?.profit_loss)
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  <div className="head">
                    <h3 class="mb-1 text-start ps-3">Win Rate %</h3>
                    <h1 class="mb-0 text-start ps-3">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.win_rate ? (
                        Math.round(playerData?.win_rate) + "%"
                      ) : (
                        "0"
                      )}
                    </h1>
                  </div>
                </div>
              </div>
              <hr className="hr-tag" />
              <div class="row">
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Session</h3>
                    <h1 class="mb-0 text-start">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.sessions ? (
                        playerData?.sessions
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Session Avg.</h3>
                    <h1
                      class="mb-0 text-start"
                      style={{
                        color:
                          playerData?.avg_session > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_session ? (
                        formatCurrency(Math.round(playerData?.avg_session))
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  <div className="head">
                    <h3 class="mb-1 text-start ps-3">A. VPIP%</h3>
                    <h1 class="mb-0 text-start ps-3">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_vpip ? (
                        Math.round(playerData?.avg_vpip) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
              </div>
              <hr className="hr-tag" />
              <div class="row">
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">A. Pre Flop %</h3>
                    <h1 class="mb-0 text-start">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_pre_flop ? (
                        Math.round(playerData?.avg_pre_flop) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                {/* <div class="col-4">
                {" "}
                <div className="head">
                  <h3 class="mb-1 text-start">Last 10 Games</h3>
                  <h1 class="mb-0 text-start">{formatCurrency(playerData?.avg_last10_win_loss)}</h1>
                </div>
              </div> */}
                <div class="col-4">
                  <div className="head">
                    <h3 class="mb-1 text-start">Current Streak</h3>
                    <h1
                      class="mb-0 text-start"
                      style={{
                        color:
                          playerData?.positive_streak === true
                            ? "#21b976"
                            : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.positive_streak === true ? (
                        "+" + playerData?.current_streak
                      ) : playerData?.positive_streak === false ? (
                        "-" + playerData?.current_streak
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
              </div>
              {/* <hr className="hr-tag" />
              <div class="row">

                <div class="col-4">
                  {" "}
                  <div className="head"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className={isFixedMobile ? "main-third-content-below-19-fixed" : "main-third-content-below-19"}>
          <div className="row third-component-row-below-19">
            <div className="col-12 mt-0">
              <div className={isFixedMobile ? "row main-third-content-below-19-detail-fixed" : "row main-third-content-below-19-detail"}>
                <div class="col-8 players-name-col">
                  <div class="player-name">
                    <h2 className="m-0">
                      {loading ? (
                        <Skeleton
                          width={100}
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : (
                        playerData?.user_first_name && playerData?.user_last_name ?
                          playerData?.user_first_name + " " + playerData?.user_last_name :
                          playerData?.user_first_name ? playerData?.user_first_name : "-" ?
                            playerData?.user_last_name ? playerData?.user_last_name : "-" : "-"
                      )}
                    </h2>
                  </div>

                  <div className="last-played mt-2">
                    <p className="last-paragraph">
                      Last Played on Stream -{" "}
                      <span>
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : playerData?.last_played ? (
                          playerData?.last_played
                        ) : (
                          "-"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-4">
                  <div className="year-dropdown-selection">
                    <button
                      type="button"
                      aria-haspopup="listbox"
                      aria-expanded={isOptionsOpen}
                      className={isOptionsOpen ? "expanded" : ""}
                      onClick={toggleOptions}
                      onKeyDown={handleListKeyDown}
                    >
                      {optionsList[selectedOption]}
                    </button>
                    <ul
                      className={`options ${isOptionsOpen ? "show" : ""}`}
                      role="listbox"
                      aria-activedescendant={optionsList[selectedOption]}
                      tabIndex={-1}
                      onKeyDown={handleListKeyDown}
                    >
                      {optionsList.map((option, index) => (
                        <li
                          id={option}
                          role="option"
                          aria-selected={selectedOption == index}
                          tabIndex={0}
                          onKeyDown={handleKeyDown(index)}
                          onClick={(e) => {
                            setSelectedThenCloseDropdown(index, option);
                            setSelectedYear(e.target.innerHTML);
                          }}
                          className="text-center"
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div class="col-4 "></div>
              </div>
              <hr className="hr-tag" />
              <div className={isFixedMobile ? "row main-third-content-below-19-inner-detail-fixed" : "row main-third-content-below-19-inner-detail"}>
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Profit/Loss</h3>
                    <h1
                      class="mb-0 text-start"
                      style={{
                        color:
                          playerData?.profit_loss > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.profit_loss ? (
                        formatCurrency(playerData?.profit_loss)
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>

                <div class="col-4">
                  <div className="head">
                    <h3 class="mb-1 text-start ps-3">Ranking</h3>
                    <h1
                      class="mb-0 text-start ps-3"
                      style={{ textTransform: "capitalize" }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerRank ? (
                        playerRank
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  <div className="head">
                    <h3 class="mb-1 text-start ps-3">Win Rate %</h3>
                    <h1 class="mb-0 text-start ps-3">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.win_rate ? (
                        Math.round(playerData?.win_rate) + "%"
                      ) : (
                        "0"
                      )}
                    </h1>
                  </div>
                </div>
              </div>
              <hr className="hr-tag" />
              <div className="row">
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Session Avg.</h3>
                    <h1
                      class="mb-0 text-start"
                      style={{
                        color:
                          playerData?.avg_session > 0 ? "#21b976" : "#fd0303",
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_session ? (
                        formatCurrency(Math.round(playerData?.avg_session))
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  <div className="head">
                    <h3 class="mb-1 text-start ps-3">A. VPIP%</h3>
                    <h1 class="mb-0 text-start ps-3">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_vpip ? (
                        Math.round(playerData?.avg_vpip) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start ps-3">A. Pre Flop %</h3>
                    <h1 class="mb-0 text-start ps-3">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.avg_pre_flop ? (
                        Math.round(playerData?.avg_pre_flop) + "%"
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
              </div>
              <hr className="hr-tag" />
              {/* <hr style={{ height: '1px', background: "#000" }} /> */}
              {/* <div style={{ height: '1px', background: "#000" }}></div> */}
              <div className="row">
                <div class="col-4">
                  {" "}
                  <div className="head">
                    <h3 class="mb-1 text-start">Session</h3>
                    <h1 class="mb-0 text-start">
                      {loading ? (
                        <Skeleton
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : playerData?.sessions ? (
                        playerData?.sessions
                      ) : (
                        "-"
                      )}
                    </h1>
                  </div>
                </div>
                <div class="col-4">
                  <div className="head"></div>
                </div>
                <div class="col-4">
                  {" "}
                  <div className="head"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {playerData?.sessions >= 19 &&
        <> */}
      <Container fluid>
        <Row className="total-earning-row">
          <Col lg="6" md="6" sm="12" xs="12" className="main-earning-card">
            <TotalEarningCard
              playerData={playerData}
              id={id}
              year={year}
              loading={loading}
              earningGraphData={earningGraphData}
              sessions={playerData?.sessions}
            />
          </Col>
          <Col lg="6" md="6" sm="12" xs="12" className="main-high-low-card">
            <HighsAndLowsCard
              playerData={playerData}
              loading={loading}
              sessions={playerData?.sessions}
              earningGraphData={earningGraphData}
            />
          </Col>
        </Row>
      </Container>

      <Col>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {bannerLoading ? (
            <Skeleton
              className="banner-img-small"
              baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
            />
          ) : (
            <a href={centerBannerLink} target="_blank" rel="noreferrer">
              <img src={centerBanner} className="banner-img-small" />
            </a>
          )}
        </div>
      </Col>

      <Container fluid>
        <Row className="total-earning-row">
          <Col lg="6" md="6" sm="12" xs="12" className="main-earning-card">
            <StakesBreakDownCard
              playerData={playerData}
              loading={loading}
              sessions={playerData?.sessions}
            />
          </Col>
          <Col lg="6" md="6" sm="12" xs="12" className="main-high-low-card">
            <DiggingDetailsCard
              playerData={playerData}
              loading={loading}
              sessions={playerData?.sessions}
              selectedYear={selectedYear}
            />
          </Col>
        </Row>
      </Container>
      {/* </>
      } */}

      {/* Hustler Casino Live Results */}
      <Container fluid>
        <Row>
          {/* <HustlerCasinoLiveResults id={id} year={year} selectedYear={location?.state?.selectedYear} loading={loading} /> */}
          <div className="my-2 hustler-card">
            <div className="hustler-card-header">
              <h2 className="hustler-casino-live-details-text">{`${selectedYear === "all" ? "All Time" : selectedYear} Hustler Casino Live Results`}</h2>
              <div className="year-dropdown">
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen1}
                  className={isOptionsOpen1 ? "expanded" : ""}
                  onClick={toggleOptions1}
                  onKeyDown={handleListKeyDown}
                >
                  {optionsList[selectedOption]}
                </button>
                <ul
                  className={`options ${isOptionsOpen1 ? "show" : ""}`}
                  role="listbox"
                  aria-activedescendant={optionsList[selectedOption]}
                  tabIndex={-1}
                  onKeyDown={handleListKeyDown}
                >
                  {optionsList.map((option, index) => (
                    <li
                      id={option}
                      role="option"
                      aria-selected={selectedOption == index}
                      tabIndex={0}
                      onKeyDown={handleKeyDown(index)}
                      onClick={(e) => {
                        setSelectedThenCloseDropdown(index, option);
                        setSelectedYear(e.target.innerHTML);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="hustler-card-body">
              <table className="table hustler-table">
                <thead className="hustler-table-head">
                  <tr>
                    <th className="text-start hustler-th-1">Date</th>
                    <th
                      className="text-start hustler-th-2"
                      onClick={() => {
                        handleRequestSort("episode_number");
                      }}
                    >
                      Episodes
                      {orderBy === "episode_number" ? (
                        <img
                          src={
                            order === "desc" && orderBy === "episode_number"
                              ? userTheme === "light"
                                ? downArrow
                                : downArrowdark
                              : userTheme === "dark"
                                ? upArrodark
                                : upArrow
                          }
                          alt="sort-arrow"
                          className="hustler-sort-arrow"
                        />
                      ) : (
                        <img
                          src={
                            userTheme === "light"
                              ? sortingIconDark
                              : sortingIconLight
                          }
                          alt="sort-arrow"
                          className="leaderboard-sort-arrow"
                        />
                      )}
                    </th>
                    <th
                      className="text-end hustler-th-3"
                      onClick={() => {
                        handleRequestSort("result");
                      }}
                    >
                      Results
                      {orderBy === "result" ? (
                        <img
                          src={
                            order === "desc" && orderBy === "result"
                              ? userTheme === "light"
                                ? downArrow
                                : downArrowdark
                              : userTheme === "dark"
                                ? upArrodark
                                : upArrow
                          }
                          alt="sort-arrow"
                          className="hustler-sort-arrow"
                        />
                      ) : (
                        <img
                          // style={{ height: "11px", width: "9px" }}
                          src={
                            userTheme === "light"
                              ? sortingIconDark
                              : sortingIconLight
                          }
                          alt="sort-arrow"
                          className="leaderboard-sort-arrow"
                        />
                      )}
                    </th>
                    <th
                      className="text-end hustler-th-4"
                      onClick={() => {
                        handleRequestSort("vpip");
                      }}
                    >
                      Vpip
                      {orderBy === "vpip" ? (
                        <img
                          src={
                            order === "desc" && orderBy === "vpip"
                              ? userTheme === "light"
                                ? downArrow
                                : downArrowdark
                              : userTheme === "dark"
                                ? upArrodark
                                : upArrow
                          }
                          alt="sort-arrow"
                          className="hustler-sort-arrow"
                        />
                      ) : (
                        <img
                          // style={{ height: "11px", width: "9px" }}
                          src={
                            userTheme === "light"
                              ? sortingIconDark
                              : sortingIconLight
                          }
                          alt="sort-arrow"
                          className="leaderboard-sort-arrow"
                        />
                      )}
                    </th>
                    <th
                      className="text-end hustler-th-5"
                      onClick={() => {
                        handleRequestSort("pfr");
                      }}
                    >
                      Pre-flop
                      {orderBy === "pfr" ? (
                        <img
                          src={
                            order === "desc" && orderBy === "pfr"
                              ? userTheme === "light"
                                ? downArrow
                                : downArrowdark
                              : userTheme === "dark"
                                ? upArrodark
                                : upArrow
                          }
                          alt="sort-arrow"
                          className="hustler-sort-arrow"
                        />
                      ) : (
                        <img
                          // style={{ height: "11px", width: "9px" }}
                          src={
                            userTheme === "light"
                              ? sortingIconDark
                              : sortingIconLight
                          }
                          alt="sort-arrow"
                          className="leaderboard-sort-arrow"
                        />
                      )}
                    </th>
                    <th
                      className="text-end hustler-th-6"
                      onClick={() => {
                        handleRequestSort("game_stakes");
                      }}
                    >
                      Stakes
                      {orderBy === "game_stakes" ? (
                        <img
                          src={
                            order === "desc" && orderBy === "game_stakes"
                              ? userTheme === "light"
                                ? downArrow
                                : downArrowdark
                              : userTheme === "dark"
                                ? upArrodark
                                : upArrow
                          }
                          alt="sort-arrow"
                          className="hustler-sort-arrow"
                        />
                      ) : (
                        <img
                          // style={{ height: "11px", width: "9px" }}
                          src={
                            userTheme === "light"
                              ? sortingIconDark
                              : sortingIconLight
                          }
                          alt="sort-arrow"
                          className="leaderboard-sort-arrow"
                        />
                      )}
                    </th>
                    <th className="text-end hustler-th-6">Game Type</th>
                    <th className="hustler-table-last-extra"></th>
                  </tr>
                </thead>

                <tbody className="hustler-table-body">
                  {sortedData.map((tdata, i) => (
                    <tr
                      className="leaderboard-tr"
                      key={i}
                      onClick={() =>
                        navigate(`/episode-details/${tdata?._id}`, {
                          state: { selectedYear: year },
                        })
                      }
                    >
                      <td className="text-start hustler-table-data-index">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : (
                          tdata?.episode_date
                        )}
                      </td>
                      <td className="text-start hustler-table-data-name">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : (
                          "Episode" + " " + tdata?.episode_number
                        )}
                      </td>
                      <td
                        className="text-end hustler-table-data-results"
                        style={{
                          width: "8%",
                          color: tdata?.result > 0 ? "#21b976" : "#fd0303",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : tdata?.result === null ? (
                          "$0"
                        ) : (
                          formatCurrency(tdata?.result)
                        )}
                      </td>
                      <td className="text-lg-end text-md-center">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : (
                          Math.round(tdata?.vpip) + "%"
                        )}
                      </td>
                      <td className="text-end">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : tdata?.pfr === "-" ? (
                          "0"
                        ) : (
                          Number(Math.round(tdata?.pfr)) + "%"
                        )}
                      </td>
                      <td className="text-end">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : tdata?.game_stakes ? (
                          tdata?.game_stakes
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-end">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : tdata?.game_type ? (
                          tdata?.game_type
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="hustler-table-last-extra"></td>
                    </tr>
                  ))}
                  {!loading && sortedData.length === 0 && (
                    <tr>
                      <td colSpan="8">
                        <div className="text-center">No data found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* <div className="hustler-pagination">
            <Pagination className="pagination-main">

              <PaginationItem
                // onClick={
                //   data.hasPrevPage === true
                //     ? () => onPageClick(data.prevPage)
                //     : null
                // }
                className="custom-page"
              >
                <PaginationLink
                  previous
                  // disabled={data.hasPrevPage === true ? false : true}
                  tag="button"
                >
                  <i
                    className="fa fa-angle-left fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>

              {[...Array(totalPages)].map((page, i) => (
                <PaginationItem active={i + 1 === Page} key={i}>
                  <PaginationLink onClick={e => paginationHandle(e, i)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem
                // onClick={
                //   data.hasNextPage === true
                //     ? () => onPageClick(data.nextPage)
                //     : null
                // }
                className="custom-page"
              >
                <PaginationLink
                  next
                  tag="button"
                // disabled={data.hasNextPage === true ? false : true}
                >
                  <i
                    className="fa fa-angle-right fa-lg"
                    style={{ color: "#6D727E", fontSize: "20px" }}
                  ></i>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div > */}

              {sortedData.length !== 0 && (
                <div className="hustler-pagination">
                  <nav>
                    <Pagination className="pagination-main">
                      <PaginationItem
                        onClick={() => HandlePagePrev()}
                        className="custom-page"
                      >
                        <PaginationLink previous tag="button">
                          <i
                            className="fa fa-angle-left fa-lg"
                            style={{ color: "#6D727E", fontSize: "20px" }}
                          ></i>
                        </PaginationLink>
                      </PaginationItem>
                      {paginationItems}
                      <PaginationItem
                        onClick={() => HandlePageNext()}
                        className="custom-page"
                      >
                        <PaginationLink next tag="button">
                          <i
                            className="fa fa-angle-right fa-lg"
                            style={{ color: "#6D727E", fontSize: "20px" }}
                          ></i>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>

      <Container fluid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {bannerLoading ? (
            <Skeleton
              baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
              height={"90px"}
              className="banner-img-episode-1"
            />
          ) : (
            windowSize > 426 ?
              <a href={topBannerLink} target="_blank" rel="noreferrer">
                <img src={topBanner} className="banner-img-episode-1" />
              </a>
              :
              windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                <img src={bottomBanner} className="banner-img-episode-1" />
              </a>
            // <a href={bottomBannerLink} target="_blank" rel="noreferrer">
            //   <img src={bottomBanner} className="banner-img-episode-1" />
            // </a>
          )}
        </div>
      </Container>

      <Footer />

      <button
        class="btn btn-sm  rounded-circle position-fixed bottom-0 end-0 translate-middle d-none scoll-button"
        onClick={() => scrollToTop()}
        id="back-to-up"
        style={{
          background: "linear-gradient(184deg, #FA00FF 0%, #9105FF 100%)",
          color: "#ffffff",
          zIndex: 13,
        }}
      >
        <img src={uparrowfooter} alt="" width="16px" />
      </button>
    </>
  );
};

// export default PlayerProfile;

export default connect(null, {
  GetUserDataByIDYear,
  GetUserRankingNumber,
  GetUserEarningGraphData,
  GetUserEpisodesData,
  GetAdvertiseBannerList,
})(PlayerProfile);
