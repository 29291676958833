import React, { useEffect } from "react";
import Header from "../../DefaultLayout/Header";
import { Container, Row, Button } from "reactstrap";
import "../DonationPage/donation.css";
import Footer from "../../DefaultLayout/Footer";
import WhiteArrow from "../../assets/leftArrow.svg";
import DarkArrow from "../../assets/rightArrow.svg";
import { Link } from "react-router-dom";

const Advertise = (props) => {

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header seTheme={props.seTheme} />
      <div className="donation-main-div">
        <Container fluid className="text-center advertise-container">
          <Row className="d-flex justify-content-start pb-4">
            <Link to="/" className="d-flex justify-content-start">
              <img
                src={StoredTheme === "light" ? DarkArrow : WhiteArrow}
                alt=""
                width="50px"
                height="40px"
                style={{ width: "50px", cursor: "pointer" }}
              />
            </Link>
          </Row>
          <Row className="justify-content-md-start justify-content-sm-center donation-header-row">
            <h1 className="donation-header  text-start">
              Advertise / Sponsored Links
            </h1>
          </Row>
          <Row className=" donation-content-row">
            <div className="donation-content-div">
              <h3 className="donation-content text-start">
                If you’re interested in advertising or placing sponsorships on
                this site, please email and we can get started!
              </h3>

              <h3 className="donation-content  text-start pt-2">
                Place your banners, sponsored links or any other collaborations.
              </h3>
            </div>
          </Row>

          <Row className="donation-at-row justify-content-center">
            <h3 className="donation-content  text-start">
              Contact : <a href="mailto:trackingpokerdotcom@gmail.com">Trackingpokerdotcom@gmail.com</a>&nbsp;
            </h3>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Advertise;
