import axios from "axios";

const PROXY = process.env.REACT_APP_URL;

// const config = {
//     headers: {
//         "Content-Type": "application/json",
//     },
// };


// Get All Top Winners
export const GetAllTopWinners = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/topwinners/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetAllRegulars = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/profit/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetDataByGameType = (year, gameType, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/game/${year}/${gameType}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetMoneyChangesHandsData = (year) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/moneychange/${year}`);
    return res?.data;
};

export const GetLadiesOnlyData = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/ladies/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetCurrentWinningStreakData = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/topstreak/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetOneDayCashoutAndLostData = (year, type, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/onedaycash/${year}/${type}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetTopDonorsData = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/topdonors/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetCommentaryData = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}profitCalculate/commentary/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetChipsFlyingData = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}chipsFlying/${year}`);
    return res?.data?.result;
};

export const GetPlayerOfMonth = () => async (dispatch) => {
    const res = await axios.get(`${PROXY}playerOfMonth`);
    return res?.data?.result;
};
