import { SEARCH_RESILTS } from "./actions";

const initialState = {
  searchList: [],
  loading: true,
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_RESILTS:
      return {
        ...state,
        searchList: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default searchReducer;
