import axios from "axios";

const PROXY = process.env.REACT_APP_URL;

// const config = {
//     headers: {
//         "Content-Type": "application/json",
//     },
// };


// Get All Top Winners
export const GetEpisodeHeaderDetail = (id, year) => async (dispatch) => {
    const res = await axios.get(`${PROXY}episodeHeader`);
    return res?.data?.result;
};

export const GetEpisodeDataList = (year, page, limit) => async (dispatch) => {
    const res = await axios.get(`${PROXY}episodelist/${year}?page=${page}&limit=${limit}`);
    return res?.data?.result;
};

export const GetEpisodeDetail = (id) => async (dispatch) => {
    const res = await axios.get(`${PROXY}episodedetails/${id}`);
    return res?.data?.result;
};