import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import iIcon from "../../assets/2202278_detailed_i_info_information_more_icon.svg";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { max } from "moment";

const TotalEarningCard = (props) => {
  const { id, year } = useParams();
  const [earningGraphData, setEarningGraphData] = useState([]);
  const [stakesData, setStakesData] = useState([]);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };
  function roundUp(number, near) {
    if (number % near === 0) return number;
    return parseInt(number / near) * near + near;
  }
  let ind = [];
  let winloss = [];
  let tooltipArray = [];
  let tooltip = {};

  for (let index = 0; index < props?.earningGraphData.length; index++) {
    const element = props?.earningGraphData[index];

    ind.push(index);
    winloss.push(element?.win_loss);

    const data = {
      date: element?.formattedDate,
      gameType: element?.game_type,
      gameStackes: element?.game_stakes,
      highAmount: element?.win_loss,
    };
    tooltipArray.push(data);
    for (let index = 0; index < tooltipArray.length; index++) {
      tooltip = tooltipArray[index];
    }
  }
  // let positiveArray = originalArray.filter(value => value >= 0 && value !== null);
  const filteredData = tooltipArray.filter((item) => item.highAmount !== null);

  const arr = winloss;
  const maxValue = Math.max(...winloss);
  const minValue = Math.min(...winloss);
  // let minValue = -maxValue;

  const calMinValue = -minValue;
  let displayMaxValue;
  let startStop;

  if (maxValue > calMinValue) {
    displayMaxValue = maxValue;

    const maxPer = (calMinValue * 100) / maxValue;
    const belowPer = (maxPer * 50) / 100;
    startStop = 100 - belowPer;
  } else {
    displayMaxValue = calMinValue;
    const minPer = (maxValue * 100) / calMinValue;
    const maxPer = 100 - minPer;
    const belowPer = (maxPer * 50) / 100;
    startStop = 100 - (50 + belowPer);
  }

  //displayMaxValue = displayMaxValue + (displayMaxValue * 0.10);

  let FinalDisplayMaxValue;
  if (displayMaxValue > 1000000000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 1000000000);
  } else if (displayMaxValue > 100000000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 100000000);
  } else if (displayMaxValue > 10000000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 10000000);
  } else if (displayMaxValue > 1000000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 1000000);
  } else if (displayMaxValue > 100000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 100000);
  } else if (displayMaxValue > 10000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 10000);
  } else if (displayMaxValue > 1000) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 1000);
  } else if (displayMaxValue > 100) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 100);
  } else if (displayMaxValue > 10) {
    FinalDisplayMaxValue = roundUp(displayMaxValue, 10);
  }

  const option = {
    chart: {
      height: 450,

      id: "basic-bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = filteredData[dataPointIndex];
        return (
          '<div class="arrow_box_1">' +
          '<span style="font-size: 12px; font-weight: 600;">' +
          formatCurrency(dataPoint?.highAmount) +
          "</span>" +
          "</div>"
        );
      },
    },
    xaxis: {
      categories: ind,
      tickAmount: 6,
      show: true,
      // min: 1,
      // max: 10,
      labels: {
        show: true,
        rotate: 0,
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      show: true,
      // categories: winloss,
      opposite: true,
      labels: {
        show: true,
        formatter: function (value) {
          var val = Math.abs(value)
          if (value > 1000 && value <= 10000) {
            value = '$' + (value / 1000).toFixed(0) + 'K'
          }
          else if (value > 10000 && value <= 100000) {
            value = '$' + (value / 1000).toFixed(0) + 'K'
          }
          else if (value >= 100000) {
            value = '$' + (value / 1000).toFixed(0) + 'K'
          }
          else if (value < 0 && value < -100000 && value !== 0) (
            value = '-$' + (val / 1000).toFixed(0) + 'K'
          )
          else if (value >= -1000000 && value !== 0) (
            value = '-$' + (val / 1000).toFixed(0) + 'K'
          )
          else if (val === 0) {
            value = 0
          }

          // if (value >= 10000 && value <= 1000000) {
          //   value = '$' + (value / 1000).toFixed(0) + 'K'
          // }
          // else if (value >= 1000000) {
          //   value = '$' + (value / 10000).toFixed(0) + 'K'
          // }
          // else if (value < -1 && value < -1000000 && value !== 0) (
          //   value = '-$' + (val / 10000).toFixed(0) + 'K'
          // )
          // else if (value >= -1000000 && value !== 0) (
          //   value = '-$' + (val / 1000).toFixed(0) + 'K'
          // )
          // else if (val === 0) {
          //   value = 0
          // }
          return value
        }
      },
      min: -FinalDisplayMaxValue,
      max: FinalDisplayMaxValue,
    },
    grid: {
      show: false, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: true, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: true, //or just here to disable only y axis
        },
      },
    },
    colors: ["#FD0303"],
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        gradientToColors: ["#0dcaf0"],
        stops: [startStop, 0],
      },
    },
    series: [
      {
        name: "",
        data: winloss,
      },
    ],
    responsive: [
      {
        breakpoint: 1025,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 769,
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
  };

  const lastItem = winloss[winloss?.length - 1];

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div className="my-2 earning-card">
      <div className="earning-card-header">
        <h2>Earning Graph</h2>
      </div>
      {
        props.loading ?
          // <Skeleton height={300} style={{ margin: "2% 1%", width: "90%" }}
          //   baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"} />
          <div className="earning-card-content">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center earning-graph-value-text">
                <h5>
                  <Skeleton
                    width={190}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </h5>
              </div>
            </div>
            <div>
              <h2
                className="text-start earning-graph-value"
                style={{ color: lastItem > 0 ? "#21b976" : "#fd0303" }}
              >
                <Skeleton
                  width={190}
                  baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                />
              </h2>
              {/* <h5 className="text-start value-increase-text ">
            ${props?.playerData?.poitive_win_loss_sum}
          </h5> */}
            </div>
            <Skeleton height={300}
              baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"} />
          </div>
          :
          // props.sessions >= 20 ? (
          <div className="earning-card-content">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center earning-graph-value-text">
                <h5>Total {props?.year} chart </h5>
                {/* <span className="ps-2 pe-2 pipe-line">|</span>{" "} */}
                {/* <h5 className="performance-text">Performance</h5> */}
              </div>
              {/* <div>
            <img
              src={iIcon}
              alt=""
              width="20px"
              style={{ cursor: "pointer" }}
            />
          </div> */}
            </div>
            <div>
              <h2
                className="text-start earning-graph-value"
                style={{ color: lastItem > 0 ? "#21b976" : "#fd0303" }}
              >
                {props?.loading ? (
                  <Skeleton
                    width={190}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : lastItem ? (
                  formatCurrency(lastItem)
                ) : (
                  "-"
                )}
              </h2>
              {/* <h5 className="text-start value-increase-text ">
            ${props?.playerData?.poitive_win_loss_sum}
          </h5> */}
            </div>
            {props.loading ? (
              <Skeleton
                height={300}
                baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
              />
            ) : (
              <Chart
                options={option}
                series={option.series}
                type="line"
                height={300}
                // width="550"
                // className=""
                id="apex-responsive-chart"
              />
            )}
          </div>
        // ) : (
        //   <div
        //     className="earning-card-content"
        //     style={{
        //       height: "85%",
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //     }}
        //   >
        //     <span className="no-available-data">No Available Data</span>
        //   </div>
        // )
      }
    </div>
  );
};

// export default TotalEarningCard;
export default connect(null, {})(TotalEarningCard);
