import React, { useEffect, useState } from "react";

import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import "./leaderBoard.css";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { GetMoneyChangesHandsData } from "../../store/slices/LeaderBoardTabsAction";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

const LeaderBoardStatsMoneyChangedHands = (props) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("result");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);
  const [totalOfDays, setTotalOfDays] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props?.playerKey === "mch") {
      setLoading(true);
      props
        .GetMoneyChangesHandsData(
          props.year === "All Time" ? "all" : props.year
        )
        .then((res) => {
          setTableData(res?.result);
          setTotalOfDays(res?.total);
          setLoading(false);
        });
    }
  }, [props.year]);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const paginationSection = () => {
    const data = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 9,
      nextPage: null,
      page: 1,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 5,
      totalPages: 4,
    };
    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= data.totalPages; i++) {
      if (
        i === 1 ||
        (data.page < 4 && i <= 5) ||
        i === data.page - 1 ||
        i === data.page + 1 ||
        i === data.page ||
        i === data.totalPages ||
        (data.page >= data.totalPages - 3 && i >= data.totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={data.page === i ? true : false}
              onClick={data.page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }
    return (
      <nav>
        <Pagination className="pagination-main">
          <PaginationItem
            onClick={
              data.hasPrevPage === true
                ? () => onPageClick(data.prevPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              previous
              disabled={data.hasPrevPage === true ? false : true}
              tag="button"
            >
              <i
                className="fa fa-angle-left fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
          {Pages}
          <PaginationItem
            onClick={
              data.hasNextPage === true
                ? () => onPageClick(data.nextPage)
                : null
            }
            className="custom-page"
          >
            <PaginationLink
              next
              tag="button"
              disabled={data.hasNextPage === true ? false : true}
            >
              <i
                className="fa fa-angle-right fa-lg"
                style={{ color: "#6D727E", fontSize: "20px" }}
              ></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    );
  };

  const onPageClick = (page) => {
    var pageNum = page;
  };

  // table filter
  const handleSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    setTableData(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    handleSort(tableData, getComparator(isAsc ? "desc" : "asc", property));
  };

  let totalDollar = 0;

  // Loop through the array and sum up the dollar values
  for (const item of tableData) {
    if (item?.dollar !== null) {
      totalDollar += item?.dollar;
    }
  }

  let maxDollar = -Infinity;
  let dayOfWeekWithMaxDollar = null;

  // Loop through the array to find the day with max dollar
  for (const item of tableData) {
    if (item.dollar !== null && item.dollar > maxDollar) {
      maxDollar = item.dollar;
      dayOfWeekWithMaxDollar = item.dayOfWeek;
    }
  }

  let minDollar = Infinity;
  let dayOfWeekWithMinDollar = null;

  // Loop through the array to find the day with the min dollar
  for (const item of tableData) {
    if (item.dollar !== null && item.dollar < minDollar) {
      minDollar = item.dollar;
      dayOfWeekWithMinDollar = item.dayOfWeek;
    }
  }

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div>
      <div className="money-changed-row">
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-12"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <div className="col-12 col-lg-4 col-md-3 col-sm-3 money-change-status">
            <h3>Money Changed Hands</h3>
            <h1>
              {loading ? (
                <Skeleton
                  width={100}
                  baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                />
              ) : totalOfDays ? (
                formatCurrency(totalOfDays)
              ) : (
                "-"
              )}
            </h1>
          </div>
          {/* <div className="col-6 col-lg-2 col-md-3 col-sm-3 money-change-status">
            <h3>Most Action</h3>
            <h1>
              {loading ? (
                <Skeleton
                  width={100}
                  baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                />
              ) : dayOfWeekWithMaxDollar ? (
                dayOfWeekWithMaxDollar
              ) : (
                "-"
              )}
            </h1>
          </div>
          <div className="col-12 col-lg-2 col-md-3 col-sm-3 money-change-status-1">
            <h3>Least Action</h3>
            <h1>
              {loading ? (
                <Skeleton
                  width={100}
                  baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                />
              ) : dayOfWeekWithMinDollar ? (
                dayOfWeekWithMinDollar
              ) : (
                "-"
              )}
            </h1>
          </div> */}
        </div>
      </div>

      <div style={{ overflowX: "auto" }}>
        <table className="table money-changed-leaderboard-table">
          <thead className="leaderboard-table-head">
            <tr>
              <th className="leaderboard-th-1">#</th>
              <th className="text-start leaderboard-th-2">Day of the Week</th>
              <th className="leaderboard-th-3 text-end">Results</th>
              <th className="table-last-extra"></th>
            </tr>
          </thead>

          <tbody className="leaderboard-table-body">
            {loading && (
              <tr>
                <td className="leaderboard-table-data-index">
                  <Skeleton
                    count={7}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="text-start leaderboard-table-data-name">
                  <Skeleton
                    count={7}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
                <td className="leaderboard-table-data-results">
                  <Skeleton
                    count={7}
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                </td>
              </tr>
            )}
            {tableData.map((tdata, i) => (
              <tr className="leaderboard-tr" key={i}>
                <td className="leaderboard-table-data-index">
                  {(i + 1).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </td>
                <td className="text-start leaderboard-table-data-name">
                  {tdata?._id ? tdata?._id : "-"}
                </td>
                <td
                  className="text-end"
                  style={{ color: tdata?.dollar > 0 ? "#21b976" : "#fd0303" }}
                >
                  {tdata?.dollar === null ? "0" : formatCurrency(tdata?.dollar)}
                </td>
                <td className="table-last-extra"></td>
              </tr>
            ))}
            {!loading && tableData.length === 0 && (
              <tr>
                <td colSpan="12">
                  <div className="text-center">No data found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(null, {
  GetMoneyChangesHandsData,
})(LeaderBoardStatsMoneyChangedHands);
