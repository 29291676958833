import axios from "axios";

const PROXY = process.env.REACT_APP_URL;

// const config = {
//     headers: {
//         "Content-Type": "application/json",
//     },
// };


export const GetPollList = (ipAddress, offset) => async (dispatch) => {
    const res = await axios.get(`${PROXY}poll/list-poll/${ipAddress}?offset=${offset}`);
    return res?.data?.result;
};

// "raw": "{\n    \"ip_address\":\"192.12.11\",\n    \"questionId\":\"65b8fdacefb0a2a5254c2838\",\n    \"anwserId\":\"65b8fdacefb0a2a5254c2840\"\n}",
export const AddVote = (data) => async (dispatch) => {
    const res = await axios.post(`${PROXY}poll/add-vote`, data);
    return res;
};

export const GetPollResultList = (id) => async (dispatch) => {
    const res = await axios.get(`${PROXY}poll/result/${id}`);
    return res?.data?.result;
};