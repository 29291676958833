import React, { useState, useEffect } from "react";
import Header from "../../DefaultLayout/Header";
import Footer from "../../DefaultLayout/Footer";
import EpisodeListStats from "./EpisodeListStats";
import "./episodeList.css";
import { Nav, NavItem, NavLink } from "reactstrap";
import darkMode from "../../assets/9254147_moon_crescent_night_mode_dark_icon (1).svg";
import lightMode from "../../assets/lightbulb.svg";
import upArrow from "../../assets/211624_c_up_arrow_icon.svg";

const EpisodeList = ({ seTheme }) => {
  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, setTheme] = useState(userTheme);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (window.innerWidth < 767) {
    window.onscroll = () => {
      toggleTopButton();
    };
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function toggleTopButton() {
    if (window.innerWidth < 767) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.remove("d-none");
      } else {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.add("d-none");
      }
    }
  }

  return (
    <>
      <Header seTheme={seTheme} />

      {/* Tab Navigation */}
      <div className="container-fluid main-tab-nav">
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row h-100">
              <div className="col-lg-8 col-md-10 col-sm-12 col-12 d-flex">
                <Nav className="main-nav">
                  <NavItem>
                    <NavLink href="/">Hustler Casino Live</NavLink>
                  </NavItem>
                  <NavItem className="main-nav-item">
                    <NavLink active href="/episode-list">
                      Episode List
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: 'not-allowed' }}>
                    <NavLink href="/player-profile" disabled>Player’s Profile</NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="col-lg-4 col-md-2 text-end dark-mode-btn">
                <img
                  src={theme === "light" ? darkMode : lightMode}
                  alt="dark-mode-btn"
                  onClick={changeThemeHandler}
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <EpisodeListStats />

      <Footer />
      {windowSize < 767 && (
        <button
          class="btn btn-sm  rounded-circle position-fixed bottom-0 end-0 translate-middle d-none scoll-button"
          onClick={() => scrollToTop()}
          id="back-to-up"
          style={{
            background: "linear-gradient(184deg, #FA00FF 0%, #9105FF 100%)",
            color: "#ffffff",
          }}
        >
          <img src={upArrow} alt="" width="16px" />
        </button>
      )}
    </>
  );
};

export default EpisodeList;
