import React, { Fragment } from "react";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastifyContainer = () => {
    const containerStyle = {
        zIndex: 1999,
        // width:"300px",
    };

    return (
        <Fragment>
            <ToastContainer
                position="top-right"
                transition={Flip}
                autoClose={4000}
                style={containerStyle}
            />
        </Fragment>
    );
};

export default ToastifyContainer;
