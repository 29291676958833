import "./leaderBoard.css";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import downArrow from "../../assets/down-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import LeaderBoardStatsTopWinners from "./TopWinners";
import LeaderBoardStatsRegular from "./LeaderBoardStatsRegular";
import LeaderBoardStatsTopDonors from "./TopDonors";
import LeaderBoardStatsMaxPain from "./MaxPain";
import LeaderBoardStatsAnteGames from "./AnteGame";
import LeaderBoardStatsSuperHighStakes from "./SuperHighStakes";
import LeaderBoardStatsThirstyThursday from "./ThirstyThursday";
import LeaderBoardStatsHighStakes from "./HighStakes";
import LeaderBoardStatsPotLimitOhama from "./PotLimitOhama";
import LeaderBoardStatsLadiesOnly from "./LadiesOnly";
import LeaderBoardStatsCurrentWinningStreak from "./CurrentWinningStreak";
import LeaderBoardStatsOneDayCashout from "./OneDayCashout";
import LeaderBoardStatsOneDayLost from "./OneDayLost";
import LeaderBoardStatsCommentary from "./Commentary";
import LeaderBoardStatsMoneyChangedHands from "./MoneyChangedHands";

const LeaderBoardStats = ({ setSelectedYear, setIsOptionsOpen, setSelectedOption, selectedYear, isOptionsOpen, selectedOption, userTheme }) => {
  // Tab Event Key State
  const [key, setKey] = useState("home");

  // Sorting States
  const [orderBy, setOrderBy] = useState("results");
  const [order, setOrder] = useState("asc");
  // const [selectedYear, setSelectedYear] = useState("2023");
  // const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(0);

  const getCurrentYear = () => new Date().getFullYear();
  const currentYear = getCurrentYear();
  const yearsList = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());
  yearsList.push("All Time");

  const optionsList = yearsList;
  // const optionsList = ["2023", "2022", "2021", "All Time"];

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="mt-2 leaderboard-card" id="main-leaderboard">
        <div className="leaderboard-card-header">
          <h2>{`${selectedYear} Leaderboard Stats`}</h2>
          <div className="year-dropdown">
            {/* <select
              name="year-select"
              id="year-select"
              className="year-select"
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value={"2023"}>2023</option>
              <option value={"2022"}>2022</option>
              <option value={"2021"}>2021</option>
              <option value={"All Time"}>All Time</option>
            </select> */}
            <button
              type="button"
              aria-haspopup="listbox"
              aria-expanded={isOptionsOpen}
              className={isOptionsOpen ? "expanded" : ""}
              onClick={toggleOptions}
              onKeyDown={handleListKeyDown}
            >
              {optionsList[selectedOption]}
            </button>
            <ul
              className={`options ${isOptionsOpen ? "show" : ""}`}
              role="listbox"
              aria-activedescendant={optionsList[selectedOption]}
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
            >
              {optionsList.map((option, index) => (
                <li
                  id={option}
                  role="option"
                  key={index}
                  aria-selected={selectedOption == index}
                  tabIndex={0}
                  onKeyDown={handleKeyDown(index)}
                  onClick={(e) => {
                    setSelectedThenCloseDropdown(index);
                    setSelectedYear(e.target.innerHTML);
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="leaderboard-card-body">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-3 leaderboard-tab"
          >
            <Tab eventKey="home" title="Top Winners">
              {key === "home" && <LeaderBoardStatsTopWinners year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="regulars" title="Regulars">
              {key === "regulars" && <LeaderBoardStatsRegular year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="topdonors" title="Top Donors">
              {key === "topdonors" && <LeaderBoardStatsTopDonors year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="maxpain" title="Max Pain">
              {key === "maxpain" && <LeaderBoardStatsMaxPain year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="antegame" title="Ante Game">
              {key === "antegame" && <LeaderBoardStatsAnteGames year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="highstakes" title="High Stakes">
              {key === "highstakes" && <LeaderBoardStatsHighStakes year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="thirstythursday" title="Thirsty Thursday">
              {key === "thirstythursday" && <LeaderBoardStatsThirstyThursday year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="superhighstakes" title="Super High Stakes">
              {key === "superhighstakes" && <LeaderBoardStatsSuperHighStakes year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="plo" title="Pot Limit Ohama">
              {key === "plo" && <LeaderBoardStatsPotLimitOhama year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="mch" title="Money Changed Hands">
              {key === "mch" && <LeaderBoardStatsMoneyChangedHands year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="ladiesonly" title="Ladies Only">
              {key === "ladiesonly" && <LeaderBoardStatsLadiesOnly year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="currentwinningstreak" title="Current Winning Streak">
              {key === "currentwinningstreak" && <LeaderBoardStatsCurrentWinningStreak year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="onedaycashouts" title="One Day Cashouts">
              {key === "onedaycashouts" && <LeaderBoardStatsOneDayCashout year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="onedaylost" title="One Day Lost">
              {key === "onedaylost" && <LeaderBoardStatsOneDayLost year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
            <Tab eventKey="commentary" title="Commentary">
              {key === "commentary" && <LeaderBoardStatsCommentary year={selectedYear} selectedOption={selectedOption} playerKey={key} userTheme={userTheme} />}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default LeaderBoardStats;
