import React, { useState } from "react";
import Chart from "react-apexcharts";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const CurrentStreamCard = ({ chipsFlyingData, loading, selectedYear }) => {
  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  let array = [];
  let value = [];
  let mchValue;

  for (let index = 0; index < chipsFlyingData?.length; index++) {
    const element = chipsFlyingData[index];
    mchValue = element?.moneyChangesYear;
    array.push(element?.month);
    value.push(element?.total);
  }

  let options = {
    series: [
      {
        name: "",
        data: value.slice(0, -1),
      },
    ],
    chart: {
      type: "bar",
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: '55%',
        borderRadius: 4,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: array.slice(0, -1),
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      // opacity: 1,
      colors: ["#00C2FF"],
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = value[dataPointIndex];
        return (
          '<div class="arrow_box">' +
          // '<div style="width: 93px; height: 29px; border: 2px solid #F4F6FD">' +
          '<span style="font-size: 12px; font-weight: 700">' +
          formatCurrency(dataPoint) +
          "</span>" +
          "</div>"
        );
      },
    },
    grid: {
      show: false, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    states: {
      hover: {
        filter: { type: "#D3E0EB" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    responsive: [
      {
        breakpoint: 1920,
        options: {
          chart: {
            width: 425,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 1441,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 1254,
        options: {
          chart: {
            width: 310,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 1025,
        options: {
          chart: {
            width: 310,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 913,
        options: {
          chart: {
            width: 840,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 821,
        options: {
          chart: {
            width: 730,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 769,
        options: {
          chart: {
            width: 710,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 540,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 541,
        options: {
          chart: {
            width: 500,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 426,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 410,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 394,
        options: {
          chart: {
            width: 350,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 391,
        options: {
          chart: {
            width: 350,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 376,
        options: {
          chart: {
            width: 340,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
      {
        breakpoint: 321,
        options: {
          chart: {
            width: 280,
          },
          legend: {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            // show: true,
            // horizontalAlign: 'left',
          },
        },
      },
    ],
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$ " + val + " thousands"
    //     }
    //   }
    // }
  };

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div className="my-2 current-stream">
      <div className="current-stream-header">
        <h2>🚀 Chips are Flying</h2>
      </div>

      <div className="current-stream-content">
        <div className="row">
          <div className="d-flex align-items-center moneychange-value-text">
            <h5 style={{ color: "#6D727E", fontSize: "14px" }}>
              Money Changed Hands in {selectedYear}
            </h5>
          </div>
          <div>
            <h2 className="text-start mch-value">
              {loading ? (
                <Skeleton
                  width={280}
                  baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                />
              ) : (
                formatCurrency(mchValue)
              )}
            </h2>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
            {loading ? (
              <Skeleton
                height={300}
                width={300}
                baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
              />
            ) : (
              <Chart
                className="barchart"
                type="bar"
                options={options}
                series={options.series}
                width={580}
                height="100%"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentStreamCard;
