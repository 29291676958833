import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import defaultPicture from "../../assets/profilePic.png";

const CurrentMonthData = ({ currentMonth, loading }) => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  // const winners = currentMonth?.avgOfVpipByPlayer;

  // const isPlayerNameBlank = (winners) => {
  //   for (let i = 0; i < winners.length; i++) {
  //     if (winners[i].user_profile_pic !== '') {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  // const allPlayerNamesBlank = isPlayerNameBlank(winners);
  // console.log(allPlayerNamesBlank, "allPlayerNamesBlank")

  // if (allPlayerNamesBlank) {
  //   console.log("All player names are blank strings.");
  // } else {
  //   console.log("Not all player names are blank strings.");
  // }

  return (
    <div
      className={
        currentMonth?.avgOfVpipByPlayer?.length === 0
          ? "my-2 plyear-of-the-month-no-data"
          : "my-2 plyear-of-the-month"
      }
    >
      {/* <div className='stakes-breakdown-header'>
                <h2>🔥 Players of the Month</h2>
            </div> */}
      <div className="win-lost-breakdown-card-header-1">
        <h2>🔥 Players of the Month</h2>
        <div className="current-month">
          <p className="m-0">
            <span> {currentMonth?.currentMonthName}</span>
          </p>
        </div>
      </div>

      {/* {sessions >= 19 ? */}
      <div className="plyear-of-the-month-content">
        {/* <div className="comparing-session">
          <h4>Comparing Sessions & Avg. VPIPs</h4>
        </div> */}
        <div className="current-month-content">
          <table className="table table-borderless">
            <thead className="player-of-the-month-table-head">
              <tr>
                <th className="text-start player-month-th-1">Players</th>
                {/* {windowSize <= 769 && windowSize >= 424 && <th className="player-month-th-2"></th>} */}
                <th className="text-start player-month-th-3">A.VPIP</th>
                <th className="text-end player-month-th-4">Wins</th>
              </tr>
            </thead>

            <tbody className="hcl-month-table">
              {currentMonth?.avgOfVpipByPlayer?.map((eachData, i) => (
                <tr key={i} className="hustler-episode-tr">
                  {/* {windowSize <= 480 ? ( */}
                  {/* {windowSize > 768 || windowSize <= 480 ? ( */}
                  <td
                    className="text-start current-month-content-playername"
                    onClick={() =>
                      // navigate(
                      //   `/player-profile/${eachData?._id}/${currentMonth?.currentYear}`
                      // )
                      navigate(`/playersprofile/${eachData?.slug}/HCL-poker-results/${currentMonth?.currentYear}`)
                    }
                  >
                    {eachData?.user_profile_pic && <img src={eachData?.user_profile_pic} alt="profile-img" />}
                    {/* <span className="player-of-the-month-player-name"> */}
                    {loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) :
                      eachData?.playerName ? (
                        eachData?.playerName + " " + `(${eachData?.episodeCount})`
                      ) : (
                        "Others" + " " + `(${eachData?.episodeCount})`
                      )}
                    {/* </span> */}
                  </td>
                  {/* ) : (
                    <td
                      className="text-start current-month-content-playername"
                      onClick={() =>
                        // navigate(
                        //   `/player-profile/${eachData?._id}/${currentMonth?.currentYear}`
                        // )
                        navigate(`/playersprofile/${eachData?.slug}/HCL-poker-results/${currentMonth?.currentYear}`)
                      }
                    >
                      {eachData?.user_profile_pic && <img src={eachData?.user_profile_pic} alt="profile-img" />}
                      <span className="player-of-the-month-player-name">
                        {loading ? (
                          <Skeleton
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : eachData?.playerName ? (
                          eachData?.playerName
                        ) : (
                          "-"
                        )}
                      </span>
                    </td>
                  )} */}
                  {/* {windowSize <= 769 && windowSize >= 424 && <td className="text-start current-month-content-episode-count">
                    {loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : eachData?.episodeCount ? (
                      Math.round(Number(eachData?.episodeCount)) +
                      " " +
                      "Sessions"
                    ) : (
                      "-"
                    )}
                  </td>} */}
                  <td className="text-start current-month-content-avpip">
                    {loading ? (
                      <Skeleton
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      <ProgressBar
                        variant={eachData?.AVpip > 0 ? "info" : "danger"}
                        label={`${Math.round(eachData?.AVpip)}%`}
                        now={eachData?.AVpip}
                      />
                    )}
                  </td>
                  <td
                    className="text-end current-month-content-totalwinloss"
                    style={{
                      color: eachData?.totalWinLoss > 0 ? "#21B976" : "#fd0303",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        width={20}
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : eachData?.totalWinLoss ? (
                      formatCurrency(eachData?.totalWinLoss)
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentMonth?.avgOfVpipByPlayer?.length === 0 && (
            <div className="no-data-current-month">No Available Data</div>
          )}
        </div>
        {/* <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12 stakes-data-col">
                        <div className='col-lg-5 col-md-5 col-4'>
                            <ul className='chart-ul'>
                            {loading ?
                                <Skeleton count={8} />
                                : currentMonth?.avgOfVpipByPlayer?.map((res) => {
                                    return (
                                        <div style={{ textAlign: 'left' }}>{res.playerName}</div>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className='col-lg-4 col-md-4 col-4'>
                            <table className='table table-borderless m-0'>
                                <tbody>
                                    {currentMonth?.stack_data?.map((eachData, i) => {
                                        const percentage = (eachData?.Sum_win_loss / 500000) * 100
                                        return (
                                            <tr>
                                                <td className={`col-lg-4 col-md-4 col-sm-4 col-4 stakes-value`}>
                                                    {loading === true ? <Skeleton width={150} /> : <ProgressBar variant={eachData?.count > 5 ? 'info' : 'danger'} now={eachData?.count} style={{ width: "100%" }} />}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-3 col-md-3 col-4'>
                            <table className='table table-borderless m-0'>
                                <tbody>
                                    {currentMonth?.stack_data?.map((eachData, i) => (
                                        <tr>
                                            <td className={`col-lg-4 col-md-4 col-sm-4 col-4 stakes-value`} style={{ color: eachData?.Sum_win_loss > 0 ? "#21b976" : "#fd0303" }}>
                                                {loading === true ? <Skeleton width={150} /> : formatCurrency(eachData?.Sum_win_loss)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
      </div>
      {/* : */}
      {/* <div className="stakes-breakdown-content" style={{ height: "85%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    No Available Data
                </div>
            } */}
    </div>
  );
};

export default CurrentMonthData;
