import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import Header from "../../DefaultLayout/Header";
import Footer from "../../DefaultLayout/Footer";
import sample_vid from "../../assets/sample-vid.mp4";
import darkMode from "../../assets/9254147_moon_crescent_night_mode_dark_icon (1).svg";
import lightMode from "../../assets/lightbulb.svg";
import upArrow from "../../assets/211624_c_up_arrow_icon.svg";
// import thumbnailTest from "../../assets/thumbnail-test.png";
import { GetEpisodeDetail } from "../../store/slices/EpisodesAction";
import moment from "moment";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import BannerImage from "../../assets/banner.png";
import { GetAdvertiseBannerList } from "../../store/slices/AdvertiseAction";
import useRecursiveTimeout from "../../Helper/Advertise";
import { Link } from "react-router-dom";

const EachEpisode = ({ GetEpisodeDetail, seTheme, GetAdvertiseBannerList }) => {
  const [episodeDetail, setEpisodeDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const loaction = useLocation();

  const [topBanner, setTopBanner] = useState("");
  const [centerBanner, setCenterBanner] = useState("");
  const [bottomBanner, setBottomBanner] = useState("");
  const [topBannerLink, setTopBannerLink] = useState("");
  const [centerBannerLink, setCenterBannerLink] = useState("");
  const [bottomBannerLink, setBottomBannerLink] = useState("");

  const [bannerLoading, setBannerLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setBannerLoading(true);
    GetEpisodeDetail(id)
      .then((res) => {
        res.map((response) => {
          setEpisodeDetail(response);
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    GetAdvertiseBannerList().then((res) => {
      setBannerLoading(false);
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, []);

  useRecursiveTimeout(async () => {
    GetAdvertiseBannerList().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].position === "top") {
          setTopBanner(res[i]?.image);
          setTopBannerLink(res[i]?.link);
        } else if (res[i].position === "center") {
          setCenterBanner(res[i]?.image);
          setCenterBannerLink(res[i]?.link);
        } else if (res[i].position === "bottom") {
          setBottomBanner(res[i]?.image);
          setBottomBannerLink(res[i]?.link);
        }
      }
    });
  }, 300000);

  const userTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  const [theme, setTheme] = useState(userTheme);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const changeThemeHandler = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    seTheme(newTheme);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const formatCurrency = (value) => {
    return value < 0
      ? `-$${Math.abs(value).toLocaleString()}`
      : `$${value?.toLocaleString()}`;
  };

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    // function handleWindowResize() {
    //   setWindowSize(window.innerWidth);
    // }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  if (window.innerWidth < 767) {
    window.onscroll = () => {
      toggleTopButton();
    };
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function toggleTopButton() {
    if (window.innerWidth < 767) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.remove("d-none");
      } else {
        document.getElementById("back-to-up") &&
          document.getElementById("back-to-up").classList.add("d-none");
      }
    }
  }

  let StoredTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  const formatDate = (date) => {

    var d = new Date(date).setHours(0, 0, 0, 0),
      d = new Date(d),

      // var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const videoId = episodeDetail?.link?.split('v=')[1];
  let embedUrl;
  if (videoId) {
    embedUrl = `https://www.youtube.com/embed/${videoId}`;
  } else {
    embedUrl = episodeDetail?.link
  }

  return (
    <>
      <Header id={2} seTheme={seTheme} />

      {/* Tab Navigation */}
      <div className="container-fluid main-tab-nav">
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row h-100">
              <div className="col-lg-8 col-md-10 col-sm-12 col-12 d-flex">
                <Nav className="main-nav">
                  <NavItem>
                    <NavLink href="/">Hustler Casino Live</NavLink>
                  </NavItem>
                  <NavItem className="main-nav-item">
                    <NavLink active href="/episode-list">
                      Episode List
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: "not-allowed" }}>
                    <NavLink href="/player-profile" disabled>
                      Player’s Profile
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="col-lg-4 col-md-2 text-end dark-mode-btn">
                <img
                  src={theme === "light" ? darkMode : lightMode}
                  alt="dark-mode-btn"
                  onClick={changeThemeHandler}
                  style={{ cursor: "pointer", width: "24px", height: "24px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {bannerLoading ? (
          <Skeleton
            className="banner-img-episode"
            baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
          />
        ) : (
          windowSize > 426 ?
            <a href={topBannerLink} target="_blank" rel="noreferrer">
              <img src={topBanner} className="banner-img-episode" />
            </a>
            :
            windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
              <img src={bottomBanner} className="banner-img-episode" />
            </a>
          // <a href={topBannerLink} target="_blank" rel="noreferrer">
          //   <img src={topBanner} className="banner-img-episode" />
          // </a>
        )}
      </div>

      {/* Episode Video */}
      <Container fluid className="main-episode-details-video-container">
        <Row className="episode-details-row">
          {/* <video controls className="episode-details-video">
            <source src={episodeDetail?.link} type="video/mp4" />
          </video> */}
          <iframe className="episode-details-video" src={embedUrl}>
          </iframe>
        </Row>
      </Container>

      {/* Episode Details */}
      <Container fluid className="main-episode-details-container">
        <Row className="episode-details-row-head">
          <Col lg={12} md={12} sm={12} xs={12} className="episode-details-col">
            <Col lg={8} md={8} sm={6} xs={6}>
              <h2 className="episode-list-header">
                {loading ? (
                  <Skeleton
                    width="100%"
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  `Episode ${episodeDetail?.episode_number}`
                )}
              </h2>
            </Col>
            <Col lg={4} md={4} sm={6} xs={6} className="aired-on-col">
              <div className="last-updated-episode-aired-on">
                <p className="m-0">
                  Aired On :
                  <span>
                    {loading ? (
                      <Skeleton
                        width="100%"
                        baseColor={
                          StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                        }
                      />
                    ) : (
                      episodeDetail?.episode_date
                      // formatDate(episodeDetail?.episode_date)
                      // moment(episodeDetail?.episode_date?.split("T")[0]).format("MM/DD/YYYY")
                      // moment(episodeDetail?.episode_date).format("MM/DD/YYYY")
                    )}
                  </span>
                </p>
              </div>
              {/* <h3 className="aired-on">
                <span>Aired On:</span>{" "}
                {moment(state?.episode_date).format("MM/DD/YYYY")}
              </h3> */}
            </Col>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="episode-details-col">
            <div className="episode-details-name-row">
              <h3>
                {loading ? (
                  <Skeleton
                    baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
                  />
                ) : (
                  episodeDetail?.title
                )}
              </h3>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="main-episode-details-two-cards">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="my-2 win-lost-breakdown-card">
              <div className="win-lost-breakdown-card-header">
                <h2>Win / Lost Break Down</h2>
                <div className="last-updated-episode-detail">
                  <p className="m-0">
                    <span>
                      {" "}
                      {loading ? (
                        <Skeleton
                          width="100%"
                          baseColor={
                            StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                          }
                        />
                      ) : (
                        episodeDetail?.game_type
                      )}
                    </span>
                  </p>
                </div>
              </div>

              <div className="win-lost-breakdown-card-content">
                <table
                  className="table table-borderless"
                  style={{ marginBottom: "0px" }}
                >
                  <thead className="episode-lists-table-head">
                    <tr>
                      <th className="text-start episode-lists-th-1">Players</th>
                      <th className="text-start episode-lists-th-2">PFR</th>
                      <th className="text-start episode-lists-th-3">VPIP</th>
                      <th className="text-start episode-lists-th-4">
                        Chip Count
                      </th>
                      <th className="text-end episode-lists-th-4">Win/Loss</th>
                    </tr>
                  </thead>
                  <tbody className="episode-lists">
                    {loading && !episodeDetail?.players && (
                      <tr>
                        <td className="leaderboard-table-data-index">
                          <Skeleton
                            count={7}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-start leaderboard-table-data-name">
                          <Skeleton
                            count={7}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="leaderboard-table-data-results">
                          <Skeleton
                            count={7}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Skeleton
                            count={7}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Skeleton
                            count={7}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                        <td>
                          <Skeleton
                            count={7}
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      episodeDetail?.players?.map((eachData, i) => (
                        <tr key={i} className="hustler-episode-tr">
                          <td
                            className="text-start episode-lists-table-data-index"
                            onClick={() =>
                              // navigate(
                              //   `/player-profile/${eachData?.playerId}/${loaction.state.selectedYear === "All Time"
                              //     ? "all"
                              //     : loaction.state.selectedYear
                              //   }`
                              // )
                              navigate(
                                `/playersprofile/${eachData?.slug}/HCL-poker-results/${loaction.state.selectedYear === "All Time"
                                  ? "all"
                                  : loaction.state.selectedYear
                                }`
                              )
                            }
                          >
                            {eachData?.playerName}
                          </td>
                          <td className="text-start episode-lists-table-data-episode_number">
                            {/* {Math.round(Number(eachData?.pfr))}% */}
                            {eachData?.pfr}%
                          </td>
                          <td className="text-start episode-lists-table-data-name">
                            {/* {Math.round(Number(eachData?.vpip))}% */}
                            {eachData?.vpip}%
                          </td>
                          <td className="text-start episode-lists-table-data-stakes">
                            {eachData?.buy_in === null
                              ? "$0"
                              : `$${eachData?.buy_in.toLocaleString()}`}
                          </td>
                          <td
                            className="text-end episode-lists-table-data-stakes"
                            style={{
                              color:
                                eachData?.winLossValue > 0
                                  ? "#21b976"
                                  : "#fd0303",
                            }}
                          >
                            {eachData?.winLossValue
                              ? formatCurrency(eachData?.winLossValue)
                              : "$0"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="win-lost-breakdown-card-footer">
                <h2>Unaccounted For:</h2>
                <h5
                  style={{
                    color:
                      episodeDetail?.unaccounted > 0
                        ? "#21b976"
                        : episodeDetail?.unaccounted < 0
                          ? "#fd0303"
                          : userTheme === "light"
                            ? "#000"
                            : "#FFF",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      width={30}
                      baseColor={
                        StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                      }
                    />
                  ) : episodeDetail?.unaccounted ? (
                    formatCurrency(episodeDetail?.unaccounted)
                  ) : (
                    "$0"
                  )}
                </h5>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="more-data-card">
              <div className="more-data-card-header">
                <h2>More Data To Digest</h2>
              </div>

              <div className="more-data-card-content">
                <table className="table table-borderless">
                  <tbody>
                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Money Changed Hands
                      </td>
                      <td
                        className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data"
                        style={{
                          color:
                            episodeDetail?.changedhands > 0
                              ? "#21b976"
                              : "#fd0303",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.changedhands ? (
                          formatCurrency(episodeDetail?.changedhands)
                        ) : (
                          "$0"
                        )}
                      </td>
                    </tr>

                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Number of Players
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.totalPlayers ? (
                          episodeDetail?.totalPlayers
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>

                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Games Played on a
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.dayName ? (
                          episodeDetail?.dayName
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>

                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Winning Player
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.totalWinningPlayers ? (
                          episodeDetail?.totalWinningPlayers
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>

                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Losing Player
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.totalLosingPlayers ? (
                          episodeDetail?.totalLosingPlayers
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Biggest Winner
                      </td>
                      <td
                        className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data-1"
                        onClick={() =>
                          // navigate(
                          //   `/player-profile/${episodeDetail?.biggestWinnerId
                          //   }/${loaction?.state?.selectedYear === "All Time"
                          //     ? "all"
                          //     : loaction?.state?.selectedYear
                          //   }`
                          // )
                          navigate(
                            `/playersprofile/${episodeDetail?.slug}/HCL-poker-results/${loaction?.state?.selectedYear === "All Time"
                              ? "all"
                              : loaction?.state?.selectedYear
                            }`
                          )
                        }
                      >
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.biggestWinner ? (
                          episodeDetail?.biggestWinner
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Game Type
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.game_type ? (
                          episodeDetail?.game_type
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    {/* <tr className="row more-data-card-table last-thirty">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Game Type
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? <Skeleton width="100%" /> :episodeDetail?.game_type ? episodeDetail?.game_type : "-"}
                      </td>
                    </tr> */}
                    <tr className="row more-data-card-table">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Game Stakes
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.game_stakes ? (
                          episodeDetail?.game_stakes
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>

                    {/* <tr className="row more-data-card-table biggest-piece">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Biggest Piece of the Pie
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        $200,000
                      </td>
                    </tr> */}

                    <tr className="row more-data-card-table commentator">
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-start more-data-card-table-index">
                        Commentator(s)
                      </td>
                      <td className="col-lg-6 col-md-6 col-sm-6 col-6 text-end more-data-card-table-data">
                        {loading ? (
                          <Skeleton
                            width="100%"
                            baseColor={
                              StoredTheme === "light" ? "#ebebeb" : "#1D1F23"
                            }
                          />
                        ) : episodeDetail?.commentary ? (
                          episodeDetail?.commentary
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {bannerLoading ? (
            <Skeleton
              className="banner-img-episode-1"
              baseColor={StoredTheme === "light" ? "#ebebeb" : "#1D1F23"}
            />
          ) : (
            windowSize > 426 ?
              <a href={topBannerLink} target="_blank" rel="noreferrer">
                <img src={topBanner} className="banner-img-episode-1" />
              </a>
              :
              windowSize <= 425 && <a href={bottomBannerLink} target="_blank" rel="noreferrer">
                <img src={bottomBanner} className="banner-img-episode-1" />
              </a>
            // <a href={bottomBannerLink} target="_blank" rel="noreferrer">
            //   <img src={bottomBanner} className="banner-img-episode-1" alt="" />
            // </a>
          )}
        </div>
      </Container>

      <Footer />
      {windowSize < 767 && (
        <button
          class="btn btn-sm  rounded-circle position-fixed bottom-0 end-0 translate-middle d-none scoll-button"
          onClick={() => scrollToTop()}
          id="back-to-up"
          style={{
            background: "linear-gradient(184deg, #FA00FF 0%, #9105FF 100%)",
            color: "#ffffff",
          }}
        >
          {/* <i class="fa fa-arrow-up" aria-hidden="true"></i> */}
          <img src={upArrow} alt="" width="16px" />
        </button>
      )}
    </>
  );
};

export default connect(null, {
  GetEpisodeDetail,
  GetAdvertiseBannerList,
})(EachEpisode);
